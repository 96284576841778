import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import { InvoicesIcon } from '../utils/icons';

export const invoicesRoutes: RouteType[] = [
  {
    key: RoutesKeys.invoices,
    component: asyncLoad(() => import('../containers/invoices/invoices.container')),
    path: '/fatture',
    exact: true,
    config: {
      label: 'Fatture',
      icon: InvoicesIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.invoiceDetail,
    component: asyncLoad(() => import('../containers/invoices/invoice-detail.container')),
    path: '/fatture/:invoiceId',
    exact: true,
    config: {
      label: 'Fattura',
      icon: InvoicesIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
];
