import { makeStyles, Typography, Box, Button, Grid, TextField, Container, IconButton, CircularProgress } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { SendIcon } from '../../utils/icons';
import { useInView } from 'react-intersection-observer';
import { ChatBubble } from './chat-bubble';
import { TypingIndicator } from './typing-indicator';
import { FileInputIcon } from './file-input';
import { canHandleFiles } from '../../utils';
import { useChat, useConversation } from '@hu-care/chat-client/dist/react';
import { Error as ErrorComponent, Loading } from '@hu-care/react-layout';
import { DragOverlay } from '../drag-overlay';
import { useMountedState } from 'react-use';
import { useChatPopup } from '../../contexts/chat.context';
import { Insets, useInsets } from '../../contexts/insets';

const useStyles = makeStyles(theme =>
  createStyles({
    '@keyframes progress': {
      '0%': {
        backgroundPosition: '0 0',
      },
      '100%': {
        backgroundPosition: '-70px 0',
      },
    },
    dragRoot: {
      position: 'relative',
      height: '100%',
      flex: '1 1 auto',
      '&:focus': {
        outline: 'none',
      },
    },
    chatInner: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '6px',
        backgroundColor: '#dddddd',
        opacity: '0.5',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
        opacity: '0.2',
        '-webkit-border-radius': '4px',
      },
    },
    scrollComponent: {
      paddingTop: theme.spacing(1),
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    dragOverlay: {
      animation: '$progress 2s linear infinite !important',
      // eslint-disable-next-line max-len
      backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.background.paper}, ${theme.palette.background.paper} 25px, ${theme.palette.divider} 25px, ${theme.palette.divider} 50px)`,
      backgroundSize: '150% 100%',
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.grey.A200,
      borderWidth: 4,
      opacity: 0.7,
      borderStyle: 'dashed',
      margin: 0,
      display: 'block',
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
      top: 0,
      zIndex: 1000001,
    },
    dragOverlayText: {
      position: 'absolute',
      zIndex: 1000002,
      left: 0,
      right: 0,
      textAlign: 'center',
      padding: theme.spacing(3),
    },
    root: {
      paddingBottom: '50px',
      maxHeight: 'calc(100vh - 140px)',
      overflow: 'auto',
      width: '100%',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
    sendMessageContainer: {
      paddingRight: 0,
      paddingLeft: theme.spacing(2),
    },
    sendMessageBar: (props: Insets) => ({
      // position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 1000000,
      width: '100%',
      height: 70 + (props.bottom || 0),
      backgroundColor: '#EFEFEF',
      borderTop: '1px solid lightgrey',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: props.bottom || 0,
    }),
    sendButton: {
      display: 'flex',
      marginRight: '20px !important',
      justifyContent: 'center',
      '& button': {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
  }),
);

const MAX_FILE_SIZE = 1048576 * 5; // 1MB x 5
const MAX_FILES = 1;

export const SingleChat = memo<{ destUserId: string; error: boolean }>(({ destUserId, error }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const insets = useInsets();
  const classes = useStyles(insets);

  const { selectedConv, startChat, closeChat } = useChat();
  const { open } = useChatPopup();
  const { form, typing, messages, lastMessageId, loading, sendMessage, sending } = useConversation(selectedConv);
  const [startChatLoading, setStartChatLoading] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  const isMount = useMountedState();

  useEffect(() => {
    if (messages && selectedConv) {
      selectedConv.readMessages();
    }
  }, [messages, selectedConv]);

  useEffect(() => {
    if (selectedConv) {
      selectedConv.twilioConversation.getParticipants()
      .then(participants => {
        const otherParticipant = participants.find(p => p.sid !== selectedConv.currentParticipant?.sid);
        if (otherParticipant) {
          otherParticipant.getUser()
          .then(user => {
            const [name, surname] = user.friendlyName.split(' ');
            open({
              id: user.attributes.userId,
              name,
              surname,
            });
          });
        }
      });
    }
  }, [selectedConv, open]);

  useEffect(() => {
    if (lastMessageId) {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [lastMessageId]);

  useEffect(() => {
    if (!destUserId) {
      return;
    }
    setStartChatLoading(true);
    startChat(destUserId).finally(() => {
      if (isMount()) {
        setStartChatLoading(false)
      } else {
        closeChat();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destUserId, setStartChatLoading, startChat, closeChat]);

  const onDrop = useCallback((newFiles: File[], reject: FileRejection[]) => {
    if (canHandleFiles({ newFiles, reject, t, showMessage: toast.error, maxFiles: MAX_FILES })) {
      form.setValue('file', newFiles[0]);
    }
  }, [toast, t, form]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
  });

  const { inView, ref: lastViewRef } = useInView();

  useEffect(() => {
    if(inView && typing && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [inView, typing]);

  if (startChatLoading) {
    return <Loading className={classes.dragRoot}/>;
  }

  if (error) {
    return (
      <div className={classes.dragRoot}>
        <Box p={2}>
          <ErrorComponent error={new Error(t('errors:cannot-start-chat'))}/>
        </Box>
      </div>
    )
  }

  if (!selectedConv || !selectedConv.currentParticipant) {
    return null;
  }

  const currentUserId = selectedConv.currentParticipant.identity;
  const file = form.watch('file');

  return (
    <div {...getRootProps({ className: classes.dragRoot })}>
      <div className={classes.chatInner}>
        {isDragActive && <DragOverlay/>}

        <div className={classes.scrollComponent} ref={scrollRef}>
          <Box py={2}>
            {selectedConv?.hasPrevPage ? (
              <Button
                fullWidth variant="text" color="secondary"
                disabled={loading}
                onClick={() => selectedConv?.prevPage()}
              >
                {t('chat:load-old-messages')}
              </Button>
            ) : (
              <Typography
                align="center"
                color="textSecondary"
                variant="body2"
              >
                {t('chat:no-old-messages')}
              </Typography>
            )}
          </Box>
          <Box mt="auto" width="100%" px={1} pb={1}>
            {messages.map((message, i) => {
              return (
                <Grid
                  item xs={12}
                  key={message.id}
                  id={message.id}
                  ref={i === messages.length - 1 ? lastViewRef : undefined}
                >
                  <ChatBubble
                    color={message.author !== currentUserId ? 'gray' : 'orange' }
                    message={message}
                    showTriangle={(messages.length - 1 === i) || (messages[i + 1]?.author !== message.author)}
                    float={message.author !== currentUserId ? 'left' : 'right'}
                  />
                </Grid>
              )
            })}
            {typing && (
              <Grid item xs={12}>
                <Box py={2}>
                  <TypingIndicator/>
                </Box>
              </Grid>
            )}
          </Box>
        </div>

        <Box className={classes.sendMessageBar}>
          <Container maxWidth="sm" className={classes.sendMessageContainer}>
            <form autoComplete="off" onSubmit={sendMessage} >
              <input style={{ display: 'none' }} autoComplete="off" />
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs>
                  <TextField
                    placeholder={t('chat:write-message')}
                    name="message"
                    autoComplete="off"
                    fullWidth
                    inputRef={form.register}
                    error={Boolean(form.errors.message)}
                    helperText={form.errors.message?.message}
                    onKeyDown={async () => {
                      await selectedConv.setTyping();
                    }}
                  />
                </Grid>

                <Grid item className={classes.sendButton}>

                  <Box ml={2} alignSelf="center">
                    <FileInputIcon
                      {...getInputProps()}
                      value={file ? [file] : []}
                      maxFilesNumber={1}
                      onSelect={(files: File[]) => {
                        form.setValue('file', files.length ? files[0] : null)
                      }}
                    />
                  </Box>

                  <IconButton
                    color="primary"
                    disabled={false}
                    type="submit"
                  >
                    {sending
                      ? <CircularProgress size={24} />
                      : <SendIcon />
                    }
                  </IconButton>
                </Grid>

              </Grid>
            </form>
          </Container>
        </Box>
      </div>
    </div>
  )
});
