import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import { AddProfileIcon, ProfileIcon, SettingsIcon } from '../utils/icons';

export const profileRoutes: RouteType[] = [
  {
    key: RoutesKeys.settings,
    component: asyncLoad(() => import('../containers/profile/settings.container')),
    path: '/settings',
    exact: true,
    config: {
      label: 'Impostazioni',
      title: 'Impostazioni',
      icon: SettingsIcon,
      navBar: true,
      showBack: false,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.profile,
    component: asyncLoad(() => import('../containers/profile/profile.container')),
    path: '/profiles/:patientUserId',
    exact: true,
    config: {
      label: 'Scheda paziente',
      title: 'Scheda paziente',
      icon: ProfileIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.profiles,
    component: asyncLoad(() => import('../containers/profile/profiles.container')),
    path: '/profiles',
    exact: true,
    config: {
      label: 'Schede paziente',
      title: 'Schede paziente',
      icon: ProfileIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.firstProfile,
    component: asyncLoad(() => import('../containers/profile/add-profile.container')),
    path: '/first-profile',
    exact: true,
    config: {
      label: 'Aggiungi profilo',
      title: 'Aggiungi profilo',
      icon: AddProfileIcon,
      navBar: false,
      showBack: false,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.newProfile,
    component: asyncLoad(() => import('../containers/profile/add-profile.container')),
    path: '/new-profile',
    exact: true,
    config: {
      label: 'Nuovo profilo',
      title: 'Nuovo profilo',
      icon: AddProfileIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  // {
  //   key: RoutesKeys.medicalHistory,
  //   component: asyncLoad(() => import('../containers/not-implemented')),
  //   path: '/anamnesi',
  //   exact: true,
  //   config: {
  //     label: 'Anamnesi',
  //     icon: FolderSharedIcon,
  //     navBar: true,
  //     showBack: true,
  //     scopes: [],
  //   },
  // },
];
