import { Capacitor } from '@capacitor/core'
import { Storage as CapStorage } from '@capacitor/storage';

export type StorageSetInputType = {
  key: string;
  value: string;
}

export type StorageGetInputType = {
  key: string;
}

export type StorageRemoveInputType = {
  key: string;
}

export type StorageGetReturnType = {
  value: string | null;
}

export class Storage {
  static async set({ key, value }: StorageSetInputType): Promise<void> {
    if (Capacitor.getPlatform() === 'ios') {
      return CapStorage.set({ key, value });
    } else {
      return localStorage.setItem(key, value);
    }
  }

  static async get({ key }: StorageGetInputType): Promise<StorageGetReturnType> {
    if (Capacitor.getPlatform() === 'ios') {
      return CapStorage.get({ key });
    } else {
      return {
        value: localStorage.getItem(key),
      };
    }
  }

  static async remove({ key }: StorageRemoveInputType): Promise<void> {
    if (Capacitor.getPlatform() === 'ios') {
      CapStorage.remove({ key });
    } else {
      localStorage.removeItem(key);
    }
  }
}