import { memo, Suspense, useEffect, useMemo, useState } from 'react';
import { Loading } from '@hu-care/react-layout';
import { Redirect, Switch, useLocation } from 'react-router';
import { Layout } from '../components/layout/layout';
import { getRoute, renderRoutes, RoutesKeys } from '../routes';
import { Box, makeStyles } from '@material-ui/core';
import ExternalLayout from '../components/external-layout/external-layout';
import { StoreProvider } from '@hu-care/react-ui-store';
import { SplashScreen } from '@capacitor/splash-screen';
import { ChatPopupProvider } from '../contexts/chat.context';
import { Insets, useInsets } from '../contexts/insets';
import { usePlatformAlerts } from '../hooks/usePlatformAlerts';
import { platformAlertToAlertMessage } from '../utils/platform-alerts';
import { useAlerts } from '../contexts/alerts.context';
import { AlertMessage } from '../components/alert-message';

const useStyles = makeStyles(() => ({
  snackbar: (props: Insets) => ({
    bottom: 75 + (props.bottom || 0),
  }),
}));
const InnerMainContainer = () => {
  const routes = useMemo(
    () => renderRoutes([]),
    [],
  );
  return <Suspense fallback={<Loading />}>
    <Switch>
      {routes}
      <Redirect to={getRoute(RoutesKeys.dashboard)} />
    </Switch>
  </Suspense>
}
const MainContainer = memo(() => {
  const [ready, setReady] = useState(false);
  const insets = useInsets();
  const classes = useStyles(insets);
  const alerts = useAlerts();
  const { loading: pLoading, platformAlerts = [] } = usePlatformAlerts();

  useEffect(() => {
    if (!('Pace' in window)) {
      return;
    }
    if (pLoading) {
      (window as any).Pace?.start();
    } else {
      (window as any).Pace?.stop();
    }
  }, [pLoading]);

  const { addManyMessages } = alerts;
  useEffect(() => {
    if (platformAlerts.length) {
      addManyMessages(platformAlerts.map(platformAlertToAlertMessage));
    }
  }, [addManyMessages, platformAlerts]);

  const externalLayoutRoutes = ([
    // RoutesKeys.createFirstConnection,
    RoutesKeys.uploadReport,
    RoutesKeys.firstProfile,
    RoutesKeys.updateRequired,
  ]).map(key => getRoute(key));
  const { pathname } = useLocation()
  const CurrentLayout = useMemo(() => {
    if (externalLayoutRoutes.includes(pathname)) {
      return ExternalLayout;
    } else {
      return Layout;
    }
  }, [pathname, externalLayoutRoutes]);
  const initialState = useMemo(() => ({
    className: classes.snackbar,
  }), [classes.snackbar]);
  useEffect(() => {
    if (ready) {
      SplashScreen.hide();
    }
  }, [ready]);
  useEffect(() => setReady(true), [])
  if (!ready) {
    return <Box pt={8}>
      <Loading/>
    </Box>;
  }
  return (
    <StoreProvider snackbarProps={initialState}>
      <ChatPopupProvider>
        <CurrentLayout>
          <Box pt={alerts.messages.length ? 1 : 0} px={2}>
            {alerts.messages.map(message => (
              <AlertMessage message={message} onClose={alerts.closeMessage} key={message.id} />
            ))}
          </Box>
          <InnerMainContainer />
        </CurrentLayout>
      </ChatPopupProvider>
    </StoreProvider>
  );
});
export default MainContainer;
