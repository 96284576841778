import { cloneElement, memo, useCallback } from 'react';
import {
  ListItem,
  Typography,
  Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { NotificationData } from '../../utils/notifications';
import { useTranslation } from 'react-i18next';
import { Tab } from '@material-ui/icons';
import { formatDistanceToNow } from 'date-fns';
import { it } from 'date-fns/esm/locale';
import { DoneIcon } from '../../utils/icons';
import { useHistory } from 'react-router';
import { useReadNotification } from '../../hooks/useReadNotifications';

/* const useStyles = makeStyles(theme => ({
  listItem: {
    position: 'relative',
    '&:hover $notificationListItemMenu': {
      opacity: 1,
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  listItemTextUnread: {
    fontWeight: theme.typography.fontWeightBold,
  },
  listItemActionLink: {
    cursor: 'pointer',
  },
  notificationListItemMenu: {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity background-color ease 0.2s',
    right: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
})); */

export interface NotificationWidgetItemProps {
  notification?: NotificationData;
  loading: boolean;
  divider?: boolean;
  onClose?: () => void;
}

export const NotificationItem = memo<NotificationWidgetItemProps>(({ notification, divider, loading, onClose }) => {
  const { t } = useTranslation();
  const data = notification;
  const history = useHistory();

  const { onRead } = useReadNotification();

  const markAsRead = useCallback(() => {
    if (notification && !notification.readAt) {
      onRead(notification.id);
    }
  }, [notification, onRead]);

  const onActionClick = useCallback(() => {
    markAsRead();
    if (data?.action?.url) {
      history.push(data.action.url);
      if (onClose) {
        onClose();
      }
    }
  }, [data, history, onClose, markAsRead]);

  const read = notification?.readAt;

  const icon = data?.icon ? cloneElement(data.icon as any, {
    fontSize: 'small',
    fontWeight: 'bold',
    color: read ? 'inherit' : 'primary',
  }) : <Skeleton variant="circle" width={24} height={24} />;

  const availableActions = [];
  if (!read) {
    availableActions.push({
      label: t('mark-as-read'),
      icon: <DoneIcon />,
      onClick: markAsRead,
    });
  }
  if (data?.action) {
    availableActions.push({
      label: data.action.text,
      icon: <Tab />,
      onClick: onActionClick,
    });
  }

  if (!data) {
    return null;
  }

  return (
    <ListItem divider onClick={onActionClick} style={{ backgroundColor: read ? '#F5F7FC' : 'white' }}>
      <Box display="flex" alignItems="center" width="100%">
        <Box pr={2}>
          {icon}
        </Box>
        <Box width="100%">
          <Typography variant="subtitle2" style={{ fontWeight: read ? 400 : 600 }}>
            {data?.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">{data?.message}</Typography>
          <Box display="flex" justifyContent="end">
            {data?.createdAt && (
              <Typography variant="subtitle2" style={{ fontWeight: read ? 400 : 600 }}>
                {
                  formatDistanceToNow(data.createdAt, {
                    locale: it,
                    addSuffix: true,
                  })
                }
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
/*
  return (
    <ListItem divider={divider} dense className={classes.listItem}>
      {!isMobile && (
        <ListItemIcon className={classes.listItemIcon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        primary={loading ? (
          <Skeleton width={120} />
        ) : (
          <Typography variant="body2" className={clsx({
            [classes.listItemTextUnread]: !read,
          })}>{data?.title}</Typography>
        )}
        secondary={loading ? <Skeleton width={250} /> : (
          <>
            <Typography variant="body2" color="textSecondary">{data?.message}</Typography>
            <Box flexBasis="100%" display="flex" justifyContent="space-between">
              {data?.action && (
                <Link
                  variant="body2"
                  className={clsx({
                    [classes.listItemTextUnread]: !read,
                  }, classes.listItemActionLink)}
                  onClick={onActionClick}
                  color="primary"
                >
                  {data.action.text}
                </Link>
              )}
              {data?.createdAt && (
                <Typography variant="subtitle2" component="span">
                  {' '}{formatDistanceToNow(data.createdAt, {
                    locale: it,
                    addSuffix: true,
                  })}
                </Typography>
              )}
            </Box>
          </>
        )}
      />
      {(notification && availableActions.length > 0) && (
        <>
          <Fab
            size="small"
            className={classes.notificationListItemMenu}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreHoriz />
          </Fab>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {availableActions.map(action => (
              <MenuItem
                key={action.label}
                onClick={action.onClick}
                dense
                button
                disabled={readLoading}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  {action.icon}
                </ListItemIcon>
                <Typography variant="inherit">{action.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </ListItem>
  ); */
});
