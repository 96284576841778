import { useMutation } from '@apollo/client';
import {
  ReadManyNotificationsDocument,
  ReadManyNotificationsMutation,
  ReadManyNotificationsMutationVariables,
  ReadNotificationDocument,
  ReadNotificationMutation,
  ReadNotificationMutationVariables,
} from '../generated/graphql';

import { useCallback, useMemo } from 'react';

export const useReadNotifications = () => {
  const [
    readManyMutation,
    { loading },
  ] = useMutation<ReadManyNotificationsMutation, ReadManyNotificationsMutationVariables>(ReadManyNotificationsDocument);

  const readMany = useCallback(async (ids: string[]) => {
    try {
      const result = await readManyMutation({
        variables: { ids },
      });
      return result;
    } catch (e) {
      console.error(e);
    }
  }, [readManyMutation]);

  return useMemo(() => ({
    readMany,
    loading,
  }), [
    loading,
    readMany,
  ])
}

export const useReadNotification = () => {
  const [
    readMutation,
    { loading },
  ] = useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument);

  const onRead = useCallback(async (id: string) => {
    try {
      const result = await readMutation({
        variables: { id },
      });
      return result.data?.markNotificationAsRead;
    } catch (e) {
      console.error(e);
    }
  }, [readMutation]);

  return useMemo(() => ({
    onRead,
    loading,
  }), [
    onRead,
    loading,
  ]);
}
