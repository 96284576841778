import { SvgIconComponent } from '@material-ui/icons';
import { ContainerProps } from '@material-ui/core';

export enum RoutesKeys {
  dashboard = 'dashboard',
  notifications = 'notifications',
  appointments = 'appointments',
  appointmentDetail = 'appointmentDetail',
  requests = 'requests',
  requestDetail = 'requestDetail',
  createRequest = 'createRequest',
  uploadReport = 'uploadReport',
  createConnection = 'createConnection',
  createFirstConnection = 'createFirstConnection',
  provisions = 'provisions',
  bookProvision = 'bookProvision',
  browseProviderProvisions = 'browseProviderProvisions',
  network = 'network',
  networkDetail = 'networkDetail',
  networkReports = 'networkReports',
  profile = 'profile',
  firstProfile = 'firstProfile',
  newProfile = 'newProfile',
  profiles = 'profiles',
  reports = 'reports',
  reportDetail = 'reportDetail',
  therapy = 'therapy',
  chat = 'chat',
  personalData = 'personalData',
  settings = 'settings',
  medicalHistory = 'medicalHistory',
  login = 'login',
  signup = 'signup',
  verifyPhone = 'verifyPhone',
  updateRequired = 'updateRequired',
  invoices = 'invoices',
  invoiceDetail = 'invoiceDetail',
}

export interface RouteType {
  key: RoutesKeys;
  path: string;
  component: any;
  exact?: boolean;
  children?: any[];
  config: {
    icon?: SvgIconComponent;
    altIcon?: any;
    label: string;
    title?: string;
    className?: string;
    navBar: boolean;
    showBack?: boolean;
    scopes: string[];
    args?: Record<string, any>;
    containerProps?: Partial<Omit<ContainerProps, 'children'>>;
  };
}
