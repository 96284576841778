import { TFunction } from 'react-i18next';

export * from './roles';
export * from './validation';
export * from './device-utils';
export * from './upload';
export * from './request-titles';
export * from './fractions';
export * from './merge';

export const capitalize: (str: string) => string = (str) => {
  const parts = str.split(' ')
    .filter(Boolean) // filters away empty strings coming from split (double spaces)
  try {
    return parts.map(part => part[0].toUpperCase() + part.substring(1).toLowerCase())
      .join(' ');
  } catch (e) {
    return str;
  }
}

export function kebabCase(str: string) {
  const matches = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  if (!matches) return '';
  return matches
    .map(x => x.toLowerCase())
    .join('-');
}

export enum UploadDestinations {
  Requests = 'requests',
  Reports = 'reports',
}

export function arrayOfLength(len: number) {
  return Array.from(new Array(len)).map((_, i) => i);
}

export const mapFromEnum = (en: any, t?: TFunction) => Object.values(en).map((v: any) => ({
  value: v,
  label: t ? t(v) : v,
}));

export const arrayFromEnum = (en: any) => mapFromEnum(en).map(s => s.value);
