import { memo, useCallback } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SharedDialog } from '@hu-care/react-ui-store';
import { Layout as HuCareLayout, NavBarProps, useLayout } from '@hu-care/react-layout';
import { NavBar } from '../layout/nav-bar';
import { Insets } from '../../contexts/insets';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      zIndex: 0,
      paddingLeft: 0,
      paddingRight: 0,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    sharedDialog: {
      zIndex: 20000000000,
      '& *': {
        justifyContent: 'center',
      },
    },
    titleBar: {
      ...theme.mixins.toolbar,
      // top: theme.mixins.toolbar.minHeight,
      zIndex: 1000,
      backgroundColor: 'white',
      // marginTop: '5px', // TODO check this
      width: '100%',
      position: 'fixed',
      borderBottom: '3px solid #D99EF7',
      boxShadow: '0px 6px 9px #00000029',

      '& *': {
        justifyContent: 'center',
        alignSelf: 'center',
      },
    },
    title: {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingLeft: '2pt',

      fontSize: 20,

      '&.medic': {
        color: '#448CCB',
      },
    },
    toolbar: (props: Insets) => ({
      marginTop: props.top || 0,
    }),
  }),
);

export default memo(({ children }) => {
  const { insets } = useLayout<{ insets: Insets }>();
  const classes = useStyles(insets);

  const navBar = useCallback((props: NavBarProps) => (
    <NavBar {...props} showNotifications={false}/>
  ), [])

  const { containerProps } = useLayout<{ containerProps?: any }>();

  return (
    <HuCareLayout
      showEnvBar={false}
      navBar={navBar}
      mainClassName={classes.content} rootClassName={classes.root} maxWidth="md"
      {...containerProps}
      className={clsx(classes.container, containerProps?.className)}
    >
        {children}
        <span className={classes.sharedDialog}>
          <SharedDialog />
        </span>
    </HuCareLayout>
  );
});
