import { NotificationDataFragment } from '../generated/graphql';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { NetworkIcon, RequestsIcon, GroupMedicineIcon, DoctorIcon, ReportsIcon } from './icons';
import { parseISO } from 'date-fns';
import { Chat, Today } from '@material-ui/icons';
import { CustomIcon } from '../components/shared/custom-icon';

export interface NotificationData {
  id: string;
  title: string;
  message?: string;
  createdAt?: Date;
  readAt?: string;
  icon: ReactNode;
  action?: {
    url: string;
    text: string;
  }
}

function getNotificationUri(url: string) {
  const uri = new URL(url);
  return uri.pathname + uri.search;
}

export function notificationData(notification: NotificationDataFragment, t: TFunction) {
  const commonData = {
    id: notification.id,
    readAt: notification.readAt,
    createdAt: parseISO(notification.createdAt),
  };
  switch (notification.name) {
    case 'empty':
      return {
        title: t('no-notifications'),
      }
    case 'network-requested':
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: t('notification:new-network-request'),
        message: t('notification:new-network-request-message', {
          theRole: notification.data.sender.theRole,
          fullName: notification.data.sender.fullName,
        }),
        icon: <NetworkIcon/>,
      }
    case 'network-accepted':
      return {
        ...commonData,
        title: t('notification:network-request-accepted'),
        message: t('notification:network-request-accepted-message', {
          theRole: notification.data.sender.theRole,
          fullName: notification.data.sender.fullName,
        }),
        icon: <NetworkIcon/>,
      }
    case 'request-created':
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: notification.data.subject,
        message: t('notification:request-created-message', {
          theRole: notification.data.sender.role,
          fullName: notification.data.sender.fullName,
        }),
        icon: <RequestsIcon/>,
      }
    case 'request-new-message':
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: notification.data.subject,
        message: t('notification:request-new-message-message', {
          theRole: notification.data.author.theRole || 'the_patient',
          fullName: notification.data.author.fullName,
        }),
        icon: <RequestsIcon/>,
      }
    case 'request-resolved':
      return {
        ...commonData,
        action: {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        },
        title: t('notification:request-resolved.title'),
        message: t('notification:request-resolved.message', {
          theRole: notification.data.sender.theRole || 'the_patient',
          fullName: notification.data.sender.fullName,
          title: notification.data.subject || '',
        }),
        icon: <RequestsIcon/>,
      }
    case 'avails-appointment-created-by-professional':
      return {
        ...commonData,
        title: t('notification:appointment-created-by-professional.title'),
        message: t('notification:appointment-created-by-professional.message', {
          professionalName: notification.data.sender.fullName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
        action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined,
      }
      case 'appointment-created-by-professional':
      return {
        ...commonData,
        title: t('notification:appointment-created-by-professional.title'),
        message: t('notification:appointment-created-by-professional.message', {
          professionalName: notification.data.sender.fullName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
        action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined,
      }
    case 'avails-appointment-deleted-by-professional':
      return {
        ...commonData,
        title: t('notification:appointment-deleted-by-professional.title'),
        message: t('notification:appointment-deleted-by-professional.message', {
          professionalName: notification.data.sender.fullName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
      }
    case 'appointment-deleted-by-professional':
      return {
        ...commonData,
        title: t('notification:appointment-deleted-by-professional.title'),
        message: t('notification:appointment-deleted-by-professional.message', {
          professionalName: notification.data.sender.fullName,
          appointment: notification.data.appointment,
        }),
        icon: <Today/>,
      }
    case 'chat-new-message':
      const { search } = new URL(notification.data.action.url);
      return {
        ...commonData,
        title: notification.data.subject,
        icon: <Chat/>,
        action: {
          url: search,
          text: notification.data.action.text,
        },
      }
    case 'patient-invited-in-group-medicine':
      return {
        ...commonData,
        title: t('professional:patient-invited-in-group-medicine.title'),
        message: notification.data.subject,
        action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined,
        icon: <GroupMedicineIcon />,
      }
    case 'personal-medic-transfer-answered':
      return {
        ...commonData,
        title: t('professional:personal-medic-transfer-answered.title', {
          professionalName: notification.data.professional.fullName,
        }),
        message: notification.data.subject,
        action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined,
        icon: <CustomIcon Icon={DoctorIcon} />,
      }
    case 'report-accepted':
      return {
        ...commonData,
        title: notification.data.subject,
        action: notification.data.action ? {
          url: getNotificationUri(notification.data.action.url),
          text: notification.data.action.text,
        } : undefined,
        icon: <ReportsIcon />,
      }
  }
}
