import { InMemoryCache } from '@apollo/client';
import result from '../generated/possibleTypes.json';

export const cache = new InMemoryCache({
  possibleTypes: result.possibleTypes,
  typePolicies: {
    AgendaEntryInDay: {
      keyFields: ['day', 'from', 'to', 'id', 'clinicId'],
    },
    ReportSharedWith: {
      keyFields: ['reportId', 'patientProfessionalId'],
    },
    Query: {
      fields: {},
    },
    ClinicRoomFromDay: {
      keyFields: ['fromDay', 'room', ['id']],
    },
    ClinicRoom: {
      fields: {
        provisions: {
          merge: false,
        },
      },
    },
  },
});

export default cache;
