import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { NotificationIcon, RefreshIcon } from '../../utils/icons';
import {
  Badge,
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  List, ListItem,
  ListItemText,
  Popover,
  Switch,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { DoneAll } from '@material-ui/icons';
import { useNotifications } from '../../hooks/useNotifications';
import { useReadNotifications } from '../../hooks/useReadNotifications';
import { NotificationItem } from './notification-item';

const useStyles = makeStyles(theme => ({
  widgetPaper: {
    width: '90vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    /* [theme.breakpoints.down('xs')]: {
      maxHeight: '70vh',
      maxWidth: '90vw',
      minWidth: 0,
    }, */
  },
  widgetList: {
    flexGrow: 1,
    minHeight: 0,
    overflowY: 'auto',
  },
}));

export const NotificationMenu: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [onlyUnread, setOnlyUnread] = useState(true);
  const [notificationLimit, setNotificationLimit] = useState<number>(10);

  const { notifications, loading, refetch, subscribe, notificationsToShow, paginationInfo } = useNotifications({
    onlyUnread,
    skip: 0,
    limit: notificationLimit,
  });
  const { readMany, loading: readManyLoading } = useReadNotifications();

  useEffect(() => {
    subscribe();
  }, [subscribe]);

  const unreadCount = notificationsToShow.reduce((acc, curr) => {
    if (!curr.readAt) {
      acc++;
    }
    return acc;
  }, 0);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsRead = useCallback(() => {
    const unreadIds = notifications?.filter(n => !n.readAt).map(n => n.id);
    if (unreadIds?.length) {
      readMany(unreadIds);
    }
  }, [readMany, notifications]);

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadCount} color="secondary" max={9}>
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: classes.widgetPaper,
        }}
      >
        <div className={classes.widgetList}>
          <Box pl={2} pr={1} py={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{t('notifications')}</Typography>
            <Box display="flex">
              <FormControlLabel
                label={
                  <Typography variant="body2">{t('show-only-unread')}</Typography>
                }
                style={{ marginRight: 2 }}
                checked={onlyUnread}
                onChange={(e, checked) => setOnlyUnread(checked)}
                control={<Switch color="primary" size="small"/>}
                labelPlacement="start"
              />
              <IconButton disabled={readManyLoading} onClick={markAsRead}>
                <DoneAll fontSize="small"/>
              </IconButton>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon/>
              </IconButton>
            </Box>
          </Box>
          <Box style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            {
              loading
              ? <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                  <CircularProgress />
                </Box>
              : <>
                <List disablePadding>
                  {
                    notificationsToShow.length > 0
                    ? notificationsToShow.map((not, i) => (
                      <NotificationItem
                        loading={false}
                        notification={not}
                        key={not.id}
                        onClose={handleClose}
                      />
                    ))
                    : <ListItem style={{ textAlign: 'center', paddingTop: 2, paddingBottom: 2 }}>
                      <ListItemText primary={t('no-notifications')} />
                    </ListItem>
                  }
                </List>
                {
                  paginationInfo?.hasMore &&
                  <ListItem onClick={() => setNotificationLimit(notificationLimit + 10)}>
                    <Box textAlign="center" width="100%" py={0.5}>
                      <Typography variant="button" color="primary">Mostra di più</Typography>
                    </Box>
                  </ListItem>
                }
              </>
            }
          </Box>
        </div>
      </Popover>
    </>
  )
}
