import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { capitalize } from '@hu-care/react-utils';

function requireAll(r: any) {
  return Object.fromEntries(
    r.keys().map(function (mpath: any, ...args: any) {
      const result = r(mpath, ...args);
      const name = mpath
        .replace(/(?:^[./]*\/|\.[^.]+$)/g, '') // Trim
        .replace(/\//g, '_') // Replace '/'s by '_'s
        ;
      return [name, result];
    }),
  );
}

const itNs = requireAll((require as any).context('./resources/it/', true, /\.json$/));
// const enNs = requireAll((require as any).context('./resources/en/', true, /\.json$/));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      it: itNs,
      // en: enNs,
    },
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'common',
    returnEmptyString: false,
    appendNamespaceToMissingKey: false,
    fallbackLng: ['en', 'it'],
    // lng: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
    interpolation: {
      format: (value, format, lng) => {
        if (format === 'capitalize') {
          return capitalize(value.toLowerCase());
        }
        if (format === 'lowercase') {
          return value.toLowerCase();
        }
        return value;
      },
    },
  });
