import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import DescriptionIcon from '@material-ui/icons/Description';

export const reportsRoutes: RouteType[] = [
  {
    key: RoutesKeys.reports,
    component: asyncLoad(() => import('../containers/reports/reports.container')),
    path: '/referti',
    exact: true,
    config: {
      label: 'Referti',
      icon: DescriptionIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.reportDetail,
    component: asyncLoad(() => import('../containers/report-detail/report-detail.container')),
    path: '/referti/:reportId',
    exact: true,
    config: {
      label: 'Referto',
      icon: DescriptionIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.uploadReport,
    component: asyncLoad(() => import('../containers/upload-report/upload-report.container')),
    path: '/carica-referto',
    exact: true,
    config: {
      label: 'Carica referto',
      icon: DescriptionIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
];
