import {
  MedicBasicDataFragment,
  OrganizationSaBasicDataFragment,
  ProfessionalBasicDataFragment,
  ProfessionalRole,
} from '../generated/graphql';

import { Person } from '@material-ui/icons';
import { OrganizationIcon, DoctorIcon, PatientIcon, AssistantIcon } from './icons';
import { CustomIcon } from '../components/shared/custom-icon';
import { TFunction } from 'react-i18next';
import { capitalize, parseCf } from '@hu-care/react-utils';

export const getProfileIcon = (profile: { __typename?: string }) => {
  return getRoleIcon(profile.__typename)
}

// Object use to order entities
export const orderScore = {
  [ProfessionalRole.Medic as string]: 1,
  [ProfessionalRole.Operator as string]: 2,
  [ProfessionalRole.Assistant as string]: 3,
  [ProfessionalRole.OrganizationSa as string]: 4,
  [ProfessionalRole.Administrator as string]: 5,
};

export const getOrderScore: (arg0: string | undefined) => number = (typename) => {
  if (!typename) {
    return 0;
  }
  return orderScore[typename] || 0;
};

export function isOrganizationSa(profile: { __typename?: string } | null): profile is OrganizationSaBasicDataFragment {
  return profile?.__typename === ProfessionalRole.OrganizationSa;
}

export function isMedic(profile: { __typename?: string } | null): profile is MedicBasicDataFragment {
  return profile?.__typename === ProfessionalRole.Medic;
}

export const getRoleIcon = (role?: string) => {
  switch (role) {
    case ProfessionalRole.Medic: return <CustomIcon Icon={DoctorIcon} />;
    case ProfessionalRole.Assistant: return <CustomIcon Icon={AssistantIcon} />;
    case ProfessionalRole.OrganizationSa: return <OrganizationIcon />;
    case 'PatientUser': return <PatientIcon />;
    default: return <Person />;
  }
}

export const getDoctorPrefix = (taxId: string, t: TFunction) => {
  const parsedCf = parseCf(taxId);
  const isFemale = parsedCf?.gender?.toLowerCase() === 'f';

  return isFemale
    ? t('professional:doctor-she')
    : t('professional:doctor-he')
}

export type BaseProfessionalData = Pick<ProfessionalBasicDataFragment, '__typename' | 'id' | 'name' | 'surname' | 'user'>;

export const getProfessionalPrefix = (professional: BaseProfessionalData, t: TFunction, addSpace = true) => {
  if (professional?.__typename === 'Medic') {
    return professional.user?.traits.taxId
      ? `${getDoctorPrefix(professional.user.traits.taxId, t)}${addSpace ? ' ' : ''}`
      : '';
  } else {
    return '';
  }
}

export const getProfessionalFullName = (professional: BaseProfessionalData, t: TFunction) => {
  return `${getProfessionalPrefix(professional, t)}${professional.name} ${professional.surname}`;
}

export const getPatientFullName = (patient: Pick<BaseProfessionalData, 'name' | 'surname'>) => {
  return capitalize(`${patient.surname} ${patient.name}`);
}
