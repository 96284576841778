import { useQuery } from '@apollo/client';
import {
  GetNotificationsDocument,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  NotificationFilterInput,
  OnNewNotificationDocument,
  OnNewNotificationSubscription,
  OnNewNotificationSubscriptionVariables,
} from '../generated/graphql';

import { useCallback, useMemo } from 'react';
import { usePatient } from '../contexts/patient.context';
import { notificationData, NotificationData } from '../utils/notifications';
import { useTranslation } from 'react-i18next';

interface UseNotificationsProps {
  onlyUnread?: boolean;
  skip?: number;
  limit?: number;
  onlyTypes?: string[];
}

export const useNotifications = ({ onlyUnread = false, skip = 0, limit = 10, onlyTypes }: UseNotificationsProps = {}) => {
  const { t } = useTranslation();
  const { selectedPatient } = usePatient();
  const filters: NotificationFilterInput = {};
  /* if (onlyUnread) {
    filters.readAt = { is_null: true };
  } */
  if (onlyTypes) {
    filters.name = { in: onlyTypes };
  }

  //const notificationsFetchPolicy = useCacheWithExpiration('NotificationsQuery');
  const { data, subscribeToMore, ...rest } = useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, {
    variables: {
      filters,
      skip,
      limit,
      read: onlyUnread ? false : undefined,
      profileType: 'Patient',
    },
    context: {
      batch: false,
    },
    fetchPolicy: 'network-only',
  });

  const subscribe = useCallback(() => {
    // https://github.com/apollographql/apollo-client/issues/5870
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    subscribeToMore<OnNewNotificationSubscription, OnNewNotificationSubscriptionVariables>({
      document: OnNewNotificationDocument,
      updateQuery: (previousQueryResult, { subscriptionData }) => {
        if (!subscriptionData.data?.onNewNotification) {
          return previousQueryResult;
        }
        const item = subscriptionData.data.onNewNotification;

        return {
          ...previousQueryResult,
          notifications: {
            items: [
              item,
              ...previousQueryResult.getNotifications.items,
            ],
            paginationInfo: previousQueryResult.getNotifications.paginationInfo,
          },
        }
      },
    });
  }, [subscribeToMore]);

  const notifications = useMemo(() => {
    return data?.getNotifications.items.filter(not => {
      const destination = not.destinationProfile;
      if (!destination || !selectedPatient) return true;
      const [role, id] = destination.split(':');
      return role === 'Patient' && id.toString() === selectedPatient.id.toString()
    });
  }, [data, selectedPatient]);

  const notificationsToShow = useMemo<NotificationData[]>(() => (
    notifications || []).map(n => notificationData(n, t)).filter(Boolean) as NotificationData[],
    [notifications, t]);

  return useMemo(() => ({
    notifications,
    notificationsToShow,
    paginationInfo: data?.getNotifications.paginationInfo,
    subscribe,
    ...rest,
  }), [
    data,
    rest,
    subscribe,
    notifications,
    notificationsToShow,
  ]);
}
