import { ProfessionalRole } from '../generated/graphql';

export function getRoleColor(role?: string) {
  switch (role) {
    case ProfessionalRole.Medic:
      return { main: '#448CCB', light: '#B3D1E9', dark: '#336897' };
    case ProfessionalRole.Assistant:
      return { main: '#F7941D', light: '#FAD5A1', dark: '#985F1A' };
    case ProfessionalRole.OrganizationSa:
      return { main: '#7ACCC8', light: '#cafbf9', dark: '#63cec9' };
    case ProfessionalRole.Operator:
      return { main: '#F26C4F' };
    case 'Patient':
      return { main: '#D99EF7' };
    default:
      return { main: '#FFF' };
  }
}