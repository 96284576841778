import pkce from 'oauth-pkce';

export function getPkce(size = 64): Promise<{ verifier: string; challenge: string }> {
  return new Promise((resolve, reject) => {
    pkce(size, (error, value) => {
      if (error) {
        return reject(error);
      }
      return resolve(value);
    })
  });
}

export function encodeObject(obj: Record<any, any>) {
  const toString = JSON.stringify(obj);
  return btoa(toString);
}

export function decodeObject<T extends Record<string, any>>(string: string): T {
  const decoded = atob(string);
  try {
    return JSON.parse(decoded) as T;
  } catch (e) {
    return {} as T
  }
}
