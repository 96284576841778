import { memo, useCallback } from 'react';
import { useChatPopup } from '../../contexts/chat.context';
import { ChatHeader } from './chat-header';
import { SingleChat } from './single-chat';
import { makeStyles, Paper, Portal } from '@material-ui/core';
import { useChat } from '@hu-care/chat-client/dist/react';
import { useHistory } from 'react-router-dom';
import { Insets, useInsets } from '../../contexts/insets';

const useStyles = makeStyles(theme => ({
  popup: (props: Insets) => ({
    position: 'fixed',
    bottom: 0,
    right: 10,
    height: 450,
    width: 300,
    backgroundColor: 'white',
    zIndex: 1000000,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
      width: '100vw',
      right: 0,
      left: 0,
      bottom: 0,
      top: props.top || 0,
    },
  }),
  singleChat: (props: Insets) => ({
    height: 450 - 48, // toolbar height
    [theme.breakpoints.down('xs')]: {
      height: `calc(100vh - ${48 + (props.top || 0)}px)`,
    },
  }),
}));

export const ChatPopup = memo(() => {
  const { close, destUser, error } = useChatPopup();
  const insets = useInsets();
  const classes = useStyles(insets);
  const { closeChat } = useChat();
  const history = useHistory();

  const onCloseChat = useCallback(() => {
    close();
    closeChat();
    if (history.location.query.action && history.location.query.action.startsWith('chat|')) {
      const newQuery = new URLSearchParams(history.location.query);
      newQuery.delete('action');
      history.replace(`${history.location.pathname}?${newQuery.toString()}`);
    }
  }, [close, closeChat, history]);

  if (!destUser) {
    return null;
  }

  return (
    <Portal container={document.body}>
      <Paper className={classes.popup}>
        <ChatHeader destUser={destUser} onClose={onCloseChat}/>
        <div className={classes.singleChat}>
          <SingleChat destUserId={destUser.id} error={error}/>
        </div>
      </Paper>
    </Portal>
  )
});
