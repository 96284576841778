import { useQuery } from '@apollo/client';
import { WhoAmIDocument, WhoAmIQuery } from '../generated/graphql';

export const useWhoAmI = () => {
  const { data, ...rest } = useQuery<WhoAmIQuery>(WhoAmIDocument, {
    fetchPolicy: 'cache-first',
  });

  return {
    ...rest,
    user: data?.whoami,
  }
};
