import { FC, createContext, useContext, useEffect, useState } from 'react';
import safeAreaInsets from 'safe-area-insets';

export interface Insets {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

const InsetCtx = createContext<Insets>({});

export const useInsets = () => useContext(InsetCtx);

export const InsetsProvider: FC = ({ children }) => {
  const [insets, setInsets] = useState<Insets>(safeAreaInsets);

  useEffect(() => {
    safeAreaInsets.onChange((newValue: any) => {
      setInsets({
        ...insets,
        ...newValue,
      });
    });

    return () => {
      safeAreaInsets.offChange(setInsets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInsets]);

  return (
    <InsetCtx.Provider value={insets}>
      {children}
    </InsetCtx.Provider>
  )
}
