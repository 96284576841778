import { RoutesKeys, RouteType } from './utils';

import { asyncLoad } from '@hu-care/react-utils';
import HomeIcon from '@material-ui/icons/Home';
import { PersonAdd } from '@material-ui/icons';

import { ReactComponent as HomeIconAlt } from '../assets/pictures/home-solid.svg';
import { NetworkIcon, NotificationIcon } from '../utils/icons';

export const commonRoutes: RouteType[] = [
  {
    key: RoutesKeys.updateRequired,
    component: asyncLoad(() => import('../containers/update-required.container')),
    path: '/aggiornamento-necessario',
    exact: true,
    config: {
      label: 'Aggiornamento necessario',
      title: 'Aggiornamento necessario',
      icon: HomeIcon,
      altIcon: <HomeIconAlt />,
      navBar: false,
      showBack: false,
      scopes: [],
      containerProps: {
        disableGutters: true,
      },
    },
  },
  {
    key: RoutesKeys.network,
    component: asyncLoad(() => import('../containers/network/network.container')),
    path: '/network',
    exact: true,
    config: {
      label: 'Network',
      icon: NetworkIcon,
      altIcon: <NetworkIcon />,
      navBar: true,
      showBack: false,
      scopes: [],
      containerProps: {
        disableGutters: true,
      },
    },
  },
  {
    key: RoutesKeys.createConnection,
    component: asyncLoad(() => import('../containers/create-connection/create-connection.container')),
    path: '/network/new',
    exact: true,
    config: {
      label: 'Aggiungi contatto',
      icon: PersonAdd,
      altIcon: <PersonAdd />,
      navBar: true,
      showBack: true,
      scopes: [],
      containerProps: {
        disableGutters: true,
      },
    },
  },
  {
    key: RoutesKeys.networkDetail,
    component: asyncLoad(() => import('../containers/professional-detail/professional-detail.container')),
    path: '/network/:id',
    exact: true,
    config: {
      label: 'Professionista',
      className: 'medic',
      icon: NetworkIcon,
      altIcon: <NetworkIcon />,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.networkReports,
    component: asyncLoad(() => import('../containers/reports/network-reports.container')),
    path: '/network/:id/reports',
    exact: true,
    config: {
      label: 'Referti',
      className: 'medic',
      icon: NetworkIcon,
      altIcon: <NetworkIcon />,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.dashboard,
    component: asyncLoad(() => import('../containers/dashboard/dashboard.container')),
    path: '/',
    exact: true,
    config: {
      label: 'Dashboard',
      icon: HomeIcon,
      altIcon: <HomeIconAlt />,
      navBar: true,
      showBack: false,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.notifications,
    component: asyncLoad(() => import('../containers/notifications/notifications.container')),
    path: '/notifications',
    exact: true,
    config: {
      label: 'Notifiche',
      icon: NotificationIcon,
      altIcon: <NotificationIcon />,
      navBar: true,
      showBack: false,
      scopes: [],
    },
  },
];
