import { FC } from 'react';
import PackageJson from '../../package.json';

import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { Insets, useInsets } from '../contexts/insets';
import { Capacitor } from '@capacitor/core';

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(4),
    justifyContent: 'center',
    height: '100%',
    display: 'flex',
  },
  logo: (props: Insets) => ({
    marginTop: '20%',
    width: '50%',
    maxWidth: 220,
    padding: '6px',
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
}));

const UpdateRequiredContainer: FC = () => {
  const insets = useInsets();
  const classes = useStyles(insets);
  const version = PackageJson.version;

  return (
    <Box width="100%" textAlign="center">
      <img src={'/logo_csw_yellow.svg'} alt={'App Logo'} className={classes.logo} />
      <Box className={classes.main} width="100%">
        <div>
          <Box width="100%" px={1} textAlign="center">
            <Box pb={2}>
              <Typography variant="h5" align="center">
                La tua versione di <strong>CSW Paziente</strong> ({version}) non è aggiornata.
              </Typography>
            </Box>

            <Typography variant="h5" align="center">
              Per continuare ad usare <strong>CSW Paziente</strong>, aggiorna l’app
            </Typography>
          </Box>

          {Capacitor.getPlatform() === 'web'
            ? <Box pt={2} textAlign="center" width="100%">
                <Button variant="outlined" onClick={() => window.location.reload()}>
                  Ricarica pagina
                </Button>
              </Box>
            : <>
              <Box py={3}>
                <Hidden xsDown>
                  <Grid container spacing={2} justify="space-evenly">
                    <Grid item>
                      <a href="https://play.google.com/store/apps/details?id=it.casadellasaluteweb.app_paziente">
                        <img
                          src="/google-play-badge.png"
                          style={{ height: 60, width: 'auto' }}
                          alt="play store"
                        />
                      </a>
                    </Grid>
                    <Grid item>
                      <a href="https://apps.apple.com/it/app/csw-paziente/id1364381071">
                        <img
                          src="/Download_on_the_App_Store_Badge_IT_RGB_blk_100317.svg"
                          style={{ height: 60, width: 'auto' }}
                          alt="app store"
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Hidden>

                <Hidden smUp>
                  <Box px={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box width="100%" style={{ textAlign: 'center' }}>
                          <a href="https://play.google.com/store/apps/details?id=it.casadellasaluteweb.app_paziente">
                            <img
                              src="/google-play-badge.png"
                              style={{ width: 200, height: 'auto' }}
                              alt="play store"
                            />
                          </a>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box width="100%" style={{ textAlign: 'center' }}>
                          <a href="https://apps.apple.com/it/app/csw-paziente/id1364381071">
                            <img
                              src="/Download_on_the_App_Store_Badge_IT_RGB_blk_100317.svg"
                              style={{ width: 200, height: 'auto' }}
                              alt="app store"
                            />
                          </a>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Hidden>
              </Box>

              <Box pb={2} px={2}>
                <Typography align="center">
                  Se l'aggiornamento non fosse ancora disponibile per il tuo dispositivo, nel frattempo puoi usare
                  la versione web di CSW Paziente cliccando sul seguente link:
                </Typography>
              </Box>

              <Box pb={2}>
                <Typography align="center" variant="h5">
                  <a href={process.env.REACT_APP_ORIGIN}>Vai all'applicazione web</a>
                </Typography>
              </Box>
            </>
          }
        </div>
      </Box>
    </Box>
  );
};

export default UpdateRequiredContainer;
