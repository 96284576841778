import { FC, useEffect, useState, memo, useCallback, useRef } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PackageJson from '../package.json';
import axios from 'axios';

import { useLocation } from 'react-router-dom';
import { makeClient } from './apollo/client';
import { LayoutProvider, Loading } from '@hu-care/react-layout';
import MainContainer from './containers/main.container';
import { routes } from './routes';
import { App as CapApp } from '@capacitor/app';
import { InsetsProvider } from './contexts/insets';
import { Box } from '@material-ui/core';
import { SkipAllError, useAuth } from './contexts/auth.context';
import { PatientProvider } from './contexts/patient.context';
import { useIsMobile } from './hooks/useIsMobile';
import UpdateRequiredContainer from './containers/update-required.container';
import { throttle } from 'lodash';
import { AlertsProvider } from './contexts/alerts.context';

declare const window: any;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [pathname]);

  return null;
}

const AppUrlListener: FC<any> = () => {
  const history = useHistory();

  useEffect(() => {
    CapApp.addListener('appUrlOpen', (data: any) => {
      const url = new URL(data.url);
      history.push(url.pathname + url.search);
    });
  }, [history]);
  return null;
};

const getShouldUpgrade = () => {
  const version = PackageJson.version;
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/status/patient/${version}`)
}

export const App = memo(() => {
  const { ready, getToken, refreshToken, token, logout } = useAuth();
  const [apollo, setApollo] = useState<ApolloClient<any> | null>(null);
  const [errored, setErrored] = useState<Error | boolean>(false);
  const isMobile = useIsMobile();
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const throttledGetShouldUpgrade = useRef(throttle(getShouldUpgrade, 60*1000));

  useEffect(() => {
    CapApp.addListener('appStateChange', ({ isActive: _isActive }) => {
      setIsActive(_isActive);
    });
  }, [setIsActive])

  useEffect(() => {
    if (isActive && throttledGetShouldUpgrade?.current) {
      const shouldUpgradePromise = throttledGetShouldUpgrade.current();

      if (shouldUpgradePromise) {
        shouldUpgradePromise
          .then(({ data: result }) => {
            setShouldUpgrade(!result);
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  }, [isActive, setShouldUpgrade]);

  const showOrHideRef = useRef<() => void>();

  useEffect(() => {
    showOrHideRef.current = () => {
      if (!window.customerly) return;
      if (isMobile) {
        window.customerly.hide();
      } else {
        window.customerly.show();
      }
    }
  }, [isMobile]);

  useEffect(() => {
    if (window.customerly && typeof window.customerly.load === 'function') {
      window.customerly.load({
        app_id: 'c8b16b08',
        accentColor: '#f1b80f',
        attributes: {
          env: '%NODE_ENV%',
          application: 'patient',
        },
        visible: false,
        callbacks: {
          onChatClosed: function () {
            if (showOrHideRef.current) {
              showOrHideRef.current();
            }
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showOrHideRef.current) {
      showOrHideRef.current();
    }
  }, [isMobile]);

  if (errored) {
    // Big error, catch by global Error boundary, user needs to reload the page
    throw errored;
  }
  const onRefreshFail = useCallback((err?: Error) => {
    if (err && err instanceof SkipAllError) {
      setErrored(err);
    } else {
      logout();
    }
  }, [logout, setErrored]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    setApollo(
      makeClient({
        tokenGetter: getToken,
        refreshToken: () => refreshToken(token?.refreshToken),
        onRefreshFail: onRefreshFail,
      }),
    );
  }, [ready, getToken, refreshToken, token, onRefreshFail]);

  if (!apollo) {
    return (
      <Box pt={8}>
        <Loading/>
      </Box>
    );
  }

  if (shouldUpgrade) {
    return <UpdateRequiredContainer />
  }

  return (
    <ApolloProvider client={apollo}>
      <ScrollToTop />
      <AppUrlListener />
      <AlertsProvider>
        <PatientProvider>
          <InsetsProvider>
            <LayoutProvider routes={routes}>
              <MainContainer/>
            </LayoutProvider>
          </InsetsProvider>
        </PatientProvider>
      </AlertsProvider>
    </ApolloProvider>
  );
});
