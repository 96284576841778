import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import it from 'date-fns/locale/it';
import './index.css';
import './print.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

import theme from './theme';
import { AuthProvider } from './contexts/auth.context';
import { createHistory } from '@hu-care/react-utils';
import { Router } from 'react-router';
import { Route } from 'react-router-dom';

if (process.env.REACT_APP_VERSION) {
  console.log(`v.${process.env.REACT_APP_VERSION}`);
}

theme.palette.secondary.light = '#AFAFAF';

const history = createHistory({
  basename: process.env.PUBLIC_URL || '/',
});

declare const window: any;

// window.userId = '4e4630aa-0431-458a-ba35-04ded360162f'; // Sejedjamaladdin Nekoofar
// window.userId = '56ebffc5-0819-4123-a626-03021a8c4405';

// window.userId = '82e2641a-f2e4-433f-9391-9f0a5afc2bc2'; // Franco Franchi
// window.taxId = 'TSTGZN31B56I596F'; // Franco Franchi

// window.userId = '694b5793-9e19-4bbb-b6d6-fcec9dfd118d'; // Celestino Fiorellino
// window.taxId = 'FRLCST80A01D575T';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
      <CssBaseline/>
      <Router history={history}>
        <AuthProvider skipAll={!!window.userId && process.env.NODE_ENV === 'development'}>
          <Route path="/callback">
            {() => null}
          </Route>
          <App/>
        </AuthProvider>
      </Router>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
