import { FC, useCallback } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box } from '@material-ui/core';

export const AlertMessage: FC<{
  message: import('../contexts/alerts.context').AlertMessage;
  onClose: (id: string) => void;
}> = ({ message, onClose }) => {

  const _onClose = useCallback(() => {
    if (message.canClose) {
      message.onClose && message.onClose();
      onClose(message.id);
    }
  }, [message, onClose]);

  return (
    <Box pb={1}>
      <Alert severity={message.severity} onClose={_onClose}>
        {message.title && <AlertTitle>{message.title}</AlertTitle>}
        {message.text}
      </Alert>
    </Box>
  )
}
