import { useQuery } from '@apollo/client';
import { isSameDay, startOfDay } from 'date-fns/esm';
import { useEffect, useMemo, useState } from 'react';
import { usePatient } from '../contexts/patient.context';
import {
  PlatformAlertsDocument,
  PlatformAlertsQuery,
  PlatformAlertsQueryVariables,
} from '../generated/graphql';

export const usePlatformAlerts = () => {
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    const int = setInterval(() => {
      setToday(oldDay => {
        const newDay = startOfDay(new Date());
        return isSameDay(oldDay, newDay) ? oldDay : newDay;
      });
    }, 3600000);

    return () => {
      clearInterval(int);
    }
  }, [setToday]);

  const { selectedPatient } = usePatient();
  const { data, loading, ...rest } = useQuery<PlatformAlertsQuery, PlatformAlertsQueryVariables>(PlatformAlertsDocument, {
    variables: {
      day: today,
    },
    skip: !selectedPatient,
    pollInterval: 5*60000,
  });

  return useMemo(() => ({
    loading,
    platformAlerts: data?.platformAlerts,
    ...rest,
  }), [loading, data, rest]);
}
