import { KeyboardEvent } from 'react';
import { KeyboardResize, Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

export class DeviceUtils {
  static closeKeyboard() {
    if (Capacitor.isNative) {
      return Keyboard.hide();
    }
  }

  static openKeyboard() {
    if (Capacitor.isNative) {
      return Keyboard.show();
    }
  }

  static keyboardResizeNone() {
    if (Capacitor.isNative) {
      return Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  static keyboardResizeNative() {
    if (Capacitor.isNative) {
      return Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  }

  static keyboardShowAccessBar() {
    if (Capacitor.isNative) {
      return Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  }

  static keyboardHideAccessBar() {
    if (Capacitor.isNative) {
      return Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
  }

  static async handleKeydown(event: KeyboardEvent<HTMLInputElement>) {
    const code = event.keyCode || event.which;
    if (code === 13) { // enter
      event.preventDefault();
      await DeviceUtils.closeKeyboard();
    }
  }
}
