import { FC, useMemo } from 'react';
import {
  AppBar,
  Box,
  Grid,
  Link,
  Select,
  Toolbar,
  MenuItem,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import { Link as RouteLink } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';

import { getRoute, RoutesKeys } from '../../routes';
import { NavBarProps } from '@hu-care/react-layout';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { useInsets, Insets } from '../../contexts/insets';
import { NotificationMenu } from '../notifications/notification-menu';
import { usePatient } from '../../contexts/patient.context';
import { AddProfileIcon, getPatientUserIcon, ProfileIcon } from '../../utils/icons';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>
  createStyles({
    logo: {
      // necessary for content to be below app bar
      height: theme.mixins.toolbar.minHeight,
      width: 'auto',
      padding: '6px 0 0 0',
    },
    logoBox: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    notifications: {
      position: 'absolute',
      right: 0,
    },
    toolbar: (props: Insets) => ({
      paddingTop: props.top || 0,
    }),
    bottomToolbar: (props: Insets) => ({
      boxSizing: 'content-box',
      paddingTop: 4,
    }),
    titleBar: (props: Insets) => ({
      ...theme.mixins.toolbar,
      top: +(theme?.mixins?.toolbar?.minHeight || 0) + (+(props?.top || 0)) + 6,
      zIndex: 1000,
      backgroundColor: 'white',
      width: '100%',
      position: 'fixed',
      borderBottom: '3px solid #D99EF7',
      boxShadow: '0px 6px 9px #00000029',

      '& *': {
        justifyContent: 'center',
        alignSelf: 'center',
      },
    }),
    selectProfile: {
      color: theme.palette.secondary.main,
      alignItems: 'center',
      whiteSpace: 'nowrap',
      fontSize: 20,
    },
    selectedProfileRow: {
      '@media only screen and (max-width: 320px)': {
        maxWidth: '22ch',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      fontWeight: theme.typography.fontWeightBold,
    },
    profileRow: {
      fontWeight: theme.typography.fontWeightBold,
    },
    title: {
      color: theme.palette.secondary.main,
      alignItems: 'center',
      whiteSpace: 'nowrap',
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
    },
    dropdownPaper: {
      left: '0 !important',
    },
    addProfileLink: {
      color: theme.palette.secondary.main,
      '& .MuiSvgIcon-root': {
        fill: theme.palette.secondary.main,
      },
      '& .MuiTypography-root': {
        paddingLeft: 0,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  }),
);

const ProfileSelectorDropdownIcon = withStyles({
  root: {
    marginRight: 28,
    transform: 'rotate(0deg)',
  },
})(ProfileIcon);

const TopNavBar: FC<{ insets: Insets; showNotifications?: boolean }> = ({ insets, showNotifications = true }) => {
  const classes = useStyles(insets);
  return (
    <AppBar position={'fixed'} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box component="nav" className={classes.logoBox}>
          <Link component={RouteLink} to={getRoute(RoutesKeys.dashboard)}>
            <img src={'/logo_csw_white.svg'} alt={'App Logo'} className={classes.logo}/>
          </Link>
        </Box>
        {showNotifications && (
          <NotificationMenu />
        )}
      </Toolbar>
    </AppBar>
  );
};

export const NavBar: FC<NavBarProps & { showNotifications?: boolean }> = (props) => {
  const insets = useInsets();
  const classes = useStyles(insets);
  const history = useHistory();
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const { setSelectedPatientId, selectedPatient, patientProfiles } = usePatient();

  const backButton = useMemo(() => {
    return props.showBack
      ? <IconButton onClick={history.goBack}>
          <ArrowBackIosIcon />
        </IconButton>
      : null
  }, [props, history]);

  const profileSelector = useMemo(() => {
    return <Select
      fullWidth
      IconComponent={ProfileSelectorDropdownIcon}
      startAdornment={backButton}
      disableUnderline
      value={selectedPatient?.id.toString() || ''}
      onChange={e => setSelectedPatientId(e.target.value as string)}
      className={classes.selectProfile}
      renderValue={(value) => {
        const profile = patientProfiles?.find(p => p.patientId === value)
        return profile
          ? (
            <ListItem>
              <ListItemText
                primary={
                  <Typography align="center" className={classes.selectedProfileRow}>
                    {`${profile.patient.name} ${profile.patient.surname}`}
                  </Typography>
                }
              />
            </ListItem>
          )
          : null
      }}
      MenuProps={{
        MenuListProps: {
          style: {
            padding: 0,
          },
        },
        PaperProps: {
          className: classes.dropdownPaper,
        },
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      {(patientProfiles || [])
        .map((profile) => {
          const Icon = getPatientUserIcon(profile.type);
          return <MenuItem
            divider
            dense
            key={profile.patientId.toString()}
            value={profile.patientId.toString()}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText
                style={{ margin: 0 }}
                primary={
                  <Typography className={classes.profileRow}>
                    {`${profile.patient.name} ${profile.patient.surname}`}
                  </Typography>
                }
                secondary={
                  t(`patient:profile-types.${profile.type}`)
                }
              />
          </MenuItem>
        })}

      <MenuItem
        component={RouteLink}
        to={getRoute(RoutesKeys.newProfile)}
        className={classes.addProfileLink}
      >
        <ListItemText
          primary={
            <Typography variant="subtitle2">
              aggiungi scheda paziente
            </Typography>
          }
        />
        <ListItemIcon>
          <AddProfileIcon />
        </ListItemIcon>
      </MenuItem>
    </Select>
  }, [setSelectedPatientId, classes, t, patientProfiles, backButton, selectedPatient]);

  const showProfileSelector = !props.title;

  return (
    <div>
      <TopNavBar insets={insets} showNotifications={props.showNotifications} />
      {
        pathname !== getRoute(RoutesKeys.updateRequired) &&
        <Toolbar className={clsx(classes.toolbar, classes.bottomToolbar)}/>
      }
      {props.navBar && (
        <Grid container className={classes.titleBar}>
          {!showProfileSelector && backButton && <Grid item xs={1}>
            {backButton}
          </Grid>}

          <Grid item xs={(showProfileSelector || !backButton) ? 12 : 11}>
            { props.title
              ? <Typography className={classes.title}>{props.title}</Typography>
              : profileSelector
            }
          </Grid>
        </Grid>
      )}
    </div>
  )
}
