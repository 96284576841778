import { FC, forwardRef, SVGProps } from 'react';
import clsx from 'clsx';

export interface CustomIconProps extends SVGProps<SVGSVGElement> {
  Icon: FC<SVGProps<SVGSVGElement>>;
}

export const CustomIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ Icon, ...props }, ref) => {
  return <Icon
    {...props}
    className={clsx(
      props.className,
      'MuiSvgIcon-root',
      'custom',
    )}
  />
})
