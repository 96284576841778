import acceptFile from 'attr-accept';
import { FileRejection } from 'react-dropzone';
import { TFunction } from 'react-i18next';

export const MAX_FILE_SIZE = 5000000; // ~5MB
export const ACCEPTED_FILES = ['.pdf', 'image/*'];
export const ACCEPTED_FILES_HUMAN = ['.pdf', '.jpg', '.png'];

export function convertBytesToMbsOrKbs(filesize: number) {
  let size = ''; // I know, not technically correct...

  if (filesize >= 1000000) {
    size = `${filesize / 1000000} megabytes`;
  } else if (filesize >= 1000) {
    size = `${filesize / 1000} kilobytes`;
  } else {
    size = `${filesize} bytes`;
  }

  return size;
}

export function isFileAccepted(file: { name: string, type: string }) {
  return acceptFile(file, ACCEPTED_FILES);
}

export function rejectedMessage(rejectedFile: any, acceptedFiles: any[], maxFileSize: number) {
  let message = `Impossibile caricare il file ${rejectedFile.name}. `;

  if (!acceptFile(rejectedFile, acceptedFiles)) {
    message += 'Estensione non supportata. ';
  }

  if (rejectedFile.size > maxFileSize) {
    message += `Il File è troppo grande. Dimensione massima consentita: ${convertBytesToMbsOrKbs(maxFileSize)}.`;
  }

  return message;
}

export interface CanHandleFilesOptions {
  newFiles: File[];
  reject?: FileRejection[];
  maxFiles: number;
  maxFileSize?: number;
  showMessage: (message: string) => void;
  t: TFunction;
}

export function canHandleFiles(options: CanHandleFilesOptions) {
  const { newFiles, maxFiles, reject, showMessage, t, maxFileSize = MAX_FILE_SIZE } = options;
  if (newFiles.map(f => isFileAccepted(f)).includes(false)) {
    showMessage(t('errors:not-accepted-extension'))
    return false;
  }

  if (reject && reject.length) {
    for (const { errors, file } of reject) {
      const error = errors[0];
      let transKey = 'cannot-upload-file';
      if (['too-many-files', 'file-too-large'].includes(error.code)) {
        transKey = error.code;
      }
      showMessage(t(`errors:${transKey}`, {
        maxFiles: maxFiles,
        maxFileSize: Math.floor(maxFileSize / 10000),
        filename: file.name,
      }));
    }
    return false;
  }
  if (newFiles.length > maxFiles) {
    showMessage(t('errors:too-many-files', { maxFiles: maxFiles }));
    return false;
  }
  return true;
}
