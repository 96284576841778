/**
 * This function merges two arrays (array1 and array2) by returning a new array with {start} array1
 * elements, followed by {then1} array1 elements, followed by {then2} array2 elements, and so on
 *
 * example:
 * karlMerge([1,1,1,1,1], [2,2,2,2,2], 3, 2, 2)
 * OUTPUT: [1, 1, 1, 2, 2, 1, 1, 2, 2, 2]
 *
 * @param array1 first array to be merged
 * @param array2 second array to be merged
 * @param start number of elements from array1 to be inserted consecutively at the beginning of the returned array
 * @param then1 number of elements from array1 to be inserted consecutively
 * @param then2 number of elements from array2 to be inserted consecutively
 */
export const karlMerge = (array1: any[], array2: any[], start: number, then1: number, then2: number) => {
  const arr1 = ([] as any[]).concat(array1) // shallow copy, so I can use shift later
  const arr2 = ([] as any[]).concat(array2) // shallow copyso I can use shift later

  let toReturn: any[] = []
  for (let i = 0; i < start; i++) {
    const newElem = arr1.shift()
    if (newElem) toReturn.push(newElem);
  }

  let newElem1, newElem2
  while (arr1.length && arr2.length) {
    for (let i = 0; i < then2; i++) {
      newElem2 = arr2.shift()
      if (newElem2) toReturn.push(newElem2);
    }
    for (let i = 0; i < then1; i++) {
      newElem1 = arr1.shift()
      if (newElem1) toReturn.push(newElem1);
    }
  }
  toReturn = toReturn.concat(arr2)
  toReturn = toReturn.concat(arr1)

  return toReturn;
}