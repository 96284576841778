import theme from '@hu-care/theme';

theme.palette.secondary.main = '#595959';
theme.palette.secondary.light = '#AFAFAF';

theme.palette.primary.light = 'rgba(243, 198, 61, 0.2)';

theme.palette.error.main = '#D00';

export default theme;
