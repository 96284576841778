/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  TaxId: any;
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  Weekday: any;
};

export type AddressFilterInput = {
  address?: Maybe<StringFieldFilterInput>;
  coordinates?: Maybe<GeolocationFilterInput>;
};

export type AvailsRender = {
  id: Scalars['ID'];
  created_at:  Scalars['DateTime'];
  updated_at:  Scalars['DateTime'];
  title?: Scalars['String'];
  date_from: Scalars['DateTime'];
  date_to?: Scalars['DateTime'];
  cycle_week: Scalars['Weekday'];
  cycle_day: Scalars['Weekday'];
  res_open_in:  Scalars['Float'];
  hour_from: Scalars['Time'];
  hour_to: Scalars['Time'];
  max_informer_number: Scalars['Int'];
  visibility: AgendaEntryVisibility;
  status: AgendaListStatus;
  assistantIds: Array<Scalars['String']>;
  exclude_provisions?: Array<Scalars['String']>;
  creatorId: Scalars['String'];
  ownerId: Scalars['String'];
  clinicRoomId: Scalars['String'];
  professionalId?: Scalars['String'];
  clinic_room: ClinicRoom;
  professional: Professional;
}

export type AvailsRenderInDay = {
  id: Scalars['ID'];
  title: Scalars['String'];
  created_at:  Scalars['DateTime'];
  updated_at:  Scalars['DateTime'];
  date_from: Scalars['DateTime'];
  date_to?: Scalars['DateTime'];
  cycle_week: Scalars['Weekday'];
  cycle_day: Scalars['Weekday'];
  res_open_in:  Scalars['Float'];
  hour_from: Scalars['Time'];
  hour_to: Scalars['Time'];
  max_informer_number: Scalars['Int'];
  visibility: AgendaEntryVisibility;
  status: AgendaListStatus;
  assistantIds: Array<Scalars['String']>;
  exclude_provisions?: Array<Scalars['String']>;
  professionalId?: Scalars['String'];
  clinic_room_id: Scalars['String'];
  clinic_room: ClinicRoom;
  ownerId: Scalars['String'];
  instances?: Array<AvailsInstanceWithEvents>;
}

export type AvailsInstanceWithEvents = {
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  created_at: Scalars['DateTime'];
  updated_at:  Scalars['DateTime'];
  override_hour_from?: Scalars['Time'];
  override_hour_to?: Scalars['Time'];
  override_visibility?: AgendaEntryVisibility;
  override_assistants_ids?: Array<Scalars['String']>;
  override_exclude_provisions?: Array<Scalars['String']>;
  appointments?: Array<AvailsAppointment>;
  breaks?: Array<AvailsBreak>;
}

export type AvailsAppointment = BaseEntity & AvailsEvent & {
  id: Scalars['ID'];
  event_type: AgendaEventType;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  hour_from: Scalars['Time'];
  hour_to: Scalars['Time'];
  notes: Scalars['String'];
  creator_id: Scalars['String'];
  professionalId: Scalars['String'];
  clinicRoomId: Scalars['String'];
  instanceId: Scalars['String'];
  instance: AvailsInstance;
  clinic: ClinicRoom;
  professional: Professional;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  unsubs_patient_name?: Scalars['String'];
  unsubs_patient_tax_id?: Scalars['TaxId'];
  patientProfessionalId?: Scalars['String'];
  professionalProvisionId?: Scalars['String'];
  provision?: ProfessionalProvision;
  patientProfessional?: PatientProfessional;
}

export type AvailsBreak = BaseEntity & AvailsEvent & {
  id: Scalars['ID'];
  event_type: AgendaEventType;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  hour_from: Scalars['Time'];
  hour_to: Scalars['Time'];
  notes: Scalars['String'];
  creator_id: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  instanceId: Scalars['String'];
  instance: AvailsInstance;
  clinic: ClinicRoom;
  professional: Professional;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
}

export type AvailsEvent = {
  id: Scalars['ID'];
  event_type: AgendaEventType;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  hour_from: Scalars['Time'];
  hour_to: Scalars['Time'];
  notes: Scalars['String'];
  creator_id: Scalars['String'];
  professionalId: Scalars['String'];
  clinicId: Scalars['String'];
  instanceId: Scalars['String'];
  instance: AvailsInstance;
  clinic: ClinicRoom;
  professional: Professional;
}

export type AvailsInstance = BaseEntity & {
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  date: Scalars['DateTime'];
  override_hour_from: Scalars['Time'];
  override_hour_to: Scalars['Time'];
  override_visibility?: AgendaEntryVisibility;
  override_assistants_ids: Array<Scalars['String']>;
  override_exclude_provisions?: Array<Scalars['String']>;
  renderingId: Scalars['String'];
  clinic: ClinicRoom;
  assistants: Array<Assistant>;
}

export type SlotType = {
  render: AvailsRenderInDay;
  slots: Array<Scalars['Time']>;
}

export type TimeSlotAvails = {
  day: Scalars['Date'];
  slots: Array<SlotType>;
}

export type Administrator = Professional & BaseEntity & {
  __typename?: 'Administrator';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  professionalsSharedWithMe: Array<ProfessionalShares>;
  professionalsSharedByMe: Array<ProfessionalShares>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  logo?: Maybe<Attachment>;
};

export type AgendaEntry = BaseEntity & {
  __typename?: 'AgendaEntry';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  weekday: Scalars['Weekday'];
  override?: Maybe<Scalars['Date']>;
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  assistantIds: Array<Scalars['String']>;
  visibility: AgendaEntryVisibility;
  createdBy?: Maybe<Scalars['String']>;
  agendaWeekId?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  professionalId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  clinic: ClinicRoom;
  professional?: Maybe<Professional>;
  assistants: Array<Maybe<Assistant>>;
};

export type AgendaEntryInDay = {
  __typename?: 'AgendaEntryInDay';
  id: Scalars['ID'];
  weekday: Scalars['Weekday'];
  override?: Maybe<Scalars['Date']>;
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  assistantIds: Array<Scalars['String']>;
  visibility: AgendaEntryVisibility;
  createdBy?: Maybe<Scalars['String']>;
  day: Scalars['Date'];
  clinicId: Scalars['String'];
  professionalId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  agendaWeekId?: Maybe<Scalars['String']>;
  professional?: Maybe<Professional>;
  assistants: Array<Maybe<Assistant>>;
  clinic: ClinicRoom;
  creator?: Maybe<User>;
};

export enum AgendaEntryVisibility {
  OnlyAssistants = 'ONLY_ASSISTANTS',
  All = 'ALL',
  OnlyAssisted = 'ONLY_ASSISTED',
  OnlyFreelancer = 'ONLY_FREELANCER',
  OnlyMedic = 'ONLY_MEDIC'
}

// export type AgendaEvent = {
//   id: Scalars['ID'];
//   createdAt: Scalars['DateTime'];
//   updatedAt: Scalars['DateTime'];
//   type: AgendaEventType;
//   day: Scalars['Date'];
//   from: Scalars['Time'];
//   to: Scalars['Time'];
//   notes: Scalars['String'];
//   createdBy: Scalars['String'];
//   professionalId: Scalars['String'];
//   clinicId: Scalars['String'];
//   clinic: ClinicRoom;
//   professional: Professional;
//   creator?: Maybe<User>;
// };

export enum AgendaEventType {
  Appointment = 'Appointment',
  Break = 'Break'
}

export type AgendaEventTypeFieldFilterInput = {
  eq?: Maybe<AgendaEventType>;
  ne?: Maybe<AgendaEventType>;
  in?: Maybe<Array<AgendaEventType>>;
  nin?: Maybe<Array<AgendaEventType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

// export type AgendaList = BaseEntity & {
//   __typename?: 'AgendaList';
//   id: Scalars['ID'];
//   createdAt: Scalars['DateTime'];
//   updatedAt: Scalars['DateTime'];
//   name: Scalars['String'];
//   status: AgendaListStatus;
//   from: Scalars['Date'];
//   to?: Maybe<Scalars['Date']>;
//   openFor: Scalars['Int'];
//   professionalId: Scalars['String'];
//   weeks: Array<AgendaWeek>;
// };

export type AgendaListFilterInput = {
  _or?: Maybe<Array<AgendaListFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  status?: Maybe<AgendaListStatusFieldFilterInput>;
  openFor?: Maybe<IntFieldFilterInput>;
};

export enum AgendaListStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED'
}

export type AgendaListStatusFieldFilterInput = {
  eq?: Maybe<AgendaListStatus>;
  ne?: Maybe<AgendaListStatus>;
  in?: Maybe<Array<AgendaListStatus>>;
  nin?: Maybe<Array<AgendaListStatus>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

// export type AgendaWeek = BaseEntity & {
//   __typename?: 'AgendaWeek';
//   id: Scalars['ID'];
//   createdAt: Scalars['DateTime'];
//   updatedAt: Scalars['DateTime'];
//   order: Scalars['Int'];
//   agendaListId: Scalars['String'];
//   entries: Array<AgendaEntry>;
// };

// export type Appointment = AgendaEvent & BaseEntity & {
//   __typename?: 'Appointment';
//   id: Scalars['ID'];
//   createdAt: Scalars['DateTime'];
//   updatedAt: Scalars['DateTime'];
//   type: AgendaEventType;
//   day: Scalars['Date'];
//   from: Scalars['Time'];
//   to: Scalars['Time'];
//   notes: Scalars['String'];
//   createdBy: Scalars['String'];
//   professionalId: Scalars['String'];
//   clinicId: Scalars['String'];
//   clinic: ClinicRoom;
//   professional: Professional;
//   creator?: Maybe<User>;
//   patientName?: Maybe<Scalars['String']>;
//   patientTaxId?: Maybe<Scalars['TaxId']>;
//   patientProfessionalId?: Maybe<Scalars['String']>;
//   provisionId?: Maybe<Scalars['String']>;
//   provision?: Maybe<ProfessionalProvision>;
//   patientProfessional?: Maybe<PatientProfessional>;
// };

// export type AppointmentFilterInput = {
//   _or?: Maybe<Array<AppointmentFilterInput>>;
//   id?: Maybe<IdFieldFilterInput>;
//   createdAt?: Maybe<DateFieldFilterInput>;
//   updatedAt?: Maybe<DateFieldFilterInput>;
//   type?: Maybe<AgendaEventTypeFieldFilterInput>;
//   day?: Maybe<DateFieldFilterInput>;
//   from?: Maybe<TimeScalarFieldFilterInput>;
//   to?: Maybe<TimeScalarFieldFilterInput>;
//   createdBy?: Maybe<StringFieldFilterInput>;
//   patientName?: Maybe<StringFieldFilterInput>;
//   patientTaxId?: Maybe<TaxIdScalarFieldFilterInput>;
//   clinic?: Maybe<ClinicRoomFilterInput>;
//   professional?: Maybe<ProfessionalFilterInput>;
//   provision?: Maybe<ProfessionalProvisionFilterInput>;
//   patientProfessional?: Maybe<PatientProfessionalFilterInput>;
// };

export type AvailsAppointmentFilterInput = {
  _or?: Maybe<Array<AvailsAppointmentFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  created_at?: Maybe<DateFieldFilterInput>;
  updated_at?: Maybe<DateFieldFilterInput>;
  type?: Maybe<AgendaEventTypeFieldFilterInput>;
  hour_from?: Maybe<TimeScalarFieldFilterInput>;
  hour_to?: Maybe<TimeScalarFieldFilterInput>;
  created_by?: Maybe<StringFieldFilterInput>;
  patientName?: Maybe<StringFieldFilterInput>;
  patientTaxId?: Maybe<TaxIdScalarFieldFilterInput>;
  clinic_room?: Maybe<ClinicRoomFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
  professional_provision?: Maybe<ProfessionalProvisionFilterInput>;
  patient_professional?: Maybe<PatientProfessionalFilterInput>;
};

export type AvailsAppointmentOrderInput = {
  id?: Maybe<Order>;
  created_at?: Maybe<Order>;
  updated_at?: Maybe<Order>;
  event_type?: Maybe<Order>;
  hour_from?: Maybe<Order>;
  hour_to?: Maybe<Order>;
  creator_id?: Maybe<Order>;
  creator_professional_id?: Maybe<Order>;
  updated_by?: Maybe<Order>;
  deleted_by?: Maybe<Order>;
  unsubs_patient_name?: Maybe<Order>;
  unsubs_patient_tax_id?: Maybe<Order>;
};

export type AppointmentsPaginatedResponse = {
  __typename?: 'AvailsAppointmentsPaginatedResponse';
  items: Array<AvailsAppointment>;
  paginationInfo: PaginationInfo;
};

export type Assistant = Professional & BaseEntity & {
  __typename?: 'Assistant';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  professionalsSharedWithMe: Array<ProfessionalShares>;
  professionalsSharedByMe: Array<ProfessionalShares>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  logo?: Maybe<Attachment>;
  assistantTermsAndConditions: Scalars['Boolean'];
};

export type Attachment = BaseEntity & {
  __typename?: 'Attachment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  fileSize: Scalars['Int'];
  storeType: StoreType;
  url: Scalars['String'];
};

export type AvailabilityInput = {
  chat?: Maybe<Scalars['Boolean']>;
  requests?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  enableAllEmailNotifications?: Maybe<Scalars['Boolean']>;
};

export type BaseEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type BooleanFieldFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

// export type Break = AgendaEvent & BaseEntity & {
//   __typename?: 'Break';
//   id: Scalars['ID'];
//   createdAt: Scalars['DateTime'];
//   updatedAt: Scalars['DateTime'];
//   type: AgendaEventType;
//   day: Scalars['Date'];
//   from: Scalars['Time'];
//   to: Scalars['Time'];
//   notes: Scalars['String'];
//   createdBy: Scalars['String'];
//   professionalId: Scalars['String'];
//   clinicId: Scalars['String'];
//   clinic: ClinicRoom;
//   professional: Professional;
//   creator?: Maybe<User>;
// };

export type City = BaseEntity & {
  __typename?: 'City';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  code: Scalars['String'];
  registryCode: Scalars['String'];
  cap: Array<Scalars['String']>;
  isDistrict: Scalars['Boolean'];
  district: District;
  region: Region;
  coordinates?: Maybe<Point>;
};

export type CityFilterInput = {
  _or?: Maybe<Array<CityFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  code?: Maybe<StringFieldFilterInput>;
  registryCode?: Maybe<StringFieldFilterInput>;
  isDistrict?: Maybe<CityIsDistrictFieldFilterInput>;
};

export type CityIsDistrictFieldFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type CityOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  code?: Maybe<Order>;
  registryCode?: Maybe<Order>;
  isDistrict?: Maybe<Order>;
};

export type CityPaginatedResponse = {
  __typename?: 'CityPaginatedResponse';
  items: Array<City>;
  paginationInfo: PaginationInfo;
};

export type Clinic = BaseEntity & {
  __typename?: 'Clinic';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  professionalId: Scalars['String'];
  address: EmbedAddress;
  professional: Professional;
  rooms: Array<ClinicRoom>;
};

export type ClinicFilterInput = {
  _or?: Maybe<Array<ClinicFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
};

export type ClinicOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  phone?: Maybe<Order>;
};

export type ClinicRoom = BaseEntity & {
  __typename?: 'ClinicRoom';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  meta: Scalars['JSON'];
  clinicId: Scalars['String'];
  provisions: Array<ProfessionalProvision>;
  clinic: Clinic;
};

export type ClinicRoomFilterInput = {
  _or?: Maybe<Array<ClinicRoomFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
};

export type ClinicRoomFromDay = {
  __typename?: 'ClinicRoomFromDay';
  room: ClinicRoom;
  fromDay: Scalars['Date'];
  professionalId: Scalars['String'];
  professional: Professional;
};

export type ClinicRoomFromDayAvails = {
  __typename?: 'ClinicRoomFromDayAvails';
  room: ClinicRoom;
  fromDay: Scalars['Date'];
  professionalId: Scalars['String'];
  professional: Professional;
};

export type CopyAgendaListInput = {
  from: Scalars['Date'];
  status?: Maybe<AgendaListStatus>;
  openFor?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateAgendaEntryInput = {
  weekday: Scalars['Weekday'];
  disabled: Scalars['Boolean'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  informers: Scalars['Int'];
  visibility: AgendaEntryVisibility;
  clinicId: Scalars['String'];
  assistantIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateAgendaListInput = {
  name: Scalars['String'];
  from: Scalars['Date'];
  openFor: Scalars['Int'];
  status?: Maybe<AgendaListStatus>;
};

export type CreateAppointmentInput = {
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  provisionId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['String']>;
  shareWithGroupMedicine?: Maybe<Scalars['Boolean']>;
};

export type CreateAvailsAppointmentInput = {
  hour_from: Scalars['Time'];
  hour_to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  clinicId: Scalars['String'];
  professionalProvisionId?: Maybe<Scalars['String']>;
  patientProfessionalId?: Maybe<Scalars['String']>;
  unsubs_patient_name?: Maybe<Scalars['String']>;
  unsubs_patient_tax_id?: Maybe<Scalars['String']>;
  shareWithGroupMedicine?: Maybe<Scalars['Boolean']>;
}

export type CreateAttachmentInput = {
  file: Scalars['Upload'];
  destination: Scalars['String'];
};

export type CreateBreakInput = {
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
};

export type CreateClinicInput = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  rooms?: Maybe<Array<CreateClinicRoomInput>>;
  address: CreateEmbedAddressInput;
};

export type CreateClinicRoomInput = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  provisionIds?: Maybe<Array<Scalars['String']>>;
  meta: Scalars['JSON'];
};

export type CreateCryptedEmbedAddressInput = {
  address: Scalars['String'];
  municipalityId: Scalars['String'];
};

export type CreateDelegationInput = {
  taxId: Scalars['TaxId'];
  name: Scalars['String'];
  surname: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
  patientProfessionalId: Scalars['String'];
  types?: Maybe<Array<DelegationType>>;
};

export type CreateEmbedAddressInput = {
  address?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  municipalityId: Scalars['String'];
  coordinates?: Maybe<LatLngInput>;
};

export type CreateEmbedContactInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
};

export type CreateMedicInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer?: Maybe<Scalars['Boolean']>;
  registerDistrictId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
  availability?: Maybe<AvailabilityInput>;
  termsAndConditions: Scalars['Boolean'];
};

export type CreateOperatorInput = {
  profession?: Maybe<OperatorProfession>;
  termsAndConditions: Scalars['Boolean'];
};

export type CreateOrganizationSaInput = {
  name?: Maybe<Scalars['String']>;
  isGroupMedicine?: Maybe<Scalars['Boolean']>;
};

export type CreatePatientInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
  personalMedicId?: Maybe<Scalars['String']>;
};

export type CreatePatientProfessionalInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address: CreateCryptedEmbedAddressInput;
};

export type CreateProfessionalProvisionInput = {
  provisionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  duration: Scalars['Int'];
};

export type CreateQuickAppointmentInput = {
  day: Scalars['Date'];
  from: Scalars['Time'];
  to: Scalars['Time'];
  notes?: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  provisionId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['String']>;
};

export type CreateReportInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<CreateAttachmentInput>>;
  medicName?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type CreateRequestInput = {
  recipientId: Scalars['String'];
  recipientType: RequestRecipientType;
  title?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  type: RequestType;
  private?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<CreateAttachmentInput>>;
};

export type CreateRequestMessageInput = {
  message: Scalars['String'];
  attachments?: Maybe<Array<CreateAttachmentInput>>;
};

export type CreateUserPatientInput = {
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
  address?: Maybe<CreateEmbedAddressInput>;
};

export type CryptedEmbedAddress = {
  __typename?: 'CryptedEmbedAddress';
  address: Scalars['String'];
  municipalityName: Scalars['String'];
  districtName: Scalars['String'];
  nationName: Scalars['String'];
  municipalityId?: Maybe<Scalars['String']>;
};

export type CupProvider = {
  __typename?: 'CupProvider';
  id: Scalars['ID'];
  date: Scalars['Date'];
  duration: Scalars['String'];
  price: Scalars['String'];
  address: Scalars['String'];
  addressName: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  trackUrl: Scalars['String'];
};


export type DateDFilterInputType = {
  eq?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  nin?: Maybe<Array<Scalars['Date']>>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type DateFieldFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  ne?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  is_null?: Maybe<Scalars['Boolean']>;
};


export type Delegation = BaseEntity & {
  __typename?: 'Delegation';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  taxId: Scalars['TaxId'];
  name: Scalars['String'];
  surname: Scalars['String'];
  createdBy: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  patientProfessionalId: Scalars['String'];
  typeCode: Scalars['Float'];
  types: Array<DelegationType>;
};

export enum DelegationType {
  Generic = 'Generic',
  InformedConsent = 'InformedConsent'
}

export type District = {
  __typename?: 'District';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type EmbedAddress = {
  __typename?: 'EmbedAddress';
  address?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  municipalityName?: Maybe<Scalars['String']>;
  districtName?: Maybe<Scalars['String']>;
  nationName?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Point>;
  municipalityId?: Maybe<Scalars['String']>;
  municipality?: Maybe<City>;
};

export type EmbedAvailability = {
  __typename?: 'EmbedAvailability';
  chat: Scalars['Boolean'];
  requests: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  email: Scalars['Boolean'];
  enableAllEmailNotifications: Scalars['Boolean'];
};

export type EmbedContact = {
  __typename?: 'EmbedContact';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
};

export type FindClinicFilterInput = {
  _or?: Maybe<Array<ClinicFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
  geolocation?: Maybe<GeolocationFilterInput>;
  address?: Maybe<AddressFilterInput>;
};

export type FloatFieldFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type FrequentProvision = {
  __typename?: 'FrequentProvision';
  id: Scalars['ID'];
  name: Scalars['String'];
  isCup: Scalars['Boolean'];
};

export type GeolocationFilterInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  distance?: Maybe<Scalars['Int']>;
};

/** Order by clause for the `sortBy` argument on the query `getInvoicesByPatient`. */
export type GetInvoicesByPatientSortByOrderByClause = {
  /** The column that is used for ordering. */
  field: SortInvoices;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for the `sortBy` argument on the query `getInvoicesByRecipientTaxId`. */
export type GetInvoicesByRecipientTaxIdSortByOrderByClause = {
  /** The column that is used for ordering. */
  field: SortInvoices;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for the `sortBy` argument on the query `getInvoices`. */
export type GetInvoicesSortByOrderByClause = {
  /** The column that is used for ordering. */
  field: SortInvoices;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type IdFieldFilterInput = {
  eq?: Maybe<Scalars['ID']>;
  ne?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  nin?: Maybe<Array<Scalars['ID']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type IntFieldFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Invite = BaseEntity & {
  __typename?: 'Invite';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  type: InviteType;
  inviteRole?: Maybe<Scalars['String']>;
  invitedUserId?: Maybe<Scalars['String']>;
  inviter: Professional;
};

export enum InviteType {
  Assistant = 'ASSISTANT',
  Organization = 'ORGANIZATION',
  Patient = 'PATIENT'
}

export type InviteUserInput = {
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['TaxId']>;
  phone?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Importo del bollo, se dovuto */
  bollo: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceOwner: InvoiceOwner;
  invoiceRows: Array<InvoiceRow>;
  invoiceType: InvoiceType;
  /** Rifiuto del consenso all'invio al sistema TS */
  noTs?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  patientId?: Maybe<Scalars['ID']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  payedWith?: Maybe<Scalars['Int']>;
  paymentInfo?: Maybe<Scalars['String']>;
  professionalId: Scalars['Int'];
  recipientAddress: Scalars['String'];
  recipientAnag: Scalars['String'];
  recipientLocation?: Maybe<Scalars['String']>;
  recipientTaxId?: Maybe<Scalars['String']>;
  /** Serie della fattura, opzionale */
  serie?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  /** Documento da inviare al sistema TS */
  ts?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode: Scalars['Int'];
  vatId?: Maybe<Scalars['String']>;
  vatPercent?: Maybe<Scalars['Float']>;
  /** Ritenuta d'acconto */
  withholdingTax?: Maybe<Scalars['Boolean']>;
  year: Scalars['Int'];
};

export type InvoiceInput = {
  /** Importo del bollo, se dovuto */
  bollo: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoiceRows?: Maybe<Array<InvoiceRowRelatedInput>>;
  invoiceType: InvoiceType;
  /** Rifiuto del consenso all'invio al sistema TS */
  noTs?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  patientId?: Maybe<Scalars['ID']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  payedWith?: Maybe<Scalars['Int']>;
  paymentInfo?: Maybe<Scalars['String']>;
  professionalId: Scalars['Int'];
  recipientAddress: Scalars['String'];
  recipientAnag: Scalars['String'];
  recipientLocation?: Maybe<Scalars['String']>;
  recipientTaxId?: Maybe<Scalars['String']>;
  /** Serie della fattura, opzionale */
  serie?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  /** Documento da inviare al sistema TS */
  ts?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode: Scalars['Int'];
  vatId?: Maybe<Scalars['String']>;
  vatPercent?: Maybe<Scalars['Float']>;
  /** Ritenuta d'acconto */
  withholdingTax?: Maybe<Scalars['Boolean']>;
  year: Scalars['Int'];
};

export type InvoiceOptions = {
  __typename?: 'InvoiceOptions';
  defaultInvoiceType: InvoiceType;
  defaultPaymentInfo?: Maybe<Scalars['String']>;
  defaultSerie?: Maybe<Scalars['String']>;
  defaultVatCode?: Maybe<Scalars['Int']>;
  professionalId: Scalars['Int'];
  taxId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
};

export type InvoiceOptionsInput = {
  defaultInvoiceType: InvoiceType;
  defaultPaymentInfo?: Maybe<Scalars['String']>;
  defaultSerie?: Maybe<Scalars['String']>;
  defaultVatCode?: Maybe<Scalars['Int']>;
  professionalId: Scalars['Int'];
  taxId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
};

export type InvoiceOwner = {
  __typename?: 'InvoiceOwner';
  role: Scalars['String'];
  name: Scalars['String'];
  surname: Scalars['String'];
  type: Scalars['String'];
};

/** A paginated list of Invoice items. */
export type InvoicePaginator = {
  __typename?: 'InvoicePaginator';
  /** A list of Invoice items. */
  data: Array<Invoice>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type InvoicePaymentType = {
  __typename?: 'InvoicePaymentType';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type InvoiceRow = {
  __typename?: 'InvoiceRow';
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  invoiceId: Scalars['Int'];
  isNote?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode?: Maybe<Scalars['Int']>;
};

export type InvoiceRowInput = {
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  invoiceId: Scalars['Int'];
  isNote?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode?: Maybe<Scalars['Int']>;
};

export type InvoiceRowRelatedInput = {
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['Int']>;
  isNote?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCode?: Maybe<Scalars['Int']>;
};

export enum InvoiceType {
  Fattura = 'FATTURA',
  Notadicredito = 'NOTADICREDITO',
  Notula = 'NOTULA',
  Ricevuta = 'RICEVUTA'
}

export type InvoiceVatCode = {
  __typename?: 'InvoiceVatCode';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  percentage: Scalars['Float'];
};


export type JsonFieldFilterInput = {
  eq?: Maybe<Scalars['JSON']>;
  ne?: Maybe<Scalars['JSON']>;
  in?: Maybe<Array<Scalars['JSON']>>;
  nin?: Maybe<Array<Scalars['JSON']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type LatLngInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type License = BaseEntity & {
  __typename?: 'License';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  transactionType: PaymentType;
  transactionId: Scalars['String'];
  transactionData: Scalars['JSON'];
  code: Scalars['String'];
  licenseType: LicenseType;
  priceId: Scalars['String'];
};

export type LicenseFilterInput = {
  _or?: Maybe<Array<LicenseFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  expiresAt?: Maybe<DateFieldFilterInput>;
  transactionType?: Maybe<PaymentTypeFieldFilterInput>;
  transactionId?: Maybe<StringFieldFilterInput>;
  licenseType?: Maybe<LicenseTypeFilterInput>;
};

export type LicenseType = {
  __typename?: 'LicenseType';
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeProduct: StripeProduct;
};

export type LicenseTypeFilterInput = {
  _or?: Maybe<Array<LicenseTypeFilterInput>>;
};

export enum MassiveEmailTarget {
  AllPatients = 'ALL_PATIENTS',
  PersonalPatients = 'PERSONAL_PATIENTS',
  FreelancerPatients = 'FREELANCER_PATIENTS',
  Networks = 'NETWORKS',
  PatientsFilter = 'PATIENTS_FILTER'
}

export type MassiveEmailTargetFilterInput = {
  birthDay?: Maybe<DateFieldFilterInput>;
  sex?: Maybe<Scalars['String']>;
  municipalityId?: Maybe<Scalars['Int']>;
  target?: Maybe<MassiveEmailTarget>;
};

export type Medic = Professional & BaseEntity & {
  __typename?: 'Medic';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  professionalsSharedWithMe: Array<ProfessionalShares>;
  professionalsSharedByMe: Array<ProfessionalShares>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  logo?: Maybe<Attachment>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer: Scalars['Boolean'];
  contacts?: Maybe<EmbedContact>;
  termsAndConditions: Scalars['Boolean'];
  registerDistrictId?: Maybe<Scalars['String']>;
  availability: EmbedAvailability;
  registerDistrict?: Maybe<City>;
  /** @deprecated Use availability.chat */
  chatEnabled: Scalars['Boolean'];
  /** @deprecated Use availability.requests */
  requestsEnabled: Scalars['Boolean'];
};

export type MedicAgendaFilterInput = {
  _or?: Maybe<Array<AgendaListFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  status?: Maybe<AgendaListStatusFieldFilterInput>;
  openFor?: Maybe<IntFieldFilterInput>;
  from?: Maybe<DateDFilterInputType>;
  to?: Maybe<DateDFilterInputType>;
};

export type MedicAgendaOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  status?: Maybe<Order>;
  from?: Maybe<Order>;
  to?: Maybe<Order>;
  openFor?: Maybe<Order>;
};

export enum MedicType {
  Mmg = 'MMG',
  Pls = 'PLS'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptAssistantInvite: Professional;
  acceptDelegations: PatientProfessional;
  acceptOrganizationInvite: OrganizationProfessional;
  acceptPatientProfessionalTransfer: PatientProfessionalTransfer;
  acceptPersonalMedicTransfer: PersonalMedicTransfer;
  acceptProfessionalShare: ProfessionalShares;
  acceptShare: ReportSharedWith;
  addChatUser: Scalars['Boolean'];
  addSpecializationToMedic: Specialization;
  answerRequestAsProfessional: RequestMessage;
  archivePatientProfessional: PatientProfessional;
  // assignMedicToEntry: AgendaEntry;
  /** @deprecated use personalMedicTransfer.requestPersonalMedic */
  assignPersonalMedic?: Maybe<Medic>;
  // copyAgendaList: AgendaList;
  // createAgendaEntry: AgendaEntry;
  // createAgendaList: AgendaList;
  // createAgendaWeek: AgendaWeek;
  createAppointment: AvailsAppointment;
  createAppointmentAsPatient: AvailsAppointment;
  // createBreak: Break;
  createChat: Scalars['String'];
  createClinic: Clinic;
  createClinicRoom: ClinicRoom;
  createDelegation: Delegation;
  createManyAgendaEntry: Array<AgendaEntry>;
  createMedic: Medic;
  createOperator: Operator;
  createOrUpdateInvoice?: Maybe<Invoice>;
  createOrUpdateInvoiceOptions?: Maybe<InvoiceOptions>;
  createOrUpdateInvoiceRow?: Maybe<InvoiceRow>;
  createOrganizationSa: OrganizationSa;
  createOverrideEntry: AgendaEntryInDay;
  createPatientProfessional: PatientProfessional;
  createPatientProfessionalAsPatient: PatientProfessional;
  createPatientProfessionalTransfer: PatientProfessionalTransfer;
  createProfessionalProvision: ProfessionalProvision;
  createProfessionalShare: ProfessionalShares;
  createQuickAppointment: AvailsAppointment;
  createReportAsPatient: Report;
  createReportAsProfessional: Report;
  createRequestAsPatient: Request;
  createRequestAsProfessional: Request;
  createUserPatient: PatientUser;
  /** Returns the Report ID */
  declineShare: Scalars['ID'];
  deleteAdministratorProfile?: Maybe<Scalars['ID']>;
  deleteAgendaEntry: Scalars['ID'];
  deleteAgendaList: Scalars['ID'];
  deleteAgendaWeek: Scalars['ID'];
  deleteAppointment: Scalars['ID'];
  deleteAvailsAppointmentAsPatient: Scalars['ID'];
  deleteAssistantProfile?: Maybe<Scalars['ID']>;
  deleteBreak: Scalars['ID'];
  deleteClinic: Scalars['ID'];
  deleteClinicRoom: Scalars['ID'];
  deleteConnection: Scalars['ID'];
  deleteDelegation: Scalars['ID'];
  deleteInvite: Scalars['Boolean'];
  deleteInvoice?: Maybe<Invoice>;
  deleteInvoiceRow?: Maybe<InvoiceRow>;
  deleteMedicProfile?: Maybe<Scalars['ID']>;
  deleteOperatorProfile?: Maybe<Scalars['ID']>;
  deleteOrganizationSa: Scalars['ID'];
  deletePatientProfessionalTransfer: Scalars['ID'];
  deletePersonalMedicTransfer: Scalars['ID'];
  deleteProfessionalProvision: Scalars['ID'];
  deleteProfessionalShare: Scalars['ID'];
  deleteReport: Scalars['ID'];
  deleteReportAsProfessional: Scalars['ID'];
  deleteRequest: Scalars['ID'];
  deleteUserPatient: Scalars['ID'];
  denyPatientProfessionalTransfer: Scalars['ID'];
  denyPersonalMedicTransfer: Scalars['ID'];
  denyProfessionalShare: Scalars['ID'];
  dissociateFromEntry: Scalars['ID'];
  getChatAccessToken: Scalars['String'];
  grantUserAccessToPatient: PatientUser;
  inviteAssistant: Invite;
  invitePatient: Invite;
  inviteToOrganization: Invite;
  markManyNotificationsAsRead: Array<Notification>;
  markNotificationAsRead: Notification;
  markRequestMessageAsReadAsProfessional: RequestMessage;
  mockNotification: Notification;
  overrideDay: Array<AgendaEntryInDay>;
  removePersonalMedic: Scalars['Boolean'];
  removeProfessionalFromOrganization: Scalars['ID'];
  removeSpecializationFromMedic: Scalars['ID'];
  requestConnection: Network;
  requestPersonalMedic: PersonalMedicTransfer;
  resetDay: Array<AgendaEntryInDay>;
  resolveRequest: Request;
  revokeReportShare: Scalars['ID'];
  revokeSharePatientWithProfessional: Scalars['Boolean'];
  revokeUserAccessToPatient: Scalars['ID'];
  sendMail: Scalars['Boolean'];
  sendMassiveEmail: Scalars['Boolean'];
  sharePatientWithProfessional: Scalars['Boolean'];
  shareReport: PatientProfessional;
  updateAgendaEntry: AgendaEntry;
  // updateAgendaList: AgendaList;
  // updateAgendaWeek: AgendaWeek;
  // updateAppointment: Appointment;
  updateAssistant: Assistant;
  // updateBreak: Break;
  updateClinic: Clinic;
  updateClinicRoom: ClinicRoom;
  updateConnection: Network;
  updateDelegation: Delegation;
  updateHeaderFooter: Professional;
  updateMedic: Medic;
  updateOperator: Operator;
  updateOrganizationSa: OrganizationSa;
  updatePatient: Patient;
  updatePatientProfessional: PatientProfessional;
  updatePatientProfessionalStatus: PatientProfessional;
  updateProfessionalProvision: ProfessionalProvision;
  updateReportAsProfessional: Report;
  uploadLogo: Attachment;
  uploadPrivacyPolicy: Attachment;
};


export type MutationAcceptAssistantInviteArgs = {
  assistantTermsAndConditions: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationAcceptDelegationsArgs = {
  acceptedAt?: Maybe<Scalars['Date']>;
  patientProfessionalId: Scalars['String'];
};


export type MutationAcceptOrganizationInviteArgs = {
  id: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationAcceptPatientProfessionalTransferArgs = {
  id: Scalars['String'];
};


export type MutationAcceptPersonalMedicTransferArgs = {
  id: Scalars['String'];
};


export type MutationAcceptProfessionalShareArgs = {
  id: Scalars['String'];
};


export type MutationAcceptShareArgs = {
  medicId: Scalars['String'];
  reportId: Scalars['String'];
};


export type MutationAddChatUserArgs = {
  userId: Scalars['ID'];
};


export type MutationAddSpecializationToMedicArgs = {
  id: Scalars['String'];
};


export type MutationAnswerRequestAsProfessionalArgs = {
  message: CreateRequestMessageInput;
  medicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationArchivePatientProfessionalArgs = {
  archive?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationAssignMedicToEntryArgs = {
  medicId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationAssignPersonalMedicArgs = {
  medicId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
};


export type MutationCopyAgendaListArgs = {
  agendaList: CopyAgendaListInput;
  id: Scalars['String'];
};


export type MutationCreateAgendaEntryArgs = {
  agendaEntry: CreateAgendaEntryInput;
  weekId: Scalars['String'];
};


export type MutationCreateAgendaListArgs = {
  numberOfWeeks?: Maybe<Scalars['Int']>;
  agendaList: CreateAgendaListInput;
  medicId?: Maybe<Scalars['String']>;
};


export type MutationCreateAgendaWeekArgs = {
  order: Scalars['Float'];
  agendaId: Scalars['String'];
};


export type MutationCreateAppointmentArgs = {
  appointment: CreateAppointmentInput;
  medicId: Scalars['String'];
};


export type MutationCreateAppointmentAsPatientArgs = {
  appointment: CreateAppointmentInput;
  patientId: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationCreateBreakArgs = {
  break: CreateBreakInput;
  medicId: Scalars['String'];
};


export type MutationCreateChatArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateClinicArgs = {
  clinic: CreateClinicInput;
  medicId: Scalars['String'];
};


export type MutationCreateClinicRoomArgs = {
  room: CreateClinicRoomInput;
  clinicId: Scalars['String'];
};


export type MutationCreateDelegationArgs = {
  data: CreateDelegationInput;
};


export type MutationCreateManyAgendaEntryArgs = {
  agendaEntries: Array<CreateAgendaEntryInput>;
  weekId: Scalars['String'];
};


export type MutationCreateMedicArgs = {
  medic: CreateMedicInput;
};


export type MutationCreateOperatorArgs = {
  operator: CreateOperatorInput;
};


export type MutationCreateOrUpdateInvoiceArgs = {
  input: InvoiceInput;
};


export type MutationCreateOrUpdateInvoiceOptionsArgs = {
  input: InvoiceOptionsInput;
};


export type MutationCreateOrUpdateInvoiceRowArgs = {
  input?: Maybe<InvoiceRowInput>;
};


export type MutationCreateOrganizationSaArgs = {
  organizationSa: CreateOrganizationSaInput;
  organizationId: Scalars['String'];
};


export type MutationCreateOverrideEntryArgs = {
  date: Scalars['Date'];
  agendaEntry: CreateAgendaEntryInput;
  medicId: Scalars['String'];
};


export type MutationCreatePatientProfessionalArgs = {
  patientProfessional: CreatePatientProfessionalInput;
  patient: CreatePatientInput;
  medicId: Scalars['String'];
};


export type MutationCreatePatientProfessionalAsPatientArgs = {
  personalMedicId?: Maybe<Scalars['String']>;
  professionalId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationCreatePatientProfessionalTransferArgs = {
  targetId: Scalars['String'];
  patientProfessionalId: Scalars['String'];
};


export type MutationCreateProfessionalProvisionArgs = {
  provision: CreateProfessionalProvisionInput;
  medicId: Scalars['String'];
};


export type MutationCreateProfessionalShareArgs = {
  parentId?: Maybe<Scalars['String']>;
  type: ProfessionalSharesType;
  sharedWithId: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationCreateQuickAppointmentArgs = {
  appointment: CreateQuickAppointmentInput;
  medicId: Scalars['String'];
};


export type MutationCreateReportAsPatientArgs = {
  patientProfessionalId?: Maybe<Scalars['String']>;
  report: CreateReportInput;
  patientId: Scalars['String'];
};


export type MutationCreateReportAsProfessionalArgs = {
  patientProfessionalId?: Maybe<Scalars['String']>;
  patient?: Maybe<CreatePatientInput>;
  report: CreateReportInput;
  medicId: Scalars['String'];
};


export type MutationCreateRequestAsPatientArgs = {
  patientId: Scalars['String'];
  request: CreateRequestInput;
};


export type MutationCreateRequestAsProfessionalArgs = {
  medicId: Scalars['String'];
  request: CreateRequestInput;
};


export type MutationCreateUserPatientArgs = {
  patientId?: Maybe<Scalars['String']>;
  patient?: Maybe<CreateUserPatientInput>;
  termsAndConditions: Scalars['Boolean'];
  type?: Maybe<PatientType>;
};


export type MutationDeclineShareArgs = {
  medicId: Scalars['String'];
  reportId: Scalars['String'];
};


export type MutationDeleteAgendaEntryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAgendaListArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAgendaWeekArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAppointmentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAppointmentAsPatientArgs = {
  id: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationDeleteBreakArgs = {
  id: Scalars['String'];
};


export type MutationDeleteClinicArgs = {
  id: Scalars['String'];
};


export type MutationDeleteClinicRoomArgs = {
  id: Scalars['String'];
};


export type MutationDeleteConnectionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDelegationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteInvoiceRowArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOrganizationSaArgs = {
  id: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeletePatientProfessionalTransferArgs = {
  id: Scalars['String'];
};


export type MutationDeletePersonalMedicTransferArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProfessionalProvisionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProfessionalShareArgs = {
  id: Scalars['String'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['String'];
};


export type MutationDeleteReportAsProfessionalArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRequestArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserPatientArgs = {
  id: Scalars['String'];
};


export type MutationDenyPatientProfessionalTransferArgs = {
  id: Scalars['String'];
};


export type MutationDenyPersonalMedicTransferArgs = {
  id: Scalars['String'];
};


export type MutationDenyProfessionalShareArgs = {
  id: Scalars['String'];
};


export type MutationDissociateFromEntryArgs = {
  id: Scalars['String'];
};


export type MutationGrantUserAccessToPatientArgs = {
  newType: PatientType;
  type: PatientType;
  userId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationInviteAssistantArgs = {
  user: InviteUserInput;
  medicId: Scalars['String'];
};


export type MutationInvitePatientArgs = {
  patientProfessionalId: Scalars['String'];
  email: Scalars['String'];
  medicId: Scalars['String'];
};


export type MutationInviteToOrganizationArgs = {
  user: InviteUserInput;
  role: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationMarkManyNotificationsAsReadArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  ids: Array<Scalars['String']>;
};


export type MutationMarkNotificationAsReadArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
};


export type MutationMarkRequestMessageAsReadAsProfessionalArgs = {
  readAt?: Maybe<Scalars['DateTime']>;
  medicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationOverrideDayArgs = {
  date: Scalars['Date'];
  medicId: Scalars['String'];
};


export type MutationRemovePersonalMedicArgs = {
  patientId: Scalars['String'];
};


export type MutationRemoveProfessionalFromOrganizationArgs = {
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationRemoveSpecializationFromMedicArgs = {
  id: Scalars['String'];
};


export type MutationRequestConnectionArgs = {
  requestId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRequestPersonalMedicArgs = {
  groupMedicineId?: Maybe<Scalars['String']>;
  medicId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationResetDayArgs = {
  date: Scalars['Date'];
  medicId: Scalars['String'];
};


export type MutationResolveRequestArgs = {
  result?: Maybe<RequestResult>;
  id: Scalars['String'];
};


export type MutationRevokeReportShareArgs = {
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRevokeSharePatientWithProfessionalArgs = {
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationRevokeUserAccessToPatientArgs = {
  userId: Scalars['String'];
  patientId: Scalars['String'];
};


export type MutationSendMailArgs = {
  attachments?: Maybe<Array<Scalars['Upload']>>;
  body: Scalars['String'];
  title: Scalars['String'];
  to: Array<Scalars['String']>;
  medicId: Scalars['String'];
};


export type MutationSendMassiveEmailArgs = {
  attachments?: Maybe<Array<Scalars['Upload']>>;
  filter?: Maybe<MassiveEmailTargetFilterInput>;
  body: Scalars['String'];
  title: Scalars['String'];
  target: MassiveEmailTarget;
  medicId: Scalars['String'];
};


export type MutationSharePatientWithProfessionalArgs = {
  patientProfessionalId: Scalars['String'];
  medicId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationShareReportArgs = {
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateAgendaEntryArgs = {
  agendaEntry: UpdateAgendaEntryInput;
  id: Scalars['String'];
};


export type MutationUpdateAgendaListArgs = {
  agendaList: UpdateAgendaListInput;
  id: Scalars['String'];
};


export type MutationUpdateAgendaWeekArgs = {
  order: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationUpdateAppointmentArgs = {
  appointment: UpdateAppointmentInput;
  id: Scalars['String'];
};


export type MutationUpdateAssistantArgs = {
  assistant: UpdateAssistantInput;
};


export type MutationUpdateBreakArgs = {
  break: UpdateBreakInput;
  id: Scalars['String'];
};


export type MutationUpdateClinicArgs = {
  clinic: UpdateClinicInput;
  id: Scalars['String'];
};


export type MutationUpdateClinicRoomArgs = {
  room: UpdateClinicRoomInput;
  id: Scalars['String'];
};


export type MutationUpdateConnectionArgs = {
  status: NetworkStatus;
  id: Scalars['String'];
};


export type MutationUpdateDelegationArgs = {
  data: UpdateDelegationInput;
  id: Scalars['String'];
};


export type MutationUpdateHeaderFooterArgs = {
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  medicId: Scalars['String'];
};


export type MutationUpdateMedicArgs = {
  medic: UpdateMedicInput;
};


export type MutationUpdateOperatorArgs = {
  operator: UpdateOperatorInput;
};


export type MutationUpdateOrganizationSaArgs = {
  organizationSa: UpdateOrganizationSaInput;
  id: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationUpdatePatientArgs = {
  patient: UpdatePatientInput;
  patientId: Scalars['String'];
};


export type MutationUpdatePatientProfessionalArgs = {
  patient: UpdatePatientProfessionalInput;
  id: Scalars['String'];
};


export type MutationUpdatePatientProfessionalStatusArgs = {
  patientId?: Maybe<Scalars['String']>;
  medicId?: Maybe<Scalars['String']>;
  status: UpdatablePatientProfessionalStatus;
  id: Scalars['String'];
};


export type MutationUpdateProfessionalProvisionArgs = {
  provision: UpdateProfessionalProvisionInput;
  id: Scalars['String'];
};


export type MutationUpdateReportAsProfessionalArgs = {
  report: UpdateReportInput;
  id: Scalars['String'];
};


export type MutationUploadLogoArgs = {
  link?: Maybe<Scalars['String']>;
  file?: Maybe<CreateAttachmentInput>;
  medicId: Scalars['String'];
};


export type MutationUploadPrivacyPolicyArgs = {
  link?: Maybe<Scalars['String']>;
  file?: Maybe<CreateAttachmentInput>;
  medicId: Scalars['String'];
};

export type Network = BaseEntity & {
  __typename?: 'Network';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: NetworkStatus;
  canImpersonate: Scalars['Int'];
  ownerId: Scalars['String'];
  inverseId: Scalars['String'];
  inverse: Professional;
};

export enum NetworkStatus {
  Requested = 'REQUESTED',
  Approved = 'APPROVED',
  Received = 'RECEIVED',
  Blacklisted = 'BLACKLISTED'
}

export type Notification = BaseEntity & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  destinationProfile?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  readAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
};

export type NotificationFilterInput = {
  _or?: Maybe<Array<NotificationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  destinationProfile?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  readAt?: Maybe<DateFieldFilterInput>;
};

export type NotificationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  userId?: Maybe<Order>;
  destinationProfile?: Maybe<Order>;
  name?: Maybe<Order>;
  readAt?: Maybe<Order>;
};

export type NotificationPaginatedResponse = {
  __typename?: 'NotificationPaginatedResponse';
  items: Array<Notification>;
  paginationInfo: PaginationInfo;
};

export type NumberFieldFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  ne?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Operator = Professional & BaseEntity & {
  __typename?: 'Operator';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  professionalsSharedWithMe: Array<ProfessionalShares>;
  professionalsSharedByMe: Array<ProfessionalShares>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  logo?: Maybe<Attachment>;
  profession?: Maybe<OperatorProfession>;
  contacts?: Maybe<EmbedContact>;
  termsAndConditions: Scalars['Boolean'];
  availability: EmbedAvailability;
  /** @deprecated Use availability.chat */
  chatEnabled: Scalars['Boolean'];
  /** @deprecated Use availability.requests */
  requestsEnabled: Scalars['Boolean'];
};

export enum OperatorProfession {
  Nurse = 'NURSE',
  Psychologist = 'PSYCHOLOGIST',
  Physiotherapist = 'PHYSIOTHERAPIST',
  Dietician = 'DIETICIAN',
  Podiatrist = 'PODIATRIST',
  Pharmacist = 'PHARMACIST'
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organization = BaseEntity & {
  __typename?: 'Organization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  serviceAccounts: Array<OrganizationSa>;
  professionals: Array<OrganizationProfessional>;
};

export type OrganizationFilterInput = {
  _or?: Maybe<Array<OrganizationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  serviceAccounts?: Maybe<OrganizationSaFilterInput>;
};

export type OrganizationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export type OrganizationProfessional = BaseEntity & {
  __typename?: 'OrganizationProfessional';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId: Scalars['String'];
  organizationId: Scalars['String'];
  role: OrganizationRole;
  organization: Organization;
  professional: Professional;
};

export type OrganizationProfessionalFilterInput = {
  _or?: Maybe<Array<OrganizationProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
};

export enum OrganizationRole {
  Admin = 'ADMIN',
  Medic = 'MEDIC'
}

export type OrganizationSa = Professional & BaseEntity & {
  __typename?: 'OrganizationSa';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  professionalsSharedWithMe: Array<ProfessionalShares>;
  professionalsSharedByMe: Array<ProfessionalShares>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  logo?: Maybe<Attachment>;
  contacts?: Maybe<EmbedContact>;
  isGroupMedicine?: Maybe<Scalars['Boolean']>;
  managedOrganization: Organization;
  managedOrganizationId: Scalars['String'];
};

export type OrganizationSaFilterInput = {
  _or?: Maybe<Array<OrganizationSaFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  role?: Maybe<ProfessionalRoleFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  organizations?: Maybe<OrganizationProfessionalFilterInput>;
  paymentMethods?: Maybe<PaymentMethodFilterInput>;
  licenses?: Maybe<LicenseFilterInput>;
  specializations?: Maybe<SpecializationFilterInput>;
  provisions?: Maybe<ProfessionalProvisionFilterInput>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  hasMore: Scalars['Boolean'];
  total: Scalars['Int'];
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type Patient = BaseEntity & {
  __typename?: 'Patient';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  surname: Scalars['String'];
  taxId: Scalars['TaxId'];
  birthDay: Scalars['DateTime'];
  createdBy: Scalars['String'];
  fullName: Scalars['String'];
  personalMedicId?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  personalMedicTransfers: Array<PersonalMedicTransfer>;
  personalMedic?: Maybe<Medic>;
  patientProfessionals: Array<PatientProfessional>;
  users: Array<PatientUser>;
  appointments: Array<AvailsAppointment>;
};


export type PatientAppointmentsArgs = {
  to?: Maybe<Scalars['Date']>;
  from?: Maybe<Scalars['Date']>;
};

export type PatientFilterInput = {
  _or?: Maybe<Array<PatientFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  taxId?: Maybe<TaxIdScalarFieldFilterInput>;
  birthDay?: Maybe<DateFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
};

export type PatientImportLog = BaseEntity & {
  __typename?: 'PatientImportLog';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  importDate: Scalars['DateTime'];
  filename: Scalars['String'];
  fileError?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  type: Scalars['String'];
  rows: Scalars['Int'];
  written: Scalars['Int'];
  skipped: Scalars['Int'];
  discarded: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
  professionalId: Scalars['String'];
  errors: Array<PatientImportLogError>;
  professional: Professional;
};

export type PatientImportLogError = BaseEntity & {
  __typename?: 'PatientImportLogError';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  row: Scalars['Float'];
  reason: Scalars['String'];
};

export type PatientImportLogErrorFilterInput = {
  _or?: Maybe<Array<PatientImportLogErrorFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  row?: Maybe<NumberFieldFilterInput>;
  reason?: Maybe<StringFieldFilterInput>;
};

export type PatientImportLogFilterInput = {
  _or?: Maybe<Array<PatientImportLogFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  importDate?: Maybe<DateFieldFilterInput>;
  filename?: Maybe<StringFieldFilterInput>;
  fileError?: Maybe<StringFieldFilterInput>;
  source?: Maybe<StringFieldFilterInput>;
  type?: Maybe<StringFieldFilterInput>;
  rows?: Maybe<IntFieldFilterInput>;
  written?: Maybe<IntFieldFilterInput>;
  skipped?: Maybe<IntFieldFilterInput>;
  discarded?: Maybe<IntFieldFilterInput>;
  errors?: Maybe<PatientImportLogErrorFilterInput>;
  professional?: Maybe<ProfessionalFilterInput>;
};

export type PatientImportLogOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  importDate?: Maybe<Order>;
  filename?: Maybe<Order>;
  fileError?: Maybe<Order>;
  source?: Maybe<Order>;
  type?: Maybe<Order>;
  rows?: Maybe<Order>;
  written?: Maybe<Order>;
  skipped?: Maybe<Order>;
  discarded?: Maybe<Order>;
};

export type PatientOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
  surname?: Maybe<Order>;
  taxId?: Maybe<Order>;
  birthDay?: Maybe<Order>;
  createdBy?: Maybe<Order>;
};

export type PatientPaginatedResponse = {
  __typename?: 'PatientPaginatedResponse';
  items: Array<Patient>;
  paginationInfo: PaginationInfo;
};

export type PatientProfessional = BaseEntity & {
  __typename?: 'PatientProfessional';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  status: PatientProfessionalStatus;
  createdBy: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CryptedEmbedAddress>;
  professionalId?: Maybe<Scalars['String']>;
  organizationProfessionalId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  delegationsAcceptedAt?: Maybe<Scalars['DateTime']>;
  fullName: Scalars['String'];
  patient: Patient;
  upcomingAppointments: Array<AvailsAppointment>;
  /** @deprecated Use sharedReports */
  reports: Array<Report>;
  professional?: Maybe<Professional>;
  sharedReports: Array<ReportSharedWith>;
  delegations: Array<Delegation>;
  organizationProfessional?: Maybe<OrganizationProfessional>;
  professionalTransfers: Array<PatientProfessionalTransfer>;
};


export type PatientProfessionalUpcomingAppointmentsArgs = {
  daysLimit?: Maybe<Scalars['Int']>;
};

export type PatientProfessionalFilterInput = {
  _or?: Maybe<Array<PatientProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  status?: Maybe<PatientProfessionalStatusFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  email?: Maybe<StringFieldFilterInput>;
  phone?: Maybe<StringFieldFilterInput>;
  delegationsAcceptedAt?: Maybe<DateFieldFilterInput>;
};

export type PatientProfessionalOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  status?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  email?: Maybe<Order>;
  phone?: Maybe<Order>;
  delegationsAcceptedAt?: Maybe<Order>;
};

export type PatientProfessionalPaginatedResponse = {
  __typename?: 'PatientProfessionalPaginatedResponse';
  items: Array<PatientProfessional>;
  paginationInfo: PaginationInfo;
};

export enum PatientProfessionalStatus {
  RequestedByMedic = 'REQUESTED_BY_MEDIC',
  RequestedByPatient = 'REQUESTED_BY_PATIENT',
  Inactive = 'INACTIVE',
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Archived = 'ARCHIVED',
  Hidden = 'HIDDEN'
}

export type PatientProfessionalStatusFieldFilterInput = {
  eq?: Maybe<PatientProfessionalStatus>;
  ne?: Maybe<PatientProfessionalStatus>;
  in?: Maybe<Array<PatientProfessionalStatus>>;
  nin?: Maybe<Array<PatientProfessionalStatus>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type PatientProfessionalTransfer = BaseEntity & {
  __typename?: 'PatientProfessionalTransfer';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  acceptedAt?: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  targetId: Scalars['String'];
  patientProfessionalId: Scalars['String'];
  target: Professional;
  patientProfessional: PatientProfessional;
  creator?: Maybe<User>;
};

export enum PatientType {
  Owner = 'OWNER',
  FamilyMember = 'FAMILY_MEMBER',
  Tutor = 'TUTOR',
  Parent = 'PARENT'
}

export type PatientTypeFieldFilterInput = {
  eq?: Maybe<PatientType>;
  ne?: Maybe<PatientType>;
  in?: Maybe<Array<PatientType>>;
  nin?: Maybe<Array<PatientType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type PatientUser = BaseEntity & {
  __typename?: 'PatientUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  type: PatientType;
  termsAndConditions: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  patientId: Scalars['String'];
  patient: Patient;
  user: User;
};

export type PatientUserFilterInput = {
  _or?: Maybe<Array<PatientUserFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  type?: Maybe<PatientTypeFieldFilterInput>;
  patient?: Maybe<PatientFilterInput>;
};

export type PatientUserOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  userId?: Maybe<Order>;
  type?: Maybe<Order>;
};

export type PaymentMethod = BaseEntity & {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: PaymentType;
  data: Scalars['JSON'];
};

export type PaymentMethodFilterInput = {
  _or?: Maybe<Array<PaymentMethodFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  type?: Maybe<PaymentTypeFieldFilterInput>;
  data?: Maybe<JsonFieldFilterInput>;
};

export enum PaymentType {
  Stripe = 'STRIPE',
  Group = 'GROUP',
  Manual = 'MANUAL'
}

export type PaymentTypeFieldFilterInput = {
  eq?: Maybe<PaymentType>;
  ne?: Maybe<PaymentType>;
  in?: Maybe<Array<PaymentType>>;
  nin?: Maybe<Array<PaymentType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type PersonalMedicTransfer = BaseEntity & {
  __typename?: 'PersonalMedicTransfer';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  acceptedAt?: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  targetId: Scalars['String'];
  patientId: Scalars['String'];
  groupMedicineId?: Maybe<Scalars['String']>;
  target: Professional;
  patient: Patient;
  creator?: Maybe<User>;
  patientProfessional?: Maybe<PatientProfessional>;
};

export type PlatformAlert = BaseEntity & {
  __typename?: 'PlatformAlert';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  severity: PlatformAlertSeverity;
  target: PlatformAlertTarget;
  roleCode: Scalars['Float'];
  message: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  to: Scalars['Date'];
  roles?: Maybe<Array<ProfessionalRole>>;
};

export type PlatformAlertFilterInput = {
  _or?: Maybe<Array<PlatformAlertFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  severity?: Maybe<PlatformAlertSeverityFieldFilterInput>;
  target?: Maybe<PlatformAlertTargetFieldFilterInput>;
  message?: Maybe<StringFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  from?: Maybe<DateFieldFilterInput>;
  to?: Maybe<DateFieldFilterInput>;
};

export type PlatformAlertFilterInputWithRole = {
  _or?: Maybe<Array<PlatformAlertFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  severity?: Maybe<PlatformAlertSeverityFieldFilterInput>;
  target?: Maybe<PlatformAlertTargetFieldFilterInput>;
  message?: Maybe<StringFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  from?: Maybe<DateFieldFilterInput>;
  to?: Maybe<DateFieldFilterInput>;
  role?: Maybe<ProfessionalRoleFilterInput>;
};

export type PlatformAlertOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  severity?: Maybe<Order>;
  target?: Maybe<Order>;
  message?: Maybe<Order>;
  title?: Maybe<Order>;
  from?: Maybe<Order>;
  to?: Maybe<Order>;
};

export enum PlatformAlertSeverity {
  Success = 'SUCCESS',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export type PlatformAlertSeverityFieldFilterInput = {
  eq?: Maybe<PlatformAlertSeverity>;
  ne?: Maybe<PlatformAlertSeverity>;
  in?: Maybe<Array<PlatformAlertSeverity>>;
  nin?: Maybe<Array<PlatformAlertSeverity>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export enum PlatformAlertTarget {
  Medic = 'MEDIC',
  Patient = 'PATIENT'
}

export type PlatformAlertTargetFieldFilterInput = {
  eq?: Maybe<PlatformAlertTarget>;
  ne?: Maybe<PlatformAlertTarget>;
  in?: Maybe<Array<PlatformAlertTarget>>;
  nin?: Maybe<Array<PlatformAlertTarget>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type Point = {
  __typename?: 'Point';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Professional = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  role: ProfessionalRole;
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  address?: Maybe<EmbedAddress>;
  hasActiveLicense: Scalars['Boolean'];
  fullName: Scalars['String'];
  organizations: Array<OrganizationProfessional>;
  paymentMethods: Array<PaymentMethod>;
  licenses: Array<License>;
  specializations: Array<Specialization>;
  provisions: Array<ProfessionalProvision>;
  clinics: Array<Clinic>;
  professionalsSharedWithMe: Array<ProfessionalShares>;
  professionalsSharedByMe: Array<ProfessionalShares>;
  networks: Array<Network>;
  user?: Maybe<User>;
  activeLicense?: Maybe<License>;
  privacyPolicy?: Maybe<Attachment>;
  logo?: Maybe<Attachment>;
};

export type ProfessionalFilterInput = {
  _or?: Maybe<Array<ProfessionalFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  role?: Maybe<ProfessionalRoleFieldFilterInput>;
  userId?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  surname?: Maybe<StringFieldFilterInput>;
  organizations?: Maybe<OrganizationProfessionalFilterInput>;
  paymentMethods?: Maybe<PaymentMethodFilterInput>;
  licenses?: Maybe<LicenseFilterInput>;
  specializations?: Maybe<SpecializationFilterInput>;
  provisions?: Maybe<ProfessionalProvisionFilterInput>;
};

export type ProfessionalOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  role?: Maybe<Order>;
  userId?: Maybe<Order>;
  name?: Maybe<Order>;
  surname?: Maybe<Order>;
};

export type ProfessionalPaginatedResponse = {
  __typename?: 'ProfessionalPaginatedResponse';
  items: Array<Professional>;
  paginationInfo: PaginationInfo;
};

export type ProfessionalProvision = BaseEntity & {
  __typename?: 'ProfessionalProvision';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId: Scalars['String'];
  provisionId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  duration: Scalars['Int'];
  mandatory: Scalars['Boolean'];
  provision?: Maybe<Provision>;
};

export type ProfessionalProvisionFilterInput = {
  _or?: Maybe<Array<ProfessionalProvisionFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  professionalId?: Maybe<StringFieldFilterInput>;
  provisionId?: Maybe<StringFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
  price?: Maybe<FloatFieldFilterInput>;
  duration?: Maybe<IntFieldFilterInput>;
  mandatory?: Maybe<BooleanFieldFilterInput>;
  provision?: Maybe<ProvisionFilterInput>;
};

export enum ProfessionalRole {
  Medic = 'Medic',
  Assistant = 'Assistant',
  Operator = 'Operator',
  OrganizationSa = 'OrganizationSa',
  Administrator = 'Administrator'
}

export type ProfessionalRoleFieldFilterInput = {
  eq?: Maybe<ProfessionalRole>;
  ne?: Maybe<ProfessionalRole>;
  in?: Maybe<Array<ProfessionalRole>>;
  nin?: Maybe<Array<ProfessionalRole>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type ProfessionalRoleFilterInput = {
  contains?: Maybe<Array<ProfessionalRole>>;
};

export type ProfessionalShares = BaseEntity & {
  __typename?: 'ProfessionalShares';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  type: ProfessionalSharesType;
  professionalId: Scalars['String'];
  sharedWithId: Scalars['String'];
  acceptedAt?: Maybe<Scalars['DateTime']>;
  parentId?: Maybe<Scalars['String']>;
  professional: Professional;
  sharedWith: Professional;
  parent?: Maybe<ProfessionalShares>;
  creator?: Maybe<User>;
  managedOrganization?: Maybe<Organization>;
};

export enum ProfessionalSharesType {
  GroupMedicine = 'GROUP_MEDICINE',
  Substitute = 'SUBSTITUTE'
}

export type Provision = BaseEntity & {
  __typename?: 'Provision';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  defaultPrice: Scalars['Float'];
  defaultDuration: Scalars['Int'];
  /** @deprecated Unused */
  code: Scalars['String'];
  mandatory: Scalars['Boolean'];
};

export type ProvisionFilterInput = {
  _or?: Maybe<Array<ProvisionFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
};

export type ProvisionOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export type ProvisionPaginatedResponse = {
  __typename?: 'ProvisionPaginatedResponse';
  items: Array<Provision>;
  paginationInfo: PaginationInfo;
};

export type PublicPatient = {
  __typename?: 'PublicPatient';
  taxId: Scalars['TaxId'];
};

export type Query = {
  __typename?: 'Query';
  administratorProfile?: Maybe<Administrator>;
  agendaEntriesInDays: Array<AgendaEntryInDay>;
  agendaEntryByDay: Array<AgendaEntryInDay>;
  // agendaEvent: AgendaEvent;
  // agendaEventsByDay: Array<AgendaEvent>;
  // agendaEventsInDays: Array<AgendaEvent>;
  // agendaList: AgendaList;
  // agendaLists: Array<AgendaList>;
  appointment: AvailsAppointment;
  assistantProfile?: Maybe<Assistant>;
  cities: CityPaginatedResponse;
  city: City;
  clinic: Clinic;
  clinicRoomByProvision: Array<ClinicRoomFromDay>;
  clinicRoomByProvisionManyProfessionals: Array<ClinicRoomFromDay>;
  clinics: Array<Clinic>;
  cupProviders: Array<CupProvider>;
  defaultProvisions: Array<Provision>;
  delegation: Delegation;
  frequentProvisions: Array<FrequentProvision>;
  getInvoice?: Maybe<Invoice>;
  /** Restituisce primo numero fattura disponibile */
  getInvoiceNextNumber?: Maybe<Scalars['Int']>;
  /** Get user level options for invoice system */
  getInvoiceOptions?: Maybe<InvoiceOptions>;
  /** Return a list of valid payment types */
  getInvoicePaymentTypes: Array<InvoicePaymentType>;
  /** Return a list of valid vat regimes */
  getInvoiceVatCodes: Array<InvoiceVatCode>;
  /** Get all invoices for a professional, optionally filtered by year */
  getInvoices?: Maybe<InvoicePaginator>;
  /** Get all invoices issued to a patient id optionally filtered by year */
  getInvoicesByPatient?: Maybe<InvoicePaginator>;
  /** Get all invoices issued to a tax_id optionally filtered by year */
  getInvoicesByRecipientTaxId?: Maybe<InvoicePaginator>;
  impersonateMedic: Medic;
  invite: Invite;
  licenseType: LicenseType;
  licenseTypes: Array<LicenseType>;
  managedOrganizations: Array<OrganizationProfessional>;
  massiveEmailTargetedPatients: Array<PatientProfessional>;
  medicProfile?: Maybe<Medic>;
  network: Network;
  notification: Notification;
  notifications: NotificationPaginatedResponse;
  operatorProfile?: Maybe<Operator>;
  organization: Organization;
  organizationPatientProfessionalSharedWith: Array<Professional>;
  organizations: Array<Organization>;
  pastAppointmentsByPatient: AppointmentsPaginatedResponse;
  patient: Patient;
  patientImportLog: PatientImportLog;
  patientImportLogs: Array<PatientImportLog>;
  patientProfessional: PatientProfessional;
  patientProfessionalBetween?: Maybe<PatientProfessional>;
  patientProfessionalDelegations: Array<Delegation>;
  patientProfessionalPastAppointments: AppointmentsPaginatedResponse;
  patientProfessionalTransfer: PatientProfessionalTransfer;
  patientProfessionals: PatientProfessionalPaginatedResponse;
  patientProfessionalsAsPatient: Array<SharedPatientProfessionalUnion>;
  patientProfiles?: Maybe<Array<PatientUser>>;
  patientReports: ReportsPaginatedResponse;
  patientToAssociate?: Maybe<Patient>;
  patientUser: PatientUser;
  patientUsers: Array<PatientUser>;
  patients: PatientPaginatedResponse;
  pendingInvites: Array<Invite>;
  personalMedicTransfer: PersonalMedicTransfer;
  personalMedicTransfersForMedic: Array<PersonalMedicTransfer>;
  platformAlert: PlatformAlert;
  platformAlerts: Array<PlatformAlert>;
  professional: Professional;
  professionalNetworks: Array<Network>;
  professionalSharedReport: ReportSharedWith;
  professionalSharedReports: SharedReportsPaginatedResponse;
  professionalShares: ProfessionalShares;
  professionals: ProfessionalPaginatedResponse;
  professionalsByEmail: Array<Professional>;
  provision: Provision;
  provisions: ProvisionPaginatedResponse;
  receivedRequestsAsProfessional: RequestPaginatedResponse;
  report: Report;
  request: Request;
  searchAppointments: AppointmentsPaginatedResponse;
  searchPatientByTaxId?: Maybe<SearchPatientUnion>;
  searchPatientProfessionals: PatientProfessionalPaginatedResponse;
  searchPatientProfessionalsManyProfessionals: PatientProfessionalPaginatedResponse;
  sentInvites: Array<Invite>;
  sentRequestsAsPatient: RequestPaginatedResponse;
  sentRequestsAsProfessional: RequestPaginatedResponse;
  specialization: Specialization;
  specializations: Array<Specialization>;
  timeslots: Array<TimeSlot>;
  timeslotsAsPatient: Array<TimeSlot>;
  userProfiles: Array<Professional>;
  whoami: User;
};


export type QueryAgendaEntriesInDaysArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEntryByDayArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  day: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEventArgs = {
  id: Scalars['ID'];
};


export type QueryAgendaEventsByDayArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  day: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaEventsInDaysArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  medicId: Scalars['String'];
};


export type QueryAgendaListArgs = {
  id: Scalars['ID'];
};


export type QueryAgendaListsArgs = {
  filter?: Maybe<MedicAgendaFilterInput>;
  order?: Maybe<MedicAgendaOrderInput>;
  medicId: Scalars['String'];
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryCitiesArgs = {
  filter?: Maybe<CityFilterInput>;
  order?: Maybe<CityOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCityArgs = {
  id: Scalars['ID'];
};


export type QueryClinicArgs = {
  id: Scalars['ID'];
};


export type QueryClinicRoomByProvisionArgs = {
  distance?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<LatLngInput>;
  medicId?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
};


export type QueryClinicRoomByProvisionManyProfessionalsArgs = {
  distance?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<LatLngInput>;
  medicIds?: Maybe<Array<Scalars['String']>>;
  medicId?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
};


export type QueryClinicsArgs = {
  filter?: Maybe<FindClinicFilterInput>;
  order?: Maybe<ClinicOrderInput>;
};


export type QueryCupProvidersArgs = {
  coordinates: LatLngInput;
  isCup?: Maybe<Scalars['Boolean']>;
  provisionId: Scalars['String'];
};


export type QueryDelegationArgs = {
  id: Scalars['ID'];
};


export type QueryGetInvoiceArgs = {
  invoiceId: Scalars['Int'];
};


export type QueryGetInvoiceNextNumberArgs = {
  professionalId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryGetInvoiceOptionsArgs = {
  professionalId?: Maybe<Scalars['Int']>;
};


export type QueryGetInvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  professionalId: Scalars['Int'];
  sortBy?: Maybe<Array<GetInvoicesSortByOrderByClause>>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetInvoicesByPatientArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  patient_id: Scalars['Int'];
  professional_id?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Array<GetInvoicesByPatientSortByOrderByClause>>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryGetInvoicesByRecipientTaxIdArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  recipient_tax_id: Scalars['String'];
  sortBy?: Maybe<Array<GetInvoicesByRecipientTaxIdSortByOrderByClause>>;
  year?: Maybe<Scalars['Int']>;
};


export type QueryImpersonateMedicArgs = {
  id: Scalars['String'];
};


export type QueryInviteArgs = {
  id: Scalars['ID'];
};


export type QueryLicenseTypeArgs = {
  code: Scalars['String'];
};


export type QueryMassiveEmailTargetedPatientsArgs = {
  filter: MassiveEmailTargetFilterInput;
  medicId: Scalars['String'];
};


export type QueryNetworkArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  filter?: Maybe<NotificationFilterInput>;
  order?: Maybe<NotificationOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationPatientProfessionalSharedWithArgs = {
  id: Scalars['String'];
  medicId: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  filter?: Maybe<OrganizationFilterInput>;
  order?: Maybe<OrganizationOrderInput>;
};


export type QueryPastAppointmentsByPatientArgs = {
  order?: Maybe<AvailsAppointmentOrderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryPatientImportLogArgs = {
  id: Scalars['ID'];
};


export type QueryPatientImportLogsArgs = {
  filter?: Maybe<PatientImportLogFilterInput>;
  order?: Maybe<PatientImportLogOrderInput>;
  medicId: Scalars['String'];
};


export type QueryPatientProfessionalArgs = {
  id: Scalars['ID'];
};


export type QueryPatientProfessionalBetweenArgs = {
  groupMedicineId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  medicId: Scalars['String'];
};


export type QueryPatientProfessionalDelegationsArgs = {
  id: Scalars['String'];
};


export type QueryPatientProfessionalPastAppointmentsArgs = {
  order?: Maybe<AvailsAppointmentOrderInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
};


export type QueryPatientProfessionalTransferArgs = {
  id: Scalars['ID'];
};


export type QueryPatientProfessionalsArgs = {
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QueryPatientProfessionalsAsPatientArgs = {
  patientId: Scalars['String'];
};


export type QueryPatientReportsArgs = {
  filter?: Maybe<ReportFilterInput>;
  order?: Maybe<ReportOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
};


export type QueryPatientUserArgs = {
  id: Scalars['ID'];
};


export type QueryPatientUsersArgs = {
  filter?: Maybe<PatientUserFilterInput>;
  order?: Maybe<PatientUserOrderInput>;
};


export type QueryPatientsArgs = {
  filter?: Maybe<PatientFilterInput>;
  order?: Maybe<PatientOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPendingInvitesArgs = {
  types?: Maybe<Array<InviteType>>;
};


export type QueryPersonalMedicTransferArgs = {
  id: Scalars['ID'];
};


export type QueryPersonalMedicTransfersForMedicArgs = {
  patientId?: Maybe<Scalars['String']>;
  medicId: Scalars['String'];
};


export type QueryPlatformAlertArgs = {
  id: Scalars['ID'];
};


export type QueryPlatformAlertsArgs = {
  filter?: Maybe<PlatformAlertFilterInputWithRole>;
  order?: Maybe<PlatformAlertOrderInput>;
};


export type QueryProfessionalArgs = {
  id: Scalars['ID'];
};


export type QueryProfessionalNetworksArgs = {
  medicId: Scalars['String'];
};


export type QueryProfessionalSharedReportArgs = {
  id: Scalars['String'];
  medicId: Scalars['String'];
};


export type QueryProfessionalSharedReportsArgs = {
  filter?: Maybe<ReportSharedWithFilterInput>;
  order?: Maybe<ReportSharedWithOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
  patientSearch?: Maybe<Scalars['String']>;
  patientFilter?: Maybe<PatientFilterInput>;
};


export type QueryProfessionalSharesArgs = {
  id: Scalars['ID'];
};


export type QueryProfessionalsArgs = {
  filter?: Maybe<ProfessionalFilterInput>;
  order?: Maybe<ProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryProfessionalsByEmailArgs = {
  email: Scalars['String'];
};


export type QueryProvisionArgs = {
  id: Scalars['ID'];
};


export type QueryProvisionsArgs = {
  filter?: Maybe<ProvisionFilterInput>;
  order?: Maybe<ProvisionOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryReceivedRequestsAsProfessionalArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryRequestArgs = {
  id: Scalars['ID'];
};


export type QuerySearchAppointmentsArgs = {
  filter?: Maybe<AvailsAppointmentFilterInput>;
  order?: Maybe<AvailsAppointmentOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
  patientSearch?: Maybe<Scalars['String']>;
  patientFilter?: Maybe<PatientFilterInput>;
};


export type QuerySearchPatientByTaxIdArgs = {
  taxId: Scalars['TaxId'];
};


export type QuerySearchPatientProfessionalsArgs = {
  medicId: Scalars['String'];
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientSearch?: Maybe<Scalars['String']>;
  patientFilter?: Maybe<PatientFilterInput>;
};


export type QuerySearchPatientProfessionalsManyProfessionalsArgs = {
  medicId: Scalars['String'];
  filter?: Maybe<PatientProfessionalFilterInput>;
  order?: Maybe<PatientProfessionalOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientSearch?: Maybe<Scalars['String']>;
  medicIds: Array<Scalars['String']>;
  patientFilter?: Maybe<PatientFilterInput>;
};


export type QuerySentInvitesArgs = {
  types?: Maybe<Array<InviteType>>;
  medicId: Scalars['String'];
};


export type QuerySentRequestsAsPatientArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  patientId: Scalars['String'];
};


export type QuerySentRequestsAsProfessionalArgs = {
  filter?: Maybe<RequestFilterInput>;
  order?: Maybe<RequestOrderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  medicId: Scalars['String'];
};


export type QuerySpecializationArgs = {
  id: Scalars['ID'];
};


export type QuerySpecializationsArgs = {
  filter?: Maybe<SpecializationFilterInput>;
  order?: Maybe<SpecializationOrderInput>;
};


export type QueryTimeslotsArgs = {
  compact?: Maybe<Scalars['Boolean']>;
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  provisionId: Scalars['String'];
};


export type QueryTimeslotsAsPatientArgs = {
  clinicIds?: Maybe<Array<Scalars['String']>>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Report = BaseEntity & {
  __typename?: 'Report';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  professionalId?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  medicName: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  patientDeletedAt?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<Scalars['JSON']>;
  reportDate: Scalars['DateTime'];
  shares: Array<ReportSharedWith>;
  /** @deprecated Use shares */
  sharedWith: Array<PatientProfessional>;
  creator?: Maybe<User>;
  patient: Patient;
  attachments?: Maybe<Array<Attachment>>;
  professional?: Maybe<Professional>;
};

export type ReportFilterInput = {
  _or?: Maybe<Array<ReportFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  medicName?: Maybe<StringFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  description?: Maybe<StringFieldFilterInput>;
  notes?: Maybe<StringFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  patientDeletedAt?: Maybe<DateFieldFilterInput>;
  reportDate?: Maybe<DateFieldFilterInput>;
};

export type ReportOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  medicName?: Maybe<Order>;
  title?: Maybe<Order>;
  description?: Maybe<Order>;
  notes?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  patientDeletedAt?: Maybe<Order>;
  reportDate?: Maybe<Order>;
};

export type ReportSharedWith = BaseEntity & {
  __typename?: 'ReportSharedWith';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  accepted: Scalars['Boolean'];
  patientProfessionalId: Scalars['String'];
  reportId: Scalars['String'];
  report: Report;
  patientProfessional: PatientProfessional;
};

export type ReportSharedWithFilterInput = {
  _or?: Maybe<Array<ReportSharedWithFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  accepted?: Maybe<BooleanFieldFilterInput>;
  patientProfessional?: Maybe<PatientProfessionalFilterInput>;
  report?: Maybe<ReportFilterInput>;
};

export type ReportSharedWithOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  accepted?: Maybe<Order>;
};

export type ReportsPaginatedResponse = {
  __typename?: 'ReportsPaginatedResponse';
  items: Array<Report>;
  paginationInfo: PaginationInfo;
};

export type Request = BaseEntity & {
  __typename?: 'Request';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  result?: Maybe<RequestResult>;
  createdBy: Scalars['String'];
  private: Scalars['Boolean'];
  type: RequestType;
  senderPatientId?: Maybe<Scalars['String']>;
  recipientPatientId?: Maybe<Scalars['String']>;
  messages: Array<RequestMessage>;
  creator?: Maybe<User>;
  recipient: RequestAuthor;
  sender: RequestAuthor;
  recipientPatient?: Maybe<Patient>;
  senderPatient?: Maybe<Patient>;
};

export type RequestAuthor = Medic | Operator | Patient | OrganizationSa;

export type RequestFilterInput = {
  _or?: Maybe<Array<RequestFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  title?: Maybe<StringFieldFilterInput>;
  result?: Maybe<RequestResultFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
  type?: Maybe<RequestTypeFieldFilterInput>;
  messages?: Maybe<RequestMessageFilterInput>;
};

export type RequestMessage = BaseEntity & {
  __typename?: 'RequestMessage';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  patientId?: Maybe<Scalars['String']>;
  author?: Maybe<RequestAuthor>;
  creator?: Maybe<User>;
  attachments?: Maybe<Array<Attachment>>;
};

export type RequestMessageFilterInput = {
  _or?: Maybe<Array<RequestMessageFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  message?: Maybe<StringFieldFilterInput>;
  readAt?: Maybe<DateFieldFilterInput>;
  createdBy?: Maybe<StringFieldFilterInput>;
};

export type RequestOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  title?: Maybe<Order>;
  result?: Maybe<Order>;
  createdBy?: Maybe<Order>;
  type?: Maybe<Order>;
};

export type RequestPaginatedResponse = {
  __typename?: 'RequestPaginatedResponse';
  items: Array<Request>;
  paginationInfo: PaginationInfo;
};

export enum RequestRecipientType {
  Patient = 'Patient',
  Professional = 'Professional'
}

export enum RequestResult {
  Ok = 'OK',
  Ko = 'KO'
}

export type RequestResultFieldFilterInput = {
  eq?: Maybe<RequestResult>;
  ne?: Maybe<RequestResult>;
  in?: Maybe<Array<RequestResult>>;
  nin?: Maybe<Array<RequestResult>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export enum RequestType {
  HealthAdvice = 'HEALTH_ADVICE',
  DrugsExams = 'DRUGS_EXAMS',
  Informations = 'INFORMATIONS',
  MedicRequest = 'MEDIC_REQUEST',
  AppointmentRequest = 'APPOINTMENT_REQUEST'
}

export type RequestTypeFieldFilterInput = {
  eq?: Maybe<RequestType>;
  ne?: Maybe<RequestType>;
  in?: Maybe<Array<RequestType>>;
  nin?: Maybe<Array<RequestType>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type SearchPatientUnion = PublicPatient | Patient;

export type SharedPatientProfessional = {
  __typename?: 'SharedPatientProfessional';
  id: Scalars['ID'];
  patientId?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CryptedEmbedAddress>;
  professionalId?: Maybe<Scalars['String']>;
  professionalShare: ProfessionalShares;
  parentPatientProfessional: PatientProfessional;
  parentPatientProfessionalId: Scalars['String'];
  professionalShareId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  fullName: Scalars['String'];
  patient: Patient;
  professional?: Maybe<Professional>;
  appointments: Array<AvailsAppointment>;
};


export type SharedPatientProfessionalAppointmentsArgs = {
  to?: Maybe<Scalars['Date']>;
  from?: Maybe<Scalars['Date']>;
};

export type SharedPatientProfessionalUnion = SharedPatientProfessional | PatientProfessional;

export type SharedReportsPaginatedResponse = {
  __typename?: 'SharedReportsPaginatedResponse';
  items: Array<ReportSharedWith>;
  paginationInfo: PaginationInfo;
};

/** Order by clause for invoices list. */
export enum SortInvoices {
  Number = 'NUMBER',
  Year = 'YEAR'
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Specialization = BaseEntity & {
  __typename?: 'Specialization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type SpecializationFilterInput = {
  _or?: Maybe<Array<SpecializationFilterInput>>;
  id?: Maybe<IdFieldFilterInput>;
  createdAt?: Maybe<DateFieldFilterInput>;
  updatedAt?: Maybe<DateFieldFilterInput>;
  name?: Maybe<StringFieldFilterInput>;
};

export type SpecializationOrderInput = {
  id?: Maybe<Order>;
  createdAt?: Maybe<Order>;
  updatedAt?: Maybe<Order>;
  name?: Maybe<Order>;
};

export enum StoreType {
  ScwBucket = 'SCW_BUCKET',
  External = 'EXTERNAL'
}

export type StringFieldFilterInput = {
  eq?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  nin?: Maybe<Array<Scalars['String']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  id: Scalars['String'];
  productId: Scalars['String'];
  amount: Scalars['Float'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  code: Scalars['String'];
  id: Scalars['String'];
  monthlyId: Scalars['String'];
  yearlyId: Scalars['String'];
  monthly: StripePrice;
  yearly: StripePrice;
};

export type Subscription = {
  __typename?: 'Subscription';
  onNewNotification: Notification;
};


export type TaxIdScalarFieldFilterInput = {
  eq?: Maybe<Scalars['TaxId']>;
  ne?: Maybe<Scalars['TaxId']>;
  in?: Maybe<Array<Scalars['TaxId']>>;
  nin?: Maybe<Array<Scalars['TaxId']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};


export type TimeScalarFieldFilterInput = {
  eq?: Maybe<Scalars['Time']>;
  ne?: Maybe<Scalars['Time']>;
  in?: Maybe<Array<Scalars['Time']>>;
  nin?: Maybe<Array<Scalars['Time']>>;
  like?: Maybe<Scalars['String']>;
  is_null?: Maybe<Scalars['Boolean']>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  day: Scalars['Date'];
  slots: Array<Scalars['Time']>;
};

export enum UpdatablePatientProfessionalStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

export type UpdateAgendaEntryInput = {
  weekday?: Maybe<Scalars['Weekday']>;
  disabled?: Maybe<Scalars['Boolean']>;
  from?: Maybe<Scalars['Time']>;
  to?: Maybe<Scalars['Time']>;
  informers?: Maybe<Scalars['Int']>;
  visibility?: Maybe<AgendaEntryVisibility>;
  clinicId?: Maybe<Scalars['String']>;
  assistantIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateAgendaListInput = {
  name?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  openFor?: Maybe<Scalars['Int']>;
  status?: Maybe<AgendaListStatus>;
  updatePreviousList?: Maybe<Scalars['Boolean']>;
};

export type UpdateAppointmentInput = {
  notes?: Maybe<Scalars['String']>;
  patientName?: Maybe<Scalars['String']>;
  patientTaxId?: Maybe<Scalars['String']>;
};

export type UpdateAssistantInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type UpdateBreakInput = {
  notes?: Maybe<Scalars['String']>;
};

export type UpdateClinicInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
};

export type UpdateClinicRoomInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provisionIds?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
};

export type UpdateDelegationInput = {
  taxId?: Maybe<Scalars['TaxId']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
  patientProfessionalId?: Maybe<Scalars['String']>;
  types?: Maybe<Array<DelegationType>>;
};

export type UpdateMedicInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  registerNumber?: Maybe<Scalars['String']>;
  type?: Maybe<MedicType>;
  freeLancer?: Maybe<Scalars['Boolean']>;
  registerDistrictId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
  availability?: Maybe<AvailabilityInput>;
};

export type UpdateOperatorInput = {
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  profession?: Maybe<OperatorProfession>;
  address?: Maybe<CreateEmbedAddressInput>;
  contacts?: Maybe<CreateEmbedContactInput>;
  availability?: Maybe<AvailabilityInput>;
};

export type UpdateOrganizationSaInput = {
  name?: Maybe<Scalars['String']>;
  isGroupMedicine?: Maybe<Scalars['Boolean']>;
};

export type UpdatePatientInput = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
  personalMedicId?: Maybe<Scalars['String']>;
  address?: Maybe<CreateEmbedAddressInput>;
};

export type UpdatePatientProfessionalInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<CreateCryptedEmbedAddressInput>;
};

export type UpdateProfessionalProvisionInput = {
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Int']>;
};

export type UpdateReportInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<CreateAttachmentInput>>;
  medicName?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<Scalars['JSON']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  role: Scalars['String'];
  traits: UserTraits;
  verifiableAddresses: Array<UserEmailAddress>;
};

export type UserEmailAddress = {
  __typename?: 'UserEmailAddress';
  id: Scalars['ID'];
  value: Scalars['String'];
  verified: Scalars['Boolean'];
  via: Scalars['String'];
  status: Scalars['String'];
  verifiedAt?: Maybe<Scalars['String']>;
};

export type UserPhone = {
  __typename?: 'UserPhone';
  number: Scalars['String'];
  verified: Scalars['Boolean'];
  verifiedAt?: Maybe<Scalars['Float']>;
};

export type UserTraits = {
  __typename?: 'UserTraits';
  email: Scalars['String'];
  phone?: Maybe<UserPhone>;
  avatar?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['DateTime']>;
};


export type CityDataFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'registryCode' | 'name'>
  & { region: (
    { __typename?: 'Region' }
    & Pick<Region, 'name' | 'code'>
  ), district: (
    { __typename?: 'District' }
    & Pick<District, 'name' | 'code'>
  ) }
);

export type EmbedAddressDataFragment = (
  { __typename?: 'EmbedAddress' }
  & Pick<EmbedAddress, 'municipalityName' | 'districtName' | 'address' | 'location' | 'cap' | 'municipalityId'>
  & { municipality?: Maybe<(
    { __typename?: 'City' }
    & CityDataFragment
  )> }
);

export type CryptedEmbedDataFragment = (
  { __typename?: 'CryptedEmbedAddress' }
  & Pick<CryptedEmbedAddress, 'municipalityName' | 'municipalityId' | 'districtName' | 'nationName' | 'address'>
);

export type AgendaEntryBasicDataFragment = (
  { __typename?: 'AgendaEntry' }
  & Pick<AgendaEntry, 'id' | 'disabled' | 'weekday' | 'override' | 'visibility' | 'agendaWeekId' | 'from' | 'to' | 'clinicId'>
);

export type AgendaEntryDataFragment = (
  { __typename?: 'AgendaEntry' }
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  ) }
  & AgendaEntryBasicDataFragment
);

export type AgendaEntryInDayDataFragment = (
  { __typename?: 'AgendaEntryInDay' }
  & Pick<AgendaEntryInDay, 'day' | 'id' | 'disabled' | 'weekday' | 'override' | 'visibility' | 'agendaWeekId' | 'from' | 'to' | 'clinicId'>
  & { clinic: (
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  ) }
);

export type AvailsRenderBasicDataFragment = (
  { __typename?: 'AvailsRender' }
  & Pick<AvailsRender, 'id' | 'date_from' | 'date_to' | 'hour_from' | 'hour_to' | 'title'>
)

export type AvailsRenderDataFragment = (
  { __typename?: 'AvailsRender' }
  & AvailsRenderBasicDataFragment
)

export type AvailsAppointmentBasicDataFragment = (
  { __typename?: 'AvailsAppointment' }
  & Pick<AvailsAppointment, 'id' | 'unsubs_patient_name' | 'unsubs_patient_tax_id' | 'patientProfessionalId' | 'hour_from' | 'hour_to' | 'created_at' | 'clinicRoomId' | 'notes' | 'event_type'>
)

export type AvailsInstanceBasicDataFragment = (
  { __typename?: 'AvailsInstance' }
  & Pick<AvailsInstance, 'id' | 'created_at' | 'updated_at' | 'date' | 'override_hour_from' | 'override_hour_to' | 'override_visibility' | 'override_assistants_ids' | 'override_exclude_provisions' | 'renderingId'>
)

export type AvailsBreakBasicDataFragment = (
  { __typename?: 'AvailsBreak' }
  & Pick<AvailsBreak, 'id' | 'created_at' | 'hour_from' | 'hour_to' >
)

export type AvailsAppointmentDataFragment = (
  { __typename?: 'AvailsAppointment' }
  & { patient_professional?: Maybe<(
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  )>, professional_provision?: Maybe<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )>, instance: (
    { __typename?: 'AvailsInstance' }
    & AvailsInstanceBasicDataFragment
  ), professional: (
    { __typename?: 'Professional' }
    & ProfessionalBasicDataFragment
  ), clinicRoom: (
    { __typename?: 'ClinicRoom' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    )}
    & ClinicRoomDataFragment
  )}
  & AvailsAppointmentBasicDataFragment
)

export type AvailsBreakDataFragment = (
  { __typename?: 'AvailsBreak' }
  & { clinicRoom: (
    { __typename?: 'ClinicRoom' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
    & ClinicRoomDataFragment
  ), professional: (
    { __typename?: 'Professional' }
    & ProfessionalBasicDataFragment
  )}
  & AvailsBreakBasicDataFragment
)

export type AvailsInstanceWithEventsDataFragment = (
  { __typename?: 'AvailsInstanceWithEvents' }
  & Pick<AvailsInstanceWithEvents, 'id' | 'date' | 'created_at' | 'updated_at' | 'override_hour_from' | 'override_hour_to' | 'override_visibility' | 'override_assistants_ids' | 'override_exclude_provisions'>
)

export type SlotTypeDataFragment = (
  { __typename?: 'SlotType' }
  & Pick<SlotType, 'slots'>
  & { render: (
    { __typename?: 'AvailsRenderInDay' }
    & AvailsRenderInDayDataFragment
  )}
)

export type TimeSlotAvailsDataFragment = (
  { __typename?: 'TimeSlotAvails' }
  & Pick<TimeSlotAvails, 'day'>
  & { slots: Array<(
    { __typename?: 'SlotType' }
    & SlotTypeDataFragment
  )>}
)

export type AvailsRenderInDayDataFragment = (
  { __typename?: 'AvailsRenderInDay' }
  & Pick<AvailsRenderInDay , 'id' | 'title' | 'created_at' | 'updated_at' | 'date_from' | 'date_to' | 'cycle_week' | 'cycle_day' | 'res_open_in' | 'hour_from' | 'hour_to' | 'visibility' | 'status' | 'assistantIds' | 'exclude_provisions' | 'max_informer_number' >
  & { clinic_room: (
    { __typename?: 'ClinicRoom' }
    & { clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name'>
    ) }
    & ClinicRoomDataFragment
  ), instances: Array<(
    { __typename?: 'AvailsInstanceWithEvents' }
    & {
      appointments: Array<(
        { __typename?: 'AvailsAppointment' }
        & AvailsAppointmentDataFragment
      )>,
      breaks: Array<(
        { __typename?: 'AvailsBreak' }
        & AvailsBreakDataFragment
      )>
    }
    & AvailsInstanceWithEventsDataFragment
  )>
  }
);

// export type AgendaWeekBasicDataFragment = (
//   { __typename?: 'AgendaWeek' }
//   & Pick<AgendaWeek, 'id' | 'order'>
// );

// export type AgendaWeekDataFragment = (
//   { __typename?: 'AgendaWeek' }
//   & { entries: Array<(
//     { __typename?: 'AgendaEntry' }
//     & AgendaEntryDataFragment
//   )> }
//   & AgendaWeekBasicDataFragment
// );

// export type AgendaListBasicDataFragment = (
//   { __typename?: 'AgendaList' }
//   & Pick<AgendaList, 'id' | 'from' | 'to' | 'openFor' | 'status' | 'name'>
// );

// export type AgendaListDataFragment = (
//   { __typename?: 'AgendaList' }
//   & { weeks: Array<(
//     { __typename?: 'AgendaWeek' }
//     & AgendaWeekDataFragment
//   )> }
//   & AgendaListBasicDataFragment
// );

// export type AppointmentBasicDataFragment = (
//   { __typename?: 'Appointment' }
//   & Pick<Appointment, 'id' | 'patientProfessionalId' | 'from' | 'to' | 'day' | 'clinicId' | 'notes'>
//   & { professional: (
//     { __typename?: 'Administrator' }
//     & ProfessionalBasicData_Administrator_Fragment
//   ) | (
//     { __typename?: 'Assistant' }
//     & ProfessionalBasicData_Assistant_Fragment
//   ) | (
//     { __typename?: 'Medic' }
//     & ProfessionalBasicData_Medic_Fragment
//   ) | (
//     { __typename?: 'Operator' }
//     & ProfessionalBasicData_Operator_Fragment
//   ) | (
//     { __typename?: 'OrganizationSa' }
//     & ProfessionalBasicData_OrganizationSa_Fragment
//   ), provision?: Maybe<(
//     { __typename?: 'ProfessionalProvision' }
//     & Pick<ProfessionalProvision, 'id' | 'name'>
//     & { provision?: Maybe<(
//       { __typename?: 'Provision' }
//       & Pick<Provision, 'id' | 'name'>
//     )> }
//   )>, clinic: (
//     { __typename?: 'ClinicRoom' }
//     & Pick<ClinicRoom, 'id' | 'name' | 'phone'>
//     & { clinic: (
//       { __typename?: 'Clinic' }
//       & Pick<Clinic, 'id' | 'name'>
//     ) }
//   ) }
// );

// export type BreakBasicDataFragment = (
//   { __typename?: 'Break' }
//   & Pick<Break, 'id' | 'day' | 'from' | 'to' | 'clinicId' | 'notes'>
// );

// export type AppointmentDataFragment = (
//   { __typename?: 'Appointment' }
//   & Pick<Appointment, 'notes'>
//   & { professional: (
//     { __typename?: 'Administrator' }
//     & ProfessionalData_Administrator_Fragment
//   ) | (
//     { __typename?: 'Assistant' }
//     & ProfessionalData_Assistant_Fragment
//   ) | (
//     { __typename?: 'Medic' }
//     & ProfessionalData_Medic_Fragment
//   ) | (
//     { __typename?: 'Operator' }
//     & ProfessionalData_Operator_Fragment
//   ) | (
//     { __typename?: 'OrganizationSa' }
//     & ProfessionalData_OrganizationSa_Fragment
//   ), provision?: Maybe<(
//     { __typename?: 'ProfessionalProvision' }
//     & ProfessionalProvisionDataFragment
//   )>, clinic: (
//     { __typename?: 'ClinicRoom' }
//     & Pick<ClinicRoom, 'id' | 'name' | 'phone'>
//     & { clinic: (
//       { __typename?: 'Clinic' }
//       & ClinicDataFragment
//     ) }
//   ), creator?: Maybe<(
//     { __typename?: 'User' }
//     & UserDataFragment
//   )> }
//   & AppointmentBasicDataFragment
// );

// export type BreakDataFragment = (
//   { __typename?: 'Break' }
//   & Pick<Break, 'notes'>
//   & { clinic: (
//     { __typename?: 'ClinicRoom' }
//     & Pick<ClinicRoom, 'id' | 'name' | 'phone'>
//   ), creator?: Maybe<(
//     { __typename?: 'User' }
//     & UserDataFragment
//   )> }
//   & BreakBasicDataFragment
// );

// type AgendaEventBasicData_Appointment_Fragment = (
//   { __typename?: 'Appointment' }
//   & AppointmentBasicDataFragment
// );

// type AgendaEventBasicData_Break_Fragment = (
//   { __typename?: 'Break' }
//   & BreakBasicDataFragment
// );

// export type AgendaEventBasicDataFragment = AgendaEventBasicData_Appointment_Fragment | AgendaEventBasicData_Break_Fragment;

// type AgendaEventData_Appointment_Fragment = (
//   { __typename?: 'Appointment' }
//   & AppointmentDataFragment
// );

// type AgendaEventData_Break_Fragment = (
//   { __typename?: 'Break' }
//   & BreakDataFragment
// );

// export type AgendaEventDataFragment = AgendaEventData_Appointment_Fragment | AgendaEventData_Break_Fragment;

export type AttachmentDataFragment = (
  { __typename?: 'Attachment' }
  & Pick<Attachment, 'id' | 'url' | 'mimeType' | 'name'>
);

export type ClinicBasicDataFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'name' | 'phone'>
  & { address: (
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  ) }
);

export type ClinicDataFragment = (
  { __typename?: 'Clinic' }
  & Pick<Clinic, 'id' | 'name' | 'phone'>
  & { rooms: Array<(
    { __typename?: 'ClinicRoom' }
    & ClinicRoomDataFragment
  )>, address: (
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  ) }
);

export type ClinicRoomBasicDataFragment = (
  { __typename?: 'ClinicRoom' }
  & Pick<ClinicRoom, 'id' | 'name' | 'phone' | 'meta' | 'clinicId'>
  & { clinic: (
    { __typename?: 'Clinic' }
    & ClinicBasicDataFragment
  ) }
);

export type ClinicRoomDataFragment = (
  { __typename?: 'ClinicRoom' }
  & { provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )> }
  & ClinicRoomBasicDataFragment
);

export type ClinicRoomFromDayDataFragment = (
  { __typename?: 'ClinicRoomFromDay' }
  & Pick<ClinicRoomFromDay, 'professionalId' | 'fromDay'>
  & { professional: (
    { __typename?: 'Administrator' }
    & Pick<Administrator, 'id' | 'fullName' | 'role'>
    & { privacyPolicy?: Maybe<(
      { __typename?: 'Attachment' }
      & AttachmentDataFragment
    )>, professionalsSharedWithMe: Array<(
      { __typename?: 'ProfessionalShares' }
      & Pick<ProfessionalShares, 'id' | 'type'>
      & { professional: (
        { __typename?: 'Administrator' }
        & Pick<Administrator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Medic' }
        & Pick<Medic, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Operator' }
        & Pick<Operator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'OrganizationSa' }
        & Pick<OrganizationSa, 'id' | 'fullName'>
      ) }
    )> }
  ) | (
    { __typename?: 'Assistant' }
    & Pick<Assistant, 'id' | 'fullName' | 'role'>
    & { privacyPolicy?: Maybe<(
      { __typename?: 'Attachment' }
      & AttachmentDataFragment
    )>, professionalsSharedWithMe: Array<(
      { __typename?: 'ProfessionalShares' }
      & Pick<ProfessionalShares, 'id' | 'type'>
      & { professional: (
        { __typename?: 'Administrator' }
        & Pick<Administrator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Medic' }
        & Pick<Medic, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Operator' }
        & Pick<Operator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'OrganizationSa' }
        & Pick<OrganizationSa, 'id' | 'fullName'>
      ) }
    )> }
  ) | (
    { __typename?: 'Medic' }
    & Pick<Medic, 'id' | 'fullName' | 'role'>
    & { privacyPolicy?: Maybe<(
      { __typename?: 'Attachment' }
      & AttachmentDataFragment
    )>, professionalsSharedWithMe: Array<(
      { __typename?: 'ProfessionalShares' }
      & Pick<ProfessionalShares, 'id' | 'type'>
      & { professional: (
        { __typename?: 'Administrator' }
        & Pick<Administrator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Medic' }
        & Pick<Medic, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Operator' }
        & Pick<Operator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'OrganizationSa' }
        & Pick<OrganizationSa, 'id' | 'fullName'>
      ) }
    )> }
  ) | (
    { __typename?: 'Operator' }
    & Pick<Operator, 'id' | 'fullName' | 'role'>
    & { privacyPolicy?: Maybe<(
      { __typename?: 'Attachment' }
      & AttachmentDataFragment
    )>, professionalsSharedWithMe: Array<(
      { __typename?: 'ProfessionalShares' }
      & Pick<ProfessionalShares, 'id' | 'type'>
      & { professional: (
        { __typename?: 'Administrator' }
        & Pick<Administrator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Medic' }
        & Pick<Medic, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Operator' }
        & Pick<Operator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'OrganizationSa' }
        & Pick<OrganizationSa, 'id' | 'fullName'>
      ) }
    )> }
  ) | (
    { __typename?: 'OrganizationSa' }
    & Pick<OrganizationSa, 'id' | 'fullName' | 'role'>
    & { privacyPolicy?: Maybe<(
      { __typename?: 'Attachment' }
      & AttachmentDataFragment
    )>, professionalsSharedWithMe: Array<(
      { __typename?: 'ProfessionalShares' }
      & Pick<ProfessionalShares, 'id' | 'type'>
      & { professional: (
        { __typename?: 'Administrator' }
        & Pick<Administrator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Assistant' }
        & Pick<Assistant, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Medic' }
        & Pick<Medic, 'id' | 'fullName'>
      ) | (
        { __typename?: 'Operator' }
        & Pick<Operator, 'id' | 'fullName'>
      ) | (
        { __typename?: 'OrganizationSa' }
        & Pick<OrganizationSa, 'id' | 'fullName'>
      ) }
    )> }
  ), room: (
    { __typename?: 'ClinicRoom' }
    & Pick<ClinicRoom, 'id' | 'name' | 'phone' | 'meta' | 'clinicId'>
    & { provisions: Array<(
      { __typename?: 'ProfessionalProvision' }
      & ProfessionalProvisionDataFragment
    )>, clinic: (
      { __typename?: 'Clinic' }
      & Pick<Clinic, 'id' | 'name' | 'phone'>
      & { address: (
        { __typename?: 'EmbedAddress' }
        & EmbedAddressDataFragment
      ) }
    ) }
  ) }
);

export type ClinicRoomFromDayAvailsDataFragment = (
  { __typename?: 'ClinicRoomFromDayAvails' }
  & Pick<ClinicRoomFromDayAvails, 'fromDay'>
  & { 
    professional: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'id' | 'fullName' | 'role'>
      & { privacyPolicy?: Maybe<(
        { __typename?: 'Attachment' }
        & AttachmentDataFragment
      )>, professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
    ) | (
      { __typename?: 'Assistant' }
      & Pick<Assistant, 'id' | 'fullName' | 'role'>
      & { privacyPolicy?: Maybe<(
        { __typename?: 'Attachment' }
        & AttachmentDataFragment
      )>, professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
    ) | (
      { __typename?: 'Medic' }
      & Pick<Medic, 'id' | 'fullName' | 'role'>
      & { privacyPolicy?: Maybe<(
        { __typename?: 'Attachment' }
        & AttachmentDataFragment
      )>, professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
    ) | (
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName' | 'role'>
      & { privacyPolicy?: Maybe<(
        { __typename?: 'Attachment' }
        & AttachmentDataFragment
      )>, professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
    ) | (
      { __typename?: 'OrganizationSa' }
      & Pick<OrganizationSa, 'id' | 'fullName' | 'role'>
      & { privacyPolicy?: Maybe<(
        { __typename?: 'Attachment' }
        & AttachmentDataFragment
      )>, professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
    ),room: (
      { __typename?: 'ClinicRoom' }
      & Pick<ClinicRoom, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'phone' | 'meta' | 'clinicId'>
      & { provisions: Array<(
        { __typename?: 'ProfessionalProvision' }
        & ProfessionalProvisionDataFragment
      )>, clinic: (
        { __typename?: 'Clinic' }
        & Pick<Clinic, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'phone'>
        & { address: (
          { __typename?: 'EmbedAddress' }
          & EmbedAddressDataFragment
        ), professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'name' | 'surname'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & UserDataFragment
          )>, privacyPolicy?: Maybe<(
            { __typename?: 'Attachment' }
            & AttachmentDataFragment
          )> }
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'name' | 'surname'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & UserDataFragment
          )>, privacyPolicy?: Maybe<(
            { __typename?: 'Attachment' }
            & AttachmentDataFragment
          )> }
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'name' | 'surname'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & UserDataFragment
          )>, privacyPolicy?: Maybe<(
            { __typename?: 'Attachment' }
            & AttachmentDataFragment
          )> }
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'name' | 'surname'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & UserDataFragment
          )>, privacyPolicy?: Maybe<(
            { __typename?: 'Attachment' }
            & AttachmentDataFragment
          )> }
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'name' | 'surname'>
          & { user?: Maybe<(
            { __typename?: 'User' }
            & UserDataFragment
          )>, privacyPolicy?: Maybe<(
            { __typename?: 'Attachment' }
            & AttachmentDataFragment
          )> }
        ) }
      ) }
    ), firstAvailableRender: (
      { __typename?: 'AvailsRenderInDay' }
      & AvailsRenderInDayDataFragment
    ) 
  }
);

export type PaginationInfoDataFragment = (
  { __typename?: 'PaginationInfo' }
  & Pick<PaginationInfo, 'hasMore' | 'total'>
);

export type InvoiceBasicDataFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'bollo' | 'createdAt' | 'date' | 'footer' | 'header' | 'id' | 'invoiceType' | 'noTs' | 'number' | 'patientId' | 'payedAt' | 'payedWith' | 'paymentInfo' | 'professionalId' | 'recipientAddress' | 'recipientAnag' | 'recipientLocation' | 'recipientTaxId' | 'serie' | 'taxId' | 'ts' | 'updatedAt' | 'vatId' | 'vatPercent' | 'withholdingTax' | 'year' | 'vatCode'>
  & { invoiceOwner: (
    { __typename?: 'InvoiceOwner' }
    & InvoiceOwnerDataFragment
  ) }
);

export type InvoiceOwnerDataFragment = (
  { __typename?: 'InvoiceOwner' }
  & Pick<InvoiceOwner, 'role' | 'name' | 'surname' | 'type'>
);

export type InvoiceDataFragment = (
  { __typename?: 'Invoice' }
  & { invoiceRows: Array<(
    { __typename?: 'InvoiceRow' }
    & InvoiceRowDataFragment
  )> }
  & InvoiceBasicDataFragment
);

export type InvoiceRowDataFragment = (
  { __typename?: 'InvoiceRow' }
  & Pick<InvoiceRow, 'amount' | 'description' | 'id' | 'invoiceId' | 'isNote' | 'sequence' | 'updatedAt'>
);

export type InvoiceVatCodeDataFragment = (
  { __typename?: 'InvoiceVatCode' }
  & Pick<InvoiceVatCode, 'description' | 'id' | 'name' | 'percentage'>
);

export type InvoicePaymentTypeDataFragment = (
  { __typename?: 'InvoicePaymentType' }
  & Pick<InvoicePaymentType, 'description' | 'id' | 'name'>
);

export type InvoiceOptionsDataFragment = (
  { __typename?: 'InvoiceOptions' }
  & Pick<InvoiceOptions, 'defaultInvoiceType' | 'defaultPaymentInfo' | 'defaultSerie' | 'defaultVatCode' | 'professionalId' | 'taxId' | 'vatId'>
);

export type NotificationDataFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'userId' | 'readAt' | 'createdAt' | 'data' | 'name' | 'destinationProfile'>
);

export type PatientProfessionalDataFragment = (
  { __typename?: 'PatientProfessional' }
  & Pick<PatientProfessional, 'id' | 'status' | 'professionalId'>
  & { professional?: Maybe<(
    { __typename?: 'Administrator' }
    & ProfessionalBasicData_Administrator_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  )>, professionalTransfers: Array<(
    { __typename?: 'PatientProfessionalTransfer' }
    & PatientProfessionalTransferDataFragment
  )> }
);

export type PersonalMedicTransferDataFragment = (
  { __typename?: 'PersonalMedicTransfer' }
  & Pick<PersonalMedicTransfer, 'id' | 'acceptedAt' | 'patientId' | 'groupMedicineId'>
  & { target: (
    { __typename?: 'Administrator' }
    & Pick<Administrator, 'id' | 'fullName' | 'name' | 'surname'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserDataFragment
    )> }
  ) | (
    { __typename?: 'Assistant' }
    & Pick<Assistant, 'id' | 'fullName' | 'name' | 'surname'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserDataFragment
    )> }
  ) | (
    { __typename?: 'Medic' }
    & Pick<Medic, 'id' | 'fullName' | 'name' | 'surname'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserDataFragment
    )> }
  ) | (
    { __typename?: 'Operator' }
    & Pick<Operator, 'id' | 'fullName' | 'name' | 'surname'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserDataFragment
    )> }
  ) | (
    { __typename?: 'OrganizationSa' }
    & Pick<OrganizationSa, 'id' | 'fullName' | 'name' | 'surname'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserDataFragment
    )> }
  ) }
);

export type PatientProfessionalTransferDataFragment = (
  { __typename?: 'PatientProfessionalTransfer' }
  & Pick<PatientProfessionalTransfer, 'id' | 'acceptedAt' | 'targetId' | 'patientProfessionalId'>
  & { target: (
    { __typename?: 'Administrator' }
    & ProfessionalBasicData_Administrator_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ) }
);

export type SharedPatientProfessionalDataFragment = (
  { __typename?: 'SharedPatientProfessional' }
  & Pick<SharedPatientProfessional, 'id' | 'professionalId' | 'professionalShareId'>
  & { professionalShare: (
    { __typename?: 'ProfessionalShares' }
    & ProfessionalSharesDataFragment
  ) }
);

export type PatientBasicDataFragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'birthDay' | 'createdAt' | 'createdBy' | 'name' | 'personalMedicId' | 'surname' | 'taxId' | 'updatedAt'>
);

export type   PatientDataFragment = (
  { __typename?: 'Patient' }
  & { address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )>, users: Array<(
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  )>, personalMedic?: Maybe<(
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  )>, personalMedicTransfers: Array<(
    { __typename?: 'PersonalMedicTransfer' }
    & PersonalMedicTransferDataFragment
  )>, appointments?: Maybe<Array<(
    { __typename?: 'AvailsAppointment' }
    & AvailsAppointmentDataFragment
  )>>, }
  & PatientBasicDataFragment
);

export type PatientUserDataFragment = (
  { __typename?: 'PatientUser' }
  & Pick<PatientUser, 'id' | 'userId' | 'type' | 'patientId'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'name' | 'surname' | 'personalMedicId'>
  ), user: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
);

export type PlatformAlertDataFragment = (
  { __typename?: 'PlatformAlert' }
  & Pick<PlatformAlert, 'id' | 'severity' | 'target' | 'title' | 'message' | 'from' | 'to'>
);

type ProfessionalData_Administrator_Fragment = (
  { __typename?: 'Administrator' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, professionalsSharedWithMe: Array<(
    { __typename?: 'ProfessionalShares' }
    & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
    & { professional: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Assistant' }
      & Pick<Assistant, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Medic' }
      & Pick<Medic, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'OrganizationSa' }
      & Pick<OrganizationSa, 'id' | 'fullName'>
    ) }
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & AdministratorBasicDataFragment
);

type ProfessionalData_Assistant_Fragment = (
  { __typename?: 'Assistant' }
  & Pick<Assistant, 'id' | 'name' | 'surname'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, professionalsSharedWithMe: Array<(
    { __typename?: 'ProfessionalShares' }
    & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
    & { professional: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Assistant' }
      & Pick<Assistant, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Medic' }
      & Pick<Medic, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'OrganizationSa' }
      & Pick<OrganizationSa, 'id' | 'fullName'>
    ) }
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
);

type ProfessionalData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicBasicDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, professionalsSharedWithMe: Array<(
    { __typename?: 'ProfessionalShares' }
    & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
    & { professional: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Assistant' }
      & Pick<Assistant, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Medic' }
      & Pick<Medic, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'OrganizationSa' }
      & Pick<OrganizationSa, 'id' | 'fullName'>
    ) }
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & MedicDataFragment
);

type ProfessionalData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicBasicDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, professionalsSharedWithMe: Array<(
    { __typename?: 'ProfessionalShares' }
    & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
    & { professional: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Assistant' }
      & Pick<Assistant, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Medic' }
      & Pick<Medic, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'OrganizationSa' }
      & Pick<OrganizationSa, 'id' | 'fullName'>
    ) }
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OperatorDataFragment
);

type ProfessionalData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicBasicDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, professionalsSharedWithMe: Array<(
    { __typename?: 'ProfessionalShares' }
    & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
    & { professional: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Assistant' }
      & Pick<Assistant, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Medic' }
      & Pick<Medic, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Operator' }
      & Pick<Operator, 'id' | 'fullName'>
    ) | (
      { __typename?: 'OrganizationSa' }
      & Pick<OrganizationSa, 'id' | 'fullName'>
    ) }
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OrganizationSaDataFragment
);

export type ProfessionalDataFragment = ProfessionalData_Administrator_Fragment | ProfessionalData_Assistant_Fragment | ProfessionalData_Medic_Fragment | ProfessionalData_Operator_Fragment | ProfessionalData_OrganizationSa_Fragment;

type ProfessionalBasicData_Administrator_Fragment = (
  { __typename?: 'Administrator' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & AdministratorBasicDataFragment
);

type ProfessionalBasicData_Assistant_Fragment = (
  { __typename?: 'Assistant' }
  & Pick<Assistant, 'id' | 'name' | 'surname'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
);

type ProfessionalBasicData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & MedicBasicDataFragment
);

type ProfessionalBasicData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OperatorBasicDataFragment
);

type ProfessionalBasicData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, privacyPolicy?: Maybe<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )> }
  & OrganizationSaBasicDataFragment
);

export type ProfessionalBasicDataFragment = ProfessionalBasicData_Administrator_Fragment | ProfessionalBasicData_Assistant_Fragment | ProfessionalBasicData_Medic_Fragment | ProfessionalBasicData_Operator_Fragment | ProfessionalBasicData_OrganizationSa_Fragment;

export type EmbedContactDataFragment = (
  { __typename?: 'EmbedContact' }
  & Pick<EmbedContact, 'email' | 'fax' | 'phone'>
);

export type MedicDataFragment = (
  { __typename?: 'Medic' }
  & { provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )> }
  & MedicBasicDataFragment
);

export type MedicBasicDataFragment = (
  { __typename?: 'Medic' }
  & Pick<Medic, 'id' | 'userId' | 'role' | 'name' | 'surname' | 'fullName' | 'registerNumber' | 'type' | 'freeLancer'>
  & { contacts?: Maybe<(
    { __typename?: 'EmbedContact' }
    & EmbedContactDataFragment
  )>, availability: (
    { __typename?: 'EmbedAvailability' }
    & AvailabilityDataFragment
  ), specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, registerDistrict?: Maybe<(
    { __typename?: 'City' }
    & CityDataFragment
  )>, address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )>, activeLicense?: Maybe<(
    { __typename?: 'License' }
    & Pick<License, 'id' | 'expiresAt' | 'code'>
  )> }
);

export type OperatorDataFragment = (
  { __typename?: 'Operator' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & SpecializationDataFragment
  )>, provisions: Array<(
    { __typename?: 'ProfessionalProvision' }
    & ProfessionalProvisionDataFragment
  )> }
  & OperatorBasicDataFragment
);

export type AdministratorBasicDataFragment = (
  { __typename?: 'Administrator' }
  & Pick<Administrator, 'id' | 'role' | 'userId' | 'name' | 'surname' | 'fullName'>
);

export type OperatorBasicDataFragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'role' | 'profession' | 'userId' | 'name' | 'surname' | 'fullName'>
  & { availability: (
    { __typename?: 'EmbedAvailability' }
    & AvailabilityDataFragment
  ), user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, contacts?: Maybe<(
    { __typename?: 'EmbedContact' }
    & EmbedContactDataFragment
  )>, address?: Maybe<(
    { __typename?: 'EmbedAddress' }
    & EmbedAddressDataFragment
  )>, activeLicense?: Maybe<(
    { __typename?: 'License' }
    & Pick<License, 'id' | 'expiresAt' | 'code'>
  )> }
);

export type OrganizationProfessionalsDataFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id'>
  & { professionals: Array<(
    { __typename?: 'OrganizationProfessional' }
    & OrganizationProfessionalPersonalDataFragment
  )> }
);

export type OrganizationSaDataFragment = (
  { __typename?: 'OrganizationSa' }
  & { managedOrganization: (
    { __typename?: 'Organization' }
    & OrganizationProfessionalsDataFragment
  ) }
  & OrganizationSaBasicDataFragment
);

export type OrganizationSaBasicDataFragment = (
  { __typename?: 'OrganizationSa' }
  & Pick<OrganizationSa, 'id' | 'role' | 'userId' | 'isGroupMedicine' | 'name' | 'surname' | 'fullName'>
  & { activeLicense?: Maybe<(
    { __typename?: 'License' }
    & Pick<License, 'id' | 'expiresAt' | 'code'>
  )> }
);

export type OrganizationProfessionalPersonalDataFragment = (
  { __typename?: 'OrganizationProfessional' }
  & Pick<OrganizationProfessional, 'id' | 'role'>
  & { professional: (
    { __typename?: 'Administrator' }
    & ProfessionalBasicData_Administrator_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ) }
);

export type ProfessionalProvisionDataFragment = (
  { __typename?: 'ProfessionalProvision' }
  & Pick<ProfessionalProvision, 'id' | 'duration' | 'price' | 'mandatory' | 'updatedAt' | 'professionalId' | 'provisionId' | 'name'>
  & { provision?: Maybe<(
    { __typename?: 'Provision' }
    & ProvisionDataFragment
  )> }
);

export type AvailabilityDataFragment = (
  { __typename?: 'EmbedAvailability' }
  & Pick<EmbedAvailability, 'phone' | 'email' | 'chat' | 'requests'>
);

export type ProfessionalSharesBasicDataFragment = (
  { __typename?: 'ProfessionalShares' }
  & Pick<ProfessionalShares, 'id' | 'type' | 'acceptedAt'>
  & { professional: (
    { __typename?: 'Administrator' }
    & ProfessionalBasicData_Administrator_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ), sharedWith: (
    { __typename?: 'Administrator' }
    & ProfessionalBasicData_Administrator_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  ), managedOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationProfessionalsDataFragment
  )> }
);

export type ProfessionalSharesDataFragment = (
  { __typename?: 'ProfessionalShares' }
  & { parent?: Maybe<(
    { __typename?: 'ProfessionalShares' }
    & ProfessionalSharesBasicDataFragment
  )> }
  & ProfessionalSharesBasicDataFragment
);

export type ProvisionDataFragment = (
  { __typename?: 'Provision' }
  & Pick<Provision, 'id' | 'name' | 'defaultPrice' | 'defaultDuration' | 'code'>
);

export type ProvisionPaginatedDataFragment = (
  { __typename?: 'ProvisionPaginatedResponse' }
  & { items: Array<(
    { __typename?: 'Provision' }
    & ProvisionDataFragment
  )>, paginationInfo: (
    { __typename?: 'PaginationInfo' }
    & Pick<PaginationInfo, 'hasMore' | 'total'>
  ) }
);

export type TimeSlotDataFragment = (
  { __typename?: 'TimeSlot' }
  & Pick<TimeSlot, 'day' | 'slots'>
);

export type CupProviderDataFragment = (
  { __typename?: 'CupProvider' }
  & Pick<CupProvider, 'id' | 'date' | 'duration' | 'price' | 'address' | 'addressName' | 'name' | 'url' | 'trackUrl'>
);

export type FrequentProvisionDataFragment = (
  { __typename?: 'FrequentProvision' }
  & Pick<FrequentProvision, 'id' | 'isCup' | 'name'>
);

export type ReportBasicDataFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'title' | 'description' | 'createdBy' | 'reportDate' | 'createdAt' | 'medicName' | 'professionalId' | 'meta' | 'notes'>
  & { attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>> }
);

export type ReportDataFragment = (
  { __typename?: 'Report' }
  & { professional?: Maybe<(
    { __typename?: 'Administrator' }
    & ProfessionalBasicData_Administrator_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalBasicData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalBasicData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalBasicData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalBasicData_OrganizationSa_Fragment
  )>, shares: Array<(
    { __typename?: 'ReportSharedWith' }
    & ReportSharedWithDataFragment
  )> }
  & ReportBasicDataFragment
);

export type ReportSharedWithDataFragment = (
  { __typename?: 'ReportSharedWith' }
  & Pick<ReportSharedWith, 'accepted' | 'reportId' | 'patientProfessionalId'>
  & { patientProfessional: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

type RequestAuthorData_Medic_Fragment = (
  { __typename?: 'Medic' }
  & Pick<Medic, 'id' | 'role' | 'name' | 'surname'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
);

type RequestAuthorData_Operator_Fragment = (
  { __typename?: 'Operator' }
  & Pick<Operator, 'id' | 'name' | 'surname' | 'role'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
);

type RequestAuthorData_Patient_Fragment = (
  { __typename?: 'Patient' }
  & Pick<Patient, 'id' | 'name' | 'surname' | 'taxId'>
);

type RequestAuthorData_OrganizationSa_Fragment = (
  { __typename?: 'OrganizationSa' }
  & Pick<OrganizationSa, 'id' | 'name' | 'surname' | 'role'>
);

export type RequestAuthorDataFragment = RequestAuthorData_Medic_Fragment | RequestAuthorData_Operator_Fragment | RequestAuthorData_Patient_Fragment | RequestAuthorData_OrganizationSa_Fragment;

export type RequestMessageDataFragment = (
  { __typename?: 'RequestMessage' }
  & Pick<RequestMessage, 'id' | 'message' | 'readAt' | 'createdAt'>
  & { creator?: Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )>, author?: Maybe<(
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  )>, attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & AttachmentDataFragment
  )>> }
);

export type RequestDataFragment = (
  { __typename?: 'Request' }
  & Pick<Request, 'id' | 'title' | 'type' | 'result' | 'private' | 'createdAt'>
  & { messages: Array<(
    { __typename?: 'RequestMessage' }
    & RequestMessageDataFragment
  )>, recipient: (
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  ), sender: (
    { __typename?: 'Medic' }
    & RequestAuthorData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & RequestAuthorData_Operator_Fragment
  ) | (
    { __typename?: 'Patient' }
    & RequestAuthorData_Patient_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & RequestAuthorData_OrganizationSa_Fragment
  ) }
);

export type SpecializationDataFragment = (
  { __typename?: 'Specialization' }
  & Pick<Specialization, 'id' | 'name'>
);

export type UserTraitsDataFragment = (
  { __typename?: 'UserTraits' }
  & Pick<UserTraits, 'email' | 'avatar' | 'taxId' | 'name' | 'surname' | 'birthDay'>
);

export type UserEmailAddressDataFragment = (
  { __typename?: 'UserEmailAddress' }
  & Pick<UserEmailAddress, 'id' | 'value' | 'verified' | 'via' | 'status'>
);

export type UserDataFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'role'>
  & { traits: (
    { __typename?: 'UserTraits' }
    & UserTraitsDataFragment
  ) }
);

export type BookProvisionMutationVariables = Exact<{
  appointment: CreateAppointmentInput;
  patientId: Scalars['String'];
  medicId: Scalars['String'];
}>;


export type BookProvisionMutation = (
  { __typename?: 'Mutation' }
  & { createAppointmentAsPatient: (
    { __typename?: 'Appointment' }
    & AvailsAppointmentDataFragment
  ) }
);

export type CreateAvailsAppointmentAsPatientMutationVariables = Exact<{
  renderingId?: Scalars['String'];
  appointment: CreateAvailsAppointmentInput;
  patientId: Scalars['String'];
  medicId: Scalars['String'];
}>;


export type CreateAvailsAppointmentAsPatientMutation = (
  { __typename?: 'Mutation' }
  & { createAvailsAppointmentAsPatient: (
    { __typename?: 'AvailsAppointment' }
    & AvailsAppointmentDataFragment
  ) }
);

export type DeleteAvailsAppointmentMutationVariables = Exact<{
  id: Scalars['String'];
  patientId: Scalars['String'];
}>;


export type DeleteAvailsAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAvailsAppointmentAsPatient'>
);

export type ChatAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type ChatAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getChatAccessToken'>
);

export type StartChatMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type StartChatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createChat'>
);

export type CreatePatientProfessionalMutationVariables = Exact<{
  professionalId: Scalars['String'];
  patientId: Scalars['String'];
}>;


export type CreatePatientProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { createPatientProfessionalAsPatient: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationAsRead: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export type ReadManyNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReadManyNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { markManyNotificationsAsRead: Array<(
    { __typename?: 'Notification' }
    & NotificationDataFragment
  )> }
);

export type RemovePersonalMedicMutationVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type RemovePersonalMedicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePersonalMedic'>
);

export type AcceptPatientProfessionalTransferMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AcceptPatientProfessionalTransferMutation = (
  { __typename?: 'Mutation' }
  & { acceptPatientProfessionalTransfer: (
    { __typename?: 'PatientProfessionalTransfer' }
    & PatientProfessionalTransferDataFragment
  ) }
);

export type DeletePersonalMedicTransferMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePersonalMedicTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePersonalMedicTransfer'>
);

export type RequestPersonalMedicMutationVariables = Exact<{
  patientId: Scalars['String'];
  medicId: Scalars['String'];
  groupMedicineId?: Maybe<Scalars['String']>;
}>;


export type RequestPersonalMedicMutation = (
  { __typename?: 'Mutation' }
  & { requestPersonalMedic: (
    { __typename?: 'PersonalMedicTransfer' }
    & PersonalMedicTransferDataFragment
  ) }
);

export type DenyPatientProfessionalTransferMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DenyPatientProfessionalTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'denyPatientProfessionalTransfer'>
);

export type UpdatePatientProfessionalStatusMutationVariables = Exact<{
  id: Scalars['String'];
  patientId: Scalars['String'];
  status: UpdatablePatientProfessionalStatus;
}>;


export type UpdatePatientProfessionalStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientProfessionalStatus: (
    { __typename?: 'PatientProfessional' }
    & Pick<PatientProfessional, 'id' | 'status'>
  ) }
);

export type ArchivePatientProfessionalMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ArchivePatientProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { archivePatientProfessional: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type CreateUserPatientMutationVariables = Exact<{
  patientId?: Maybe<Scalars['String']>;
  patient?: Maybe<CreateUserPatientInput>;
  termsAndConditions: Scalars['Boolean'];
  type?: Maybe<PatientType>;
}>;


export type CreateUserPatientMutation = (
  { __typename?: 'Mutation' }
  & { createUserPatient: (
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  ) }
);

export type DeleteUserPatientMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserPatientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserPatient'>
);

export type CreateReportMutationVariables = Exact<{
  patientProfessionalId?: Maybe<Scalars['String']>;
  report: CreateReportInput;
  patientId: Scalars['String'];
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReportAsPatient: (
    { __typename?: 'Report' }
    & ReportDataFragment
  ) }
);

export type ShareReportMutationVariables = Exact<{
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
}>;


export type ShareReportMutation = (
  { __typename?: 'Mutation' }
  & { shareReport: (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  ) }
);

export type RevokeReportShareMutationVariables = Exact<{
  patientProfessionalId: Scalars['String'];
  id: Scalars['String'];
}>;


export type RevokeReportShareMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeReportShare'>
);

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReport'>
);

export type CreateRequestMutationVariables = Exact<{
  request: CreateRequestInput;
  patientId: Scalars['String'];
}>;


export type CreateRequestMutation = (
  { __typename?: 'Mutation' }
  & { createRequestAsPatient: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type DeleteRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRequest'>
);

export type SearchCityQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SearchCityQuery = (
  { __typename?: 'Query' }
  & { cities: (
    { __typename?: 'CityPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'City' }
      & CityDataFragment
    )> }
  ) }
);

export type SearchDistrictQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SearchDistrictQuery = (
  { __typename?: 'Query' }
  & { cities: (
    { __typename?: 'CityPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'City' }
      & CityDataFragment
    )> }
  ) }
);

export type GetCityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCityQuery = (
  { __typename?: 'Query' }
  & { city: (
    { __typename?: 'City' }
    & CityDataFragment
  ) }
);

export type AvailsAppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AvailsAppointmentQuery = (
  { __typename?: 'Query' }
  & { availsAppointment: (
    { __typename?: 'AvailsAppointment' }
    & AvailsAppointmentDataFragment
  ) }
);

export type PastAvailsAppointmentsQueryVariables = Exact<{
  patientId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<AvailsAppointmentOrderInput>;
}>;


export type PastAvailsAppointmentsQuery = (
  { __typename?: 'Query' }
  & { pastAvailsAppointmentsByPatient: (
    { __typename?: 'AvailsAppointmentsPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'AvailsAppointment' }
      & AvailsAppointmentDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'hasMore' | 'total'>
    ) }
  ) }
);

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = (
  { __typename?: 'Query' }
  & { whoami: (
    { __typename?: 'User' }
    & UserDataFragment
  ) }
);

export type ClinicsQueryVariables = Exact<{
  medicId: Scalars['ID'];
}>;


export type ClinicsQuery = (
  { __typename?: 'Query' }
  & { clinics: Array<(
    { __typename?: 'Clinic' }
    & ClinicBasicDataFragment
  )> }
);

export type InvoiceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { getInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceDataFragment
  )> }
);

export type InvoiceOptionsQueryVariables = Exact<{
  medicId: Scalars['Int'];
}>;


export type InvoiceOptionsQuery = (
  { __typename?: 'Query' }
  & { getInvoiceOptions?: Maybe<(
    { __typename?: 'InvoiceOptions' }
    & InvoiceOptionsDataFragment
  )> }
);

export type InvoiceVatCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoiceVatCodesQuery = (
  { __typename?: 'Query' }
  & { getInvoiceVatCodes: Array<(
    { __typename?: 'InvoiceVatCode' }
    & InvoiceVatCodeDataFragment
  )> }
);

export type InvoicesQueryVariables = Exact<{
  patientId: Scalars['Int'];
  year?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { getInvoicesByPatient?: Maybe<(
    { __typename?: 'InvoicePaginator' }
    & { data: Array<(
      { __typename?: 'Invoice' }
      & InvoiceDataFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'hasMorePages' | 'total'>
    ) }
  )> }
);

export type InvoicePaymentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicePaymentTypesQuery = (
  { __typename?: 'Query' }
  & { getInvoicePaymentTypes: Array<(
    { __typename?: 'InvoicePaymentType' }
    & InvoicePaymentTypeDataFragment
  )> }
);

export type NotificationsQueryVariables = Exact<{
  filters?: Maybe<NotificationFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetNotificationsQueryVariables = Exact<{
  filters?: Maybe<NotificationFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  read?: Maybe<Scalars['Boolean']>;
  profileType?: Maybe<Scalars['String']>;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { getNotifications: (
    { __typename?: 'NotificationPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ), totalUnReadNotification: Scalars['Float']
   }
  ) }
);

export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type NotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NotificationQuery = (
  { __typename?: 'Query' }
  & { notification: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export type PatientsQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientsQuery = (
  { __typename?: 'Query' }
  & { patientProfiles?: Maybe<Array<(
    { __typename?: 'PatientUser' }
    & PatientUserDataFragment
  )>> }
);

export type PatientQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PatientQuery = (
  { __typename?: 'Query' }
  & { 
    patient?: Maybe<(
      { __typename?: 'Patient' }
      & PatientDataFragment
    )>
  }
);

/* export type PatientQuery = (
  { __typename?: 'Query' }
  & { patient: (
    { __typename?: 'Patient' }
    & { appointments: Array<(
      { __typename?: 'AvailsAppointment' }
      & AvailsAppointmentDataFragment
    )> }
    & PatientDataFragment
  ) }
); */

export type SearchPatientsQueryVariables = Exact<{
  filter?: Maybe<PatientFilterInput>;
}>;


export type SearchPatientsQuery = (
  { __typename?: 'Query' }
  & { patients: (
    { __typename?: 'PatientPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Patient' }
      & PatientDataFragment
    )> }
  ) }
);

export type PatientToAssociateQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientToAssociateQuery = (
  { __typename?: 'Query' }
  & { patientToAssociate?: Maybe<(
    { __typename?: 'Patient' }
    & PatientDataFragment
  )> }
);

export type PatientProfessionalsQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type PatientProfessionalsQuery = (
  { __typename?: 'Query' }
  & { patientProfessionalsAsPatient: Array<(
    { __typename?: 'SharedPatientProfessional' }
    & SharedPatientProfessionalDataFragment
  ) | (
    { __typename?: 'PatientProfessional' }
    & PatientProfessionalDataFragment
  )> }
);

export type PlatformAlertsQueryVariables = Exact<{
  day: Scalars['DateTime'];
}>;


export type PlatformAlertsQuery = (
  { __typename?: 'Query' }
  & { platformAlerts: Array<(
    { __typename?: 'PlatformAlert' }
    & PlatformAlertDataFragment
  )> }
);

export type ProfessionalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProfessionalQuery = (
  { __typename?: 'Query' }
  & { professional: (
    { __typename?: 'Administrator' }
    & ProfessionalData_Administrator_Fragment
  ) | (
    { __typename?: 'Assistant' }
    & ProfessionalData_Assistant_Fragment
  ) | (
    { __typename?: 'Medic' }
    & ProfessionalData_Medic_Fragment
  ) | (
    { __typename?: 'Operator' }
    & ProfessionalData_Operator_Fragment
  ) | (
    { __typename?: 'OrganizationSa' }
    & ProfessionalData_OrganizationSa_Fragment
  ) }
);

export type ProfessionalsQueryVariables = Exact<{
  filter?: Maybe<ProfessionalFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type ProfessionalsQuery = (
  { __typename?: 'Query' }
  & { professionals: (
    { __typename?: 'ProfessionalPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Administrator' }
      & { professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
      & ProfessionalBasicData_Administrator_Fragment
    ) | (
      { __typename?: 'Assistant' }
      & { professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
      & ProfessionalBasicData_Assistant_Fragment
    ) | (
      { __typename?: 'Medic' }
      & { professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
      & ProfessionalBasicData_Medic_Fragment
    ) | (
      { __typename?: 'Operator' }
      & { professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
      & ProfessionalBasicData_Operator_Fragment
    ) | (
      { __typename?: 'OrganizationSa' }
      & { professionalsSharedWithMe: Array<(
        { __typename?: 'ProfessionalShares' }
        & Pick<ProfessionalShares, 'id' | 'type' | 'professionalId'>
        & { professional: (
          { __typename?: 'Administrator' }
          & Pick<Administrator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Assistant' }
          & Pick<Assistant, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Medic' }
          & Pick<Medic, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Operator' }
          & Pick<Operator, 'id' | 'fullName'>
        ) | (
          { __typename?: 'OrganizationSa' }
          & Pick<OrganizationSa, 'id' | 'fullName'>
        ) }
      )> }
      & ProfessionalBasicData_OrganizationSa_Fragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type PatientProfessionalQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientProfessionalQuery = (
  { __typename?: 'Query' }
  & { patientProfessional: (
    { __typename?: 'PatientProfessional' }
    & { sharedReports: Array<(
      { __typename?: 'ReportSharedWith' }
      & { report: (
        { __typename?: 'Report' }
        & ReportBasicDataFragment
      ) }
      & ReportSharedWithDataFragment
    )> }
    & PatientProfessionalDataFragment
  ) }
);

export type DefaultProvisionsQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultProvisionsQuery = (
  { __typename?: 'Query' }
  & { defaultProvisions: Array<(
    { __typename?: 'Provision' }
    & ProvisionDataFragment
  )> }
);

export type ProvisionsQueryVariables = Exact<{
  filter?: Maybe<ProvisionFilterInput>;
}>;


export type ProvisionsQuery = (
  { __typename?: 'Query' }
  & { provisions: (
    { __typename?: 'ProvisionPaginatedResponse' }
    & ProvisionPaginatedDataFragment
  ) }
);

export type ClinicRoomByProvisionQueryVariables = Exact<{
  distance?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<LatLngInput>;
  medicId?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
}>;


export type ClinicRoomByProvisionQuery = (
  { __typename?: 'Query' }
  & { clinicRoomByProvision: Array<(
    { __typename?: 'ClinicRoomFromDay' }
    & ClinicRoomFromDayDataFragment
  )> }
);

export type ClinicRoomByProvisionAvailsQueryVariables = Exact<{
  distance?: Maybe<Scalars['Int']>;
  coordinates?: Maybe<LatLngInput>;
  medicId?: Maybe<Scalars['String']>;
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
}>;


export type ClinicRoomByProvisionAvailsQuery = (
  { __typename?: 'Query' }
  & { clinicRoomByProvisionAvails: Array<(
    { __typename?: 'ClinicRoomFromDayAvails' }
    & ClinicRoomFromDayAvailsDataFragment
  )> }
);

export type CupProvidersQueryVariables = Exact<{
  coordinates: LatLngInput;
  provisionId: Scalars['String'];
  isCup?: Maybe<Scalars['Boolean']>;
}>;


export type CupProvidersQuery = (
  { __typename?: 'Query' }
  & { cupProviders: Array<(
    { __typename?: 'CupProvider' }
    & CupProviderDataFragment
  )> }
);

export type TimeSlotsQueryVariables = Exact<{
  patientId: Scalars['String'];
  clinicIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  provisionId: Scalars['String'];
}>;


export type TimeSlotsQuery = (
  { __typename?: 'Query' }
  & { timeslotsAsPatient: Array<(
    { __typename?: 'TimeSlot' }
    & TimeSlotDataFragment
  )> }
);

export type TimeSlotsAvailsAsPatientQueryVariables = Exact<{
  clinicIds?: Array<Scalars['String']>;
  to: Scalars['Date'];
  from: Scalars['Date'];
  patientId: Scalars['String'];
  provisionId: Scalars['String'];
}>;

export type TimeSlotsAvailsAsPatientQuery = (
  { __typename?: 'Query' }
  & { timeslotsAvailsAsPatient: Array<(
    { __typename?: 'TimeSlotAvails' }
    & TimeSlotAvailsDataFragment
  )> }
);

export type FrequentProvisionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FrequentProvisionsQuery = (
  { __typename?: 'Query' }
  & { frequentProvisions: Array<(
    { __typename?: 'FrequentProvision' }
    & FrequentProvisionDataFragment
  )> }
);

export type ReportsQueryVariables = Exact<{
  patientId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<ReportOrderInput>;
}>;


export type ReportsQuery = (
  { __typename?: 'Query' }
  & { patientReports: (
    { __typename?: 'ReportsPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Report' }
      & ReportBasicDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type ReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & ReportDataFragment
  ) }
);

export type RequestsQueryVariables = Exact<{
  patientId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type RequestsQuery = (
  { __typename?: 'Query' }
  & { sentRequestsAsPatient: (
    { __typename?: 'RequestPaginatedResponse' }
    & { items: Array<(
      { __typename?: 'Request' }
      & RequestDataFragment
    )>, paginationInfo: (
      { __typename?: 'PaginationInfo' }
      & PaginationInfoDataFragment
    ) }
  ) }
);

export type RequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RequestQuery = (
  { __typename?: 'Query' }
  & { request: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type OnNewNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnNewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onNewNotification: (
    { __typename?: 'Notification' }
    & NotificationDataFragment
  ) }
);

export const CryptedEmbedDataFragmentDoc = gql`
    fragment CryptedEmbedData on CryptedEmbedAddress {
  municipalityName
  municipalityId
  districtName
  nationName
  address
}
    `;
export const CityDataFragmentDoc = gql`
    fragment CityData on City {
  id
  registryCode
  name
  region {
    name
    code
  }
  district {
    name
    code
  }
}
    `;
export const EmbedAddressDataFragmentDoc = gql`
    fragment EmbedAddressData on EmbedAddress {
  municipalityName
  districtName
  address
  location
  cap
  municipalityId
  municipality {
    ...CityData
  }
}
    ${CityDataFragmentDoc}`;
export const ClinicBasicDataFragmentDoc = gql`
    fragment ClinicBasicData on Clinic {
  id
  name
  phone
  address {
    ...EmbedAddressData
  }
}
    ${EmbedAddressDataFragmentDoc}`;
export const ClinicRoomBasicDataFragmentDoc = gql`
    fragment ClinicRoomBasicData on ClinicRoom {
  id
  name
  phone
  meta
  clinicId
  clinic {
    ...ClinicBasicData
  }
}
    ${ClinicBasicDataFragmentDoc}`;
export const ProvisionDataFragmentDoc = gql`
    fragment ProvisionData on Provision {
  id
  name
  defaultPrice
  defaultDuration
  code
}
    `;
export const ProfessionalProvisionDataFragmentDoc = gql`
    fragment ProfessionalProvisionData on ProfessionalProvision {
  id
  duration
  price
  mandatory
  updatedAt
  professionalId
  provisionId
  name
  provision {
    ...ProvisionData
  }
}
    ${ProvisionDataFragmentDoc}`;
export const ClinicRoomDataFragmentDoc = gql`
    fragment ClinicRoomData on ClinicRoom {
  ...ClinicRoomBasicData
  provisions {
    ...ProfessionalProvisionData
  }
}
    ${ClinicRoomBasicDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}`;
export const AgendaEntryInDayDataFragmentDoc = gql`
    fragment AgendaEntryInDayData on AgendaEntryInDay {
  day
  id
  disabled
  weekday
  override
  visibility
  agendaWeekId
  from
  to
  clinicId
  clinic {
    ...ClinicRoomData
  }
}
    ${ClinicRoomDataFragmentDoc}`;
export const AvailsInstanceWithEventsDataFragmentDoc = gql`
fragment AvailsInstanceWithEventsData on AvailsInstanceWithEvents {
  id
  date
  created_at
  updated_at
  override_hour_from
  override_hour_to
  override_visibility
  override_assistants_ids
  override_exclude_provisions
}
`;

export const AvailsRenderBasicDataFragmentDoc = gql`
    fragment AvailsRenderBasicData on AvailsRender {
  id
  date_from
  date_to
  hour_from
  hour_to
  title
}
    `;
export const AgendaListBasicDataFragmentDoc = gql`
    fragment AgendaListBasicData on AgendaList {
  id
  from
  to
  openFor
  status
  name
}
    `;
export const AgendaWeekBasicDataFragmentDoc = gql`
    fragment AgendaWeekBasicData on AgendaWeek {
  id
  order
}
    `;
export const AgendaEntryBasicDataFragmentDoc = gql`
    fragment AgendaEntryBasicData on AgendaEntry {
  id
  disabled
  weekday
  override
  visibility
  agendaWeekId
  from
  to
  clinicId
}
    `;
export const AgendaEntryDataFragmentDoc = gql`
    fragment AgendaEntryData on AgendaEntry {
  ...AgendaEntryBasicData
  clinic {
    ...ClinicRoomData
  }
}
    ${AgendaEntryBasicDataFragmentDoc}
${ClinicRoomDataFragmentDoc}`;
export const AgendaWeekDataFragmentDoc = gql`
    fragment AgendaWeekData on AgendaWeek {
  ...AgendaWeekBasicData
  entries {
    ...AgendaEntryData
  }
}
    ${AgendaWeekBasicDataFragmentDoc}
${AgendaEntryDataFragmentDoc}`;
export const AgendaListDataFragmentDoc = gql`
    fragment AgendaListData on AgendaList {
  ...AgendaListBasicData
  weeks {
    ...AgendaWeekData
  }
}
    ${AgendaListBasicDataFragmentDoc}
${AgendaWeekDataFragmentDoc}`;
export const AvailsInstanceBasicDataFragmentDoc =  gql`
    fragment AvailsInstanceBasicData on AvailsInstance {
  id
  created_at
  updated_at
  date
  override_hour_from
  override_hour_to
  override_visibility
  override_assistants_ids
  override_exclude_provisions
  renderingId
}`;
export const AvailsBreakBasicDataFragmentDoc = gql`
    fragment AvailsBreakBasicData on AvailsBreak {
  id
  created_at
  hour_from
  hour_to
}`;
export const AvailsAppointmentBasicDataFragmentDoc = gql`
    fragment AvailsAppointmentBasicData on AvailsAppointment {
  id
  unsubs_patient_name
  unsubs_patient_tax_id
  patientProfessionalId
  hour_from
  hour_to
  created_at
  clinicRoomId
  notes
  event_type
}
`;
export const EmbedContactDataFragmentDoc = gql`
    fragment EmbedContactData on EmbedContact {
  email
  fax
  phone
}
    `;
export const AvailabilityDataFragmentDoc = gql`
    fragment AvailabilityData on EmbedAvailability {
  phone
  email
  chat
  requests
}
    `;
export const SpecializationDataFragmentDoc = gql`
    fragment SpecializationData on Specialization {
  id
  name
}
    `;
export const UserTraitsDataFragmentDoc = gql`
    fragment UserTraitsData on UserTraits {
  email
  avatar
  taxId
  name
  surname
  birthDay
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  role
  traits {
    ...UserTraitsData
  }
}
    ${UserTraitsDataFragmentDoc}`;
export const MedicBasicDataFragmentDoc = gql`
    fragment MedicBasicData on Medic {
  id
  userId
  role
  name
  surname
  fullName
  registerNumber
  type
  contacts {
    ...EmbedContactData
  }
  freeLancer
  availability {
    ...AvailabilityData
  }
  specializations {
    ...SpecializationData
  }
  user {
    ...UserData
  }
  registerDistrict {
    ...CityData
  }
  address {
    ...EmbedAddressData
  }
  activeLicense {
    id
    expiresAt
    code
  }
}
    ${EmbedContactDataFragmentDoc}
${AvailabilityDataFragmentDoc}
${SpecializationDataFragmentDoc}
${UserDataFragmentDoc}
${CityDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const OperatorBasicDataFragmentDoc = gql`
    fragment OperatorBasicData on Operator {
  id
  role
  profession
  userId
  name
  surname
  fullName
  availability {
    ...AvailabilityData
  }
  user {
    ...UserData
  }
  contacts {
    ...EmbedContactData
  }
  address {
    ...EmbedAddressData
  }
  activeLicense {
    id
    expiresAt
    code
  }
}
    ${AvailabilityDataFragmentDoc}
${UserDataFragmentDoc}
${EmbedContactDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const OrganizationSaBasicDataFragmentDoc = gql`
    fragment OrganizationSaBasicData on OrganizationSa {
  id
  role
  userId
  isGroupMedicine
  name
  surname
  fullName
  activeLicense {
    id
    expiresAt
    code
  }
}
    `;
export const AdministratorBasicDataFragmentDoc = gql`
    fragment AdministratorBasicData on Administrator {
  id
  role
  userId
  name
  surname
  fullName
}
    `;
export const AttachmentDataFragmentDoc = gql`
    fragment AttachmentData on Attachment {
  id
  url
  mimeType
  name
}
    `;
export const ProfessionalBasicDataFragmentDoc = gql`
    fragment ProfessionalBasicData on Professional {
  ... on Medic {
    ...MedicBasicData
  }
  ... on Operator {
    ...OperatorBasicData
  }
  ... on OrganizationSa {
    ...OrganizationSaBasicData
  }
  ... on Administrator {
    ...AdministratorBasicData
  }
  user {
    ...UserData
  }
  ... on Assistant {
    id
    name
    surname
  }
  privacyPolicy {
    ...AttachmentData
  }
}
    ${MedicBasicDataFragmentDoc}
${OperatorBasicDataFragmentDoc}
${OrganizationSaBasicDataFragmentDoc}
${AdministratorBasicDataFragmentDoc}
${UserDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const AppointmentBasicDataFragmentDoc = gql`
    fragment AppointmentBasicData on Appointment {
  id
  patientProfessionalId
  from
  to
  day
  clinicId
  notes
  professional {
    ...ProfessionalBasicData
  }
  provision {
    id
    name
    provision {
      id
      name
    }
  }
  clinic {
    id
    name
    phone
    clinic {
      id
      name
    }
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export const BreakBasicDataFragmentDoc = gql`
    fragment BreakBasicData on Break {
  id
  day
  from
  to
  clinicId
  notes
}
    `;
export const AgendaEventBasicDataFragmentDoc = gql`
    fragment AgendaEventBasicData on AgendaEvent {
  ... on Appointment {
    ...AppointmentBasicData
  }
  ... on Break {
    ...BreakBasicData
  }
}
    ${AppointmentBasicDataFragmentDoc}
${BreakBasicDataFragmentDoc}`;
export const MedicDataFragmentDoc = gql`
    fragment MedicData on Medic {
  ...MedicBasicData
  provisions {
    ...ProfessionalProvisionData
  }
}
    ${MedicBasicDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}`;
export const OperatorDataFragmentDoc = gql`
    fragment OperatorData on Operator {
  ...OperatorBasicData
  specializations {
    ...SpecializationData
  }
  provisions {
    ...ProfessionalProvisionData
  }
}
    ${OperatorBasicDataFragmentDoc}
${SpecializationDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}`;
export const OrganizationProfessionalPersonalDataFragmentDoc = gql`
    fragment OrganizationProfessionalPersonalData on OrganizationProfessional {
  id
  role
  professional {
    ...ProfessionalBasicData
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export const OrganizationProfessionalsDataFragmentDoc = gql`
    fragment OrganizationProfessionalsData on Organization {
  id
  professionals {
    ...OrganizationProfessionalPersonalData
  }
}
    ${OrganizationProfessionalPersonalDataFragmentDoc}`;
export const OrganizationSaDataFragmentDoc = gql`
    fragment OrganizationSaData on OrganizationSa {
  ...OrganizationSaBasicData
  managedOrganization {
    ...OrganizationProfessionalsData
  }
}
    ${OrganizationSaBasicDataFragmentDoc}
${OrganizationProfessionalsDataFragmentDoc}`;
export const ProfessionalDataFragmentDoc = gql`
    fragment ProfessionalData on Professional {
  user {
    ...UserData
  }
  professionalsSharedWithMe {
    id
    type
    professionalId
    professional {
      id
      fullName
    }
  }
  ... on Medic {
    ...MedicData
    clinics {
      ...ClinicBasicData
    }
  }
  ... on Operator {
    ...OperatorData
    clinics {
      ...ClinicBasicData
    }
  }
  ... on OrganizationSa {
    ...OrganizationSaData
    clinics {
      ...ClinicBasicData
    }
  }
  ... on Administrator {
    ...AdministratorBasicData
  }
  ... on Assistant {
    id
    name
    surname
  }
  privacyPolicy {
    ...AttachmentData
  }
}
    ${UserDataFragmentDoc}
${MedicDataFragmentDoc}
${ClinicBasicDataFragmentDoc}
${OperatorDataFragmentDoc}
${OrganizationSaDataFragmentDoc}
${AdministratorBasicDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const ClinicDataFragmentDoc = gql`
    fragment ClinicData on Clinic {
  id
  name
  phone
  rooms {
    ...ClinicRoomData
  }
  address {
    ...EmbedAddressData
  }
}
    ${ClinicRoomDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const PatientProfessionalTransferDataFragmentDoc = gql`
    fragment PatientProfessionalTransferData on PatientProfessionalTransfer {
  id
  acceptedAt
  targetId
  patientProfessionalId
  target {
    ...ProfessionalBasicData
  }
}
    ${ProfessionalBasicDataFragmentDoc}`;
export const PatientProfessionalDataFragmentDoc = gql`
    fragment PatientProfessionalData on PatientProfessional {
  id
  status
  professionalId
  professional {
    ...ProfessionalBasicData
  }
  professionalTransfers {
    ...PatientProfessionalTransferData
  }
}
    ${ProfessionalBasicDataFragmentDoc}
${PatientProfessionalTransferDataFragmentDoc}`;
export const AvailsBreakDataFragmentDoc = gql`
    fragment AvailsBreakData on AvailsBreak {
  ...AvailsBreakBasicData
  clinicRoom {
    ...ClinicRoomData
    clinic {
      id
      name
    }
  }
}
${AvailsBreakBasicDataFragmentDoc}
${AvailsAppointmentBasicDataFragmentDoc}
${ClinicRoomDataFragmentDoc}`;
export const AvailsAppointmentDataFragmentDoc = gql`
    fragment AvailsAppointmentData on AvailsAppointment {
  ...AvailsAppointmentBasicData
  patient_professional {
    ...PatientProfessionalData
  }
  professional_provision {
    ...ProfessionalProvisionData
  }
  instance {
    ...AvailsInstanceBasicData
  }
  professional {
    ...ProfessionalData
  }
  clinicRoom {
    ...ClinicRoomData
    clinic {
      id
      name
    }
  }
}
${AvailsAppointmentBasicDataFragmentDoc}
${AvailsInstanceBasicDataFragmentDoc}
${PatientProfessionalDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ProfessionalDataFragmentDoc}
${ClinicRoomDataFragmentDoc}
`;
export const AppointmentDataFragmentDoc = gql`
    fragment AppointmentData on Appointment {
  ...AppointmentBasicData
  professional {
    ...ProfessionalData
  }
  provision {
    ...ProfessionalProvisionData   }
  clinic {
    id
    name
    phone
    clinic {
      ...ClinicData
    }
  }
  notes
  creator {
    ...UserData
  }
}
    ${AppointmentBasicDataFragmentDoc}
${ProfessionalDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${ClinicDataFragmentDoc}
${UserDataFragmentDoc}`;
export const BreakDataFragmentDoc = gql`
    fragment BreakData on Break {
  ...BreakBasicData
  clinic {
    id
    name
    phone
  }
  notes
  creator {
    ...UserData
  }
}
    ${BreakBasicDataFragmentDoc}
${UserDataFragmentDoc}`;
export const AgendaEventDataFragmentDoc = gql`
    fragment AgendaEventData on AgendaEvent {
  ... on Appointment {
    ...AppointmentData
  }
  ... on Break {
    ...BreakData
  }
}
    ${AvailsAppointmentDataFragmentDoc}
${BreakDataFragmentDoc}`;
export const AvailsRenderInDayDataFragmentDoc = gql`
    fragment AvailsRenderInDayData on AvailsRenderInDay{
  id
  title
  created_at
  updated_at
  date_from
  date_to
  cycle_week
  cycle_day
  res_open_in
  hour_from
  hour_to
  visibility
  status
  assistants_ids
  exclude_provisions
  max_informer_number
  clinic_room {
    ...ClinicRoomData
    clinic {
      id
      name
    }
  }
  instances{
    ...AvailsInstanceWithEventsData
    appointments {
      ...AvailsAppointmentData
    }
    breaks {
      ...AvailsBreakData
    }
  }
}
${AvailsAppointmentDataFragmentDoc}
${AvailsBreakDataFragmentDoc}
${AvailsInstanceWithEventsDataFragmentDoc}
${ClinicRoomDataFragmentDoc}
${UserDataFragmentDoc}
${AttachmentDataFragmentDoc}
`;
export const ClinicRoomFromDayDataFragmentDoc = gql`
    fragment ClinicRoomFromDayData on ClinicRoomFromDay {
  professionalId
  professional {
    id
    fullName
    role
    privacyPolicy {
      ...AttachmentData
    }
    professionalsSharedWithMe {
      id
      professional {
        id
        fullName
      }
      type
    }
  }
  room {
    id
    name
    phone
    meta
    clinicId
    provisions {
      ...ProfessionalProvisionData
    }
    clinic {
      id
      name
      phone
      address {
        ...EmbedAddressData
      }
    }
  }
  fromDay
}
    ${AttachmentDataFragmentDoc}
${ProfessionalProvisionDataFragmentDoc}
${EmbedAddressDataFragmentDoc}`;
export const ClinicRoomFromDayAvailsDataFragmentDoc = gql`
    fragment ClinicRoomFromDayAvailsData on ClinicRoomFromDayAvails {
  room {
    id
    createdAt
    updatedAt
    name
    phone
    meta
    clinicId
    provisions {
      ...ProfessionalProvisionData
    }
    clinic {
      id
      createdAt
      updatedAt
      name
      phone
      address {
        ...EmbedAddressData
      }
      professional {
        id
        name
        surname
        user {
          ...UserData
        }
        privacyPolicy {
          ...AttachmentData
        }
      }
    }
  }
  professional {
    id
    fullName
    role
    privacyPolicy {
      ...AttachmentData
    }
    professionalsSharedWithMe {
      id
      professional {
        id
        fullName
      }
      type
    }
  }
  fromDay
  firstAvailableRender {
    ...AvailsRenderInDayData
  }
}
${ProfessionalProvisionDataFragmentDoc}
${EmbedAddressDataFragmentDoc}
${UserDataFragmentDoc}
${AttachmentDataFragmentDoc}
${AvailsRenderInDayDataFragmentDoc}`;
export const PaginationInfoDataFragmentDoc = gql`
    fragment PaginationInfoData on PaginationInfo {
  hasMore
  total
}
    `;
export const InvoiceOwnerDataFragmentDoc = gql`
    fragment InvoiceOwnerData on InvoiceOwner {
  role
  name
  surname
  type
}
    `;
export const InvoiceBasicDataFragmentDoc = gql`
    fragment InvoiceBasicData on Invoice {
  bollo
  createdAt
  date
  footer
  header
  id
  invoiceType
  noTs
  number
  patientId
  payedAt
  payedWith
  paymentInfo
  professionalId
  recipientAddress
  recipientAnag
  recipientLocation
  recipientTaxId
  serie
  taxId
  ts
  updatedAt
  vatId
  vatPercent
  withholdingTax
  year
  vatCode
  invoiceOwner {
    ...InvoiceOwnerData
  }
}
    ${InvoiceOwnerDataFragmentDoc}`;
export const InvoiceRowDataFragmentDoc = gql`
    fragment InvoiceRowData on InvoiceRow {
  amount
  description
  id
  invoiceId
  isNote
  sequence
  updatedAt
}
    `;
export const InvoiceDataFragmentDoc = gql`
    fragment InvoiceData on Invoice {
  ...InvoiceBasicData
  invoiceRows {
    ...InvoiceRowData
  }
}
    ${InvoiceBasicDataFragmentDoc}
${InvoiceRowDataFragmentDoc}`;
export const InvoiceVatCodeDataFragmentDoc = gql`
    fragment InvoiceVatCodeData on InvoiceVatCode {
  description
  id
  name
  percentage
}
    `;
export const InvoicePaymentTypeDataFragmentDoc = gql`
    fragment InvoicePaymentTypeData on InvoicePaymentType {
  description
  id
  name
}
    `;
export const InvoiceOptionsDataFragmentDoc = gql`
    fragment InvoiceOptionsData on InvoiceOptions {
  defaultInvoiceType
  defaultPaymentInfo
  defaultSerie
  defaultVatCode
  professionalId
  taxId
  vatId
}
    `;
export const NotificationDataFragmentDoc = gql`
    fragment NotificationData on Notification {
  id
  userId
  readAt
  createdAt
  data
  name
  destinationProfile
}
    `;
export const ProfessionalSharesBasicDataFragmentDoc = gql`
    fragment ProfessionalSharesBasicData on ProfessionalShares {
  id
  professional {
    ...ProfessionalBasicData
  }
  sharedWith {
    ...ProfessionalBasicData
  }
  managedOrganization {
    ...OrganizationProfessionalsData
  }
  type
  acceptedAt
}
    ${ProfessionalBasicDataFragmentDoc}
${OrganizationProfessionalsDataFragmentDoc}`;
export const ProfessionalSharesDataFragmentDoc = gql`
    fragment ProfessionalSharesData on ProfessionalShares {
  ...ProfessionalSharesBasicData
  parent {
    ...ProfessionalSharesBasicData
  }
}
    ${ProfessionalSharesBasicDataFragmentDoc}`;
export const SharedPatientProfessionalDataFragmentDoc = gql`
    fragment SharedPatientProfessionalData on SharedPatientProfessional {
  id
  professionalId
  professionalShare {
    ...ProfessionalSharesData
  }
  professionalShareId
}
    ${ProfessionalSharesDataFragmentDoc}`;
export const PatientBasicDataFragmentDoc = gql`
    fragment PatientBasicData on Patient {
  id
  birthDay
  createdAt
  createdBy
  name
  personalMedicId
  surname
  taxId
  updatedAt
}
    `;
export const PatientUserDataFragmentDoc = gql`
    fragment PatientUserData on PatientUser {
  id
  userId
  type
  patientId
  patient {
    id
    name
    surname
  }
  user {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export const PersonalMedicTransferDataFragmentDoc = gql`
    fragment PersonalMedicTransferData on PersonalMedicTransfer {
  id
  acceptedAt
  patientId
  target {
    id
    fullName
    name
    surname
    user {
      ...UserData
    }
  }
  groupMedicineId
}
    ${UserDataFragmentDoc}`;
export const PatientDataFragmentDoc = gql`
    fragment PatientData on Patient {
  ...PatientBasicData
  address {
    ...EmbedAddressData
  }
  users {
    ...PatientUserData
  }
  personalMedic {
    ...ProfessionalBasicData
  }
  personalMedicTransfers {
    ...PersonalMedicTransferData
  }
  appointments{
    ...AvailsAppointmentData
  }
}
    ${PatientBasicDataFragmentDoc}
${EmbedAddressDataFragmentDoc}
${PatientUserDataFragmentDoc}
${ProfessionalBasicDataFragmentDoc}
${AvailsAppointmentDataFragmentDoc}
${PersonalMedicTransferDataFragmentDoc}`;
export const PlatformAlertDataFragmentDoc = gql`
    fragment PlatformAlertData on PlatformAlert {
  id
  severity
  target
  title
  message
  from
  to
}
    `;
export const ProvisionPaginatedDataFragmentDoc = gql`
    fragment ProvisionPaginatedData on ProvisionPaginatedResponse {
  items {
    ...ProvisionData
  }
  paginationInfo {
    hasMore
    total
  }
}
    ${ProvisionDataFragmentDoc}`;
export const TimeSlotDataFragmentDoc = gql`
    fragment TimeSlotData on TimeSlot {
  day
  slots
}
    `;
export const CupProviderDataFragmentDoc = gql`
    fragment CupProviderData on CupProvider {
  id
  date
  duration
  price
  address
  addressName
  name
  url
  trackUrl
}
    `;
export const FrequentProvisionDataFragmentDoc = gql`
    fragment FrequentProvisionData on FrequentProvision {
  id
  isCup
  name
}
    `;
export const ReportBasicDataFragmentDoc = gql`
    fragment ReportBasicData on Report {
  id
  title
  description
  createdBy
  reportDate
  createdAt
  medicName
  professionalId
  meta
  notes
  attachments {
    ...AttachmentData
  }
}
    ${AttachmentDataFragmentDoc}`;
export const ReportSharedWithDataFragmentDoc = gql`
    fragment ReportSharedWithData on ReportSharedWith {
  accepted
  reportId
  patientProfessionalId
  patientProfessional {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export const ReportDataFragmentDoc = gql`
    fragment ReportData on Report {
  ...ReportBasicData
  professional {
    ...ProfessionalBasicData
  }
  shares {
    ...ReportSharedWithData
  }
}
    ${ReportBasicDataFragmentDoc}
${ProfessionalBasicDataFragmentDoc}
${ReportSharedWithDataFragmentDoc}`;
export const RequestAuthorDataFragmentDoc = gql`
    fragment RequestAuthorData on RequestAuthor {
  ... on Patient {
    id
    name
    surname
    taxId
  }
  ... on Medic {
    id
    role
    name
    surname
    user {
      ...UserData
    }
  }
  ... on Operator {
    id
    name
    surname
    role
    user {
      ...UserData
    }
  }
  ... on OrganizationSa {
    id
    name
    surname
    role
  }
}
    ${UserDataFragmentDoc}`;
export const RequestMessageDataFragmentDoc = gql`
    fragment RequestMessageData on RequestMessage {
  id
  message
  readAt
  creator {
    ...UserData
  }
  author {
    ...RequestAuthorData
  }
  attachments {
    ...AttachmentData
  }
  createdAt
}
    ${UserDataFragmentDoc}
${RequestAuthorDataFragmentDoc}
${AttachmentDataFragmentDoc}`;
export const RequestDataFragmentDoc = gql`
    fragment RequestData on Request {
  id
  title
  type
  result
  private
  createdAt
  messages {
    ...RequestMessageData
  }
  recipient {
    ...RequestAuthorData
  }
  sender {
    ...RequestAuthorData
  }
}
    ${RequestMessageDataFragmentDoc}
${RequestAuthorDataFragmentDoc}`;
export const UserEmailAddressDataFragmentDoc = gql`
    fragment UserEmailAddressData on UserEmailAddress {
  id
  value
  verified
  via
  status
}
    `;
export const CreateAvailsAppointmentAsPatientDocument = gql`
    mutation CreateAvailsAppointmentAsPatient($renderingId: String, $appointment: CreateAvailsAppointmentInput!, $patientId: String!, $medicId: String!) {
  createAvailsAppointmentAsPatient(
    renderingId: $renderingId
    appointment: $appointment
    patientId: $patientId
    medicId: $medicId
  ) {
    ...AvailsAppointmentData
  }
}
    ${AvailsAppointmentDataFragmentDoc}`;
export type CreateAvailsAppointmentAsPatientMutationResult = ApolloReactCommon.MutationResult<CreateAvailsAppointmentAsPatientMutation>;
export type CreateAvailsAppointmentAsPatientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAvailsAppointmentAsPatientMutation, CreateAvailsAppointmentAsPatientMutationVariables>;
export const BookProvisionDocument = gql`
    mutation BookProvision($appointment: CreateAppointmentInput!, $patientId: String!, $medicId: String!) {
  createAppointmentAsPatient(
    appointment: $appointment
    patientId: $patientId
    medicId: $medicId
  ) {
    ...AppointmentData
  }
}
    ${AvailsAppointmentDataFragmentDoc}`;
export type BookProvisionMutationResult = ApolloReactCommon.MutationResult<BookProvisionMutation>;
export type BookProvisionMutationOptions = ApolloReactCommon.BaseMutationOptions<BookProvisionMutation, BookProvisionMutationVariables>;
export const DeleteAvailsAppointmentDocument = gql`
    mutation DeleteAvailsAppointment($id: String!, $patientId: String!) {
  deleteAvailsAppointmentAsPatient(id: $id, patientId: $patientId)
}
    `;
export type DeleteAppointmentMutationResult = ApolloReactCommon.MutationResult<DeleteAvailsAppointmentMutationVariables>;
export type DeleteAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAvailsAppointmentMutation, DeleteAvailsAppointmentMutationVariables>;
export const ChatAccessTokenDocument = gql`
    mutation ChatAccessToken {
  getChatAccessToken
}
    `;
export type ChatAccessTokenMutationResult = ApolloReactCommon.MutationResult<ChatAccessTokenMutation>;
export type ChatAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<ChatAccessTokenMutation, ChatAccessTokenMutationVariables>;
export const StartChatDocument = gql`
    mutation StartChat($userId: ID!) {
  createChat(userId: $userId)
}
    `;
export type StartChatMutationResult = ApolloReactCommon.MutationResult<StartChatMutation>;
export type StartChatMutationOptions = ApolloReactCommon.BaseMutationOptions<StartChatMutation, StartChatMutationVariables>;
export const CreatePatientProfessionalDocument = gql`
    mutation CreatePatientProfessional($professionalId: String!, $patientId: String!) {
  createPatientProfessionalAsPatient(
    patientId: $patientId
    professionalId: $professionalId
  ) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type CreatePatientProfessionalMutationResult = ApolloReactCommon.MutationResult<CreatePatientProfessionalMutation>;
export type CreatePatientProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatientProfessionalMutation, CreatePatientProfessionalMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($id: String!) {
  markNotificationAsRead(id: $id) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type ReadNotificationMutationResult = ApolloReactCommon.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ReadManyNotificationsDocument = gql`
    mutation ReadManyNotifications($ids: [String!]!) {
  markManyNotificationsAsRead(ids: $ids) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type ReadManyNotificationsMutationResult = ApolloReactCommon.MutationResult<ReadManyNotificationsMutation>;
export type ReadManyNotificationsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadManyNotificationsMutation, ReadManyNotificationsMutationVariables>;
export const RemovePersonalMedicDocument = gql`
    mutation RemovePersonalMedic($patientId: String!) {
  removePersonalMedic(patientId: $patientId)
}
    `;
export type RemovePersonalMedicMutationResult = ApolloReactCommon.MutationResult<RemovePersonalMedicMutation>;
export type RemovePersonalMedicMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePersonalMedicMutation, RemovePersonalMedicMutationVariables>;
export const AcceptPatientProfessionalTransferDocument = gql`
    mutation AcceptPatientProfessionalTransfer($id: String!) {
  acceptPatientProfessionalTransfer(id: $id) {
    ...PatientProfessionalTransferData
  }
}
    ${PatientProfessionalTransferDataFragmentDoc}`;
export type AcceptPatientProfessionalTransferMutationResult = ApolloReactCommon.MutationResult<AcceptPatientProfessionalTransferMutation>;
export type AcceptPatientProfessionalTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptPatientProfessionalTransferMutation, AcceptPatientProfessionalTransferMutationVariables>;
export const DeletePersonalMedicTransferDocument = gql`
    mutation DeletePersonalMedicTransfer($id: String!) {
  deletePersonalMedicTransfer(id: $id)
}
    `;
export type DeletePersonalMedicTransferMutationResult = ApolloReactCommon.MutationResult<DeletePersonalMedicTransferMutation>;
export type DeletePersonalMedicTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePersonalMedicTransferMutation, DeletePersonalMedicTransferMutationVariables>;
export const RequestPersonalMedicDocument = gql`
    mutation RequestPersonalMedic($patientId: String!, $medicId: String!, $groupMedicineId: String) {
  requestPersonalMedic(
    patientId: $patientId
    medicId: $medicId
    groupMedicineId: $groupMedicineId
  ) {
    ...PersonalMedicTransferData
  }
}
    ${PersonalMedicTransferDataFragmentDoc}`;
export type RequestPersonalMedicMutationResult = ApolloReactCommon.MutationResult<RequestPersonalMedicMutation>;
export type RequestPersonalMedicMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPersonalMedicMutation, RequestPersonalMedicMutationVariables>;
export const DenyPatientProfessionalTransferDocument = gql`
    mutation DenyPatientProfessionalTransfer($id: String!) {
  denyPatientProfessionalTransfer(id: $id)
}
    `;
export type DenyPatientProfessionalTransferMutationResult = ApolloReactCommon.MutationResult<DenyPatientProfessionalTransferMutation>;
export type DenyPatientProfessionalTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<DenyPatientProfessionalTransferMutation, DenyPatientProfessionalTransferMutationVariables>;
export const UpdatePatientProfessionalStatusDocument = gql`
    mutation UpdatePatientProfessionalStatus($id: String!, $patientId: String!, $status: UpdatablePatientProfessionalStatus!) {
  updatePatientProfessionalStatus(id: $id, patientId: $patientId, status: $status) {
    id
    status
  }
}
    `;
export type UpdatePatientProfessionalStatusMutationResult = ApolloReactCommon.MutationResult<UpdatePatientProfessionalStatusMutation>;
export type UpdatePatientProfessionalStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientProfessionalStatusMutation, UpdatePatientProfessionalStatusMutationVariables>;
export const ArchivePatientProfessionalDocument = gql`
    mutation ArchivePatientProfessional($id: String!) {
  archivePatientProfessional(id: $id) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type ArchivePatientProfessionalMutationResult = ApolloReactCommon.MutationResult<ArchivePatientProfessionalMutation>;
export type ArchivePatientProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchivePatientProfessionalMutation, ArchivePatientProfessionalMutationVariables>;
export const CreateUserPatientDocument = gql`
    mutation CreateUserPatient($patientId: String, $patient: CreateUserPatientInput, $termsAndConditions: Boolean!, $type: PatientType) {
  createUserPatient(
    patientId: $patientId
    patient: $patient
    termsAndConditions: $termsAndConditions
    type: $type
  ) {
    ...PatientUserData
  }
}
    ${PatientUserDataFragmentDoc}`;
export type CreateUserPatientMutationResult = ApolloReactCommon.MutationResult<CreateUserPatientMutation>;
export type CreateUserPatientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserPatientMutation, CreateUserPatientMutationVariables>;
export const DeleteUserPatientDocument = gql`
    mutation DeleteUserPatient($id: String!) {
  deleteUserPatient(id: $id)
}
    `;
export type DeleteUserPatientMutationResult = ApolloReactCommon.MutationResult<DeleteUserPatientMutation>;
export type DeleteUserPatientMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserPatientMutation, DeleteUserPatientMutationVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($patientProfessionalId: String, $report: CreateReportInput!, $patientId: String!) {
  createReportAsPatient(
    patientProfessionalId: $patientProfessionalId
    report: $report
    patientId: $patientId
  ) {
    ...ReportData
  }
}
    ${ReportDataFragmentDoc}`;
export type CreateReportMutationResult = ApolloReactCommon.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const ShareReportDocument = gql`
    mutation ShareReport($patientProfessionalId: String!, $id: String!) {
  shareReport(patientProfessionalId: $patientProfessionalId, id: $id) {
    ...PatientProfessionalData
  }
}
    ${PatientProfessionalDataFragmentDoc}`;
export type ShareReportMutationResult = ApolloReactCommon.MutationResult<ShareReportMutation>;
export type ShareReportMutationOptions = ApolloReactCommon.BaseMutationOptions<ShareReportMutation, ShareReportMutationVariables>;
export const RevokeReportShareDocument = gql`
    mutation RevokeReportShare($patientProfessionalId: String!, $id: String!) {
  revokeReportShare(patientProfessionalId: $patientProfessionalId, id: $id)
}
    `;
export type RevokeReportShareMutationResult = ApolloReactCommon.MutationResult<RevokeReportShareMutation>;
export type RevokeReportShareMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeReportShareMutation, RevokeReportShareMutationVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($id: String!) {
  deleteReport(id: $id)
}
    `;
export type DeleteReportMutationResult = ApolloReactCommon.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const CreateRequestDocument = gql`
    mutation CreateRequest($request: CreateRequestInput!, $patientId: String!) {
  createRequestAsPatient(request: $request, patientId: $patientId) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type CreateRequestMutationResult = ApolloReactCommon.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const DeleteRequestDocument = gql`
    mutation DeleteRequest($id: String!) {
  deleteRequest(id: $id)
}
    `;
export type DeleteRequestMutationResult = ApolloReactCommon.MutationResult<DeleteRequestMutation>;
export type DeleteRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const SearchCityDocument = gql`
    query SearchCity($name: String!) {
  cities(filter: {name: {like: $name}}, limit: 100) {
    items {
      ...CityData
    }
  }
}
    ${CityDataFragmentDoc}`;
export type SearchCityQueryResult = ApolloReactCommon.QueryResult<SearchCityQuery, SearchCityQueryVariables>;
export const SearchDistrictDocument = gql`
    query SearchDistrict($name: String!) {
  cities(filter: {name: {like: $name}, isDistrict: {eq: true}}, limit: 100) {
    items {
      ...CityData
    }
  }
}
    ${CityDataFragmentDoc}`;
export type SearchDistrictQueryResult = ApolloReactCommon.QueryResult<SearchDistrictQuery, SearchDistrictQueryVariables>;
export const GetCityDocument = gql`
    query GetCity($id: ID!) {
  city(id: $id) {
    ...CityData
  }
}
    ${CityDataFragmentDoc}`;
export type GetCityQueryResult = ApolloReactCommon.QueryResult<GetCityQuery, GetCityQueryVariables>;
export const AvailsAppointmentDocument = gql`
    query Appointment($id: ID!) {
  availsAppointment(id: $id) {
    ...AvailsAppointmentData
  }
}
    ${AvailsAppointmentDataFragmentDoc}`;

export type AppointmentQueryResult = ApolloReactCommon.QueryResult<AvailsAppointmentQuery, AvailsAppointmentQueryVariables>;
export const PastAvailsAppointmentsDocument = gql`
    query PastAvailsAppointments($patientId: String!, $skip: Int, $limit: Int, $order: AvailsAppointmentOrderInput) {
  pastAvailsAppointmentsByPatient(
    patientId: $patientId
    skip: $skip
    limit: $limit
    order: $order
  ) {
    items {
      ...AvailsAppointmentData
    }
    paginationInfo {
      hasMore
      total
    }
  }
}
    ${AvailsAppointmentDataFragmentDoc}`;
export type PastAppointmentsQueryResult = ApolloReactCommon.QueryResult<PastAvailsAppointmentsQuery, PastAvailsAppointmentsQueryVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoami {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;
export type WhoAmIQueryResult = ApolloReactCommon.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const ClinicsDocument = gql`
    query Clinics($medicId: ID!) {
  clinics(filter: {professional: {id: {eq: $medicId}}}) {
    ...ClinicBasicData
  }
}
    ${ClinicBasicDataFragmentDoc}`;
export type ClinicsQueryResult = ApolloReactCommon.QueryResult<ClinicsQuery, ClinicsQueryVariables>;
export const InvoiceDocument = gql`
    query Invoice($id: Int!) {
  getInvoice(invoiceId: $id) {
    ...InvoiceData
  }
}
    ${InvoiceDataFragmentDoc}`;
export type InvoiceQueryResult = ApolloReactCommon.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const InvoiceOptionsDocument = gql`
    query InvoiceOptions($medicId: Int!) {
  getInvoiceOptions(professionalId: $medicId) {
    ...InvoiceOptionsData
  }
}
    ${InvoiceOptionsDataFragmentDoc}`;
export type InvoiceOptionsQueryResult = ApolloReactCommon.QueryResult<InvoiceOptionsQuery, InvoiceOptionsQueryVariables>;
export const InvoiceVatCodesDocument = gql`
    query InvoiceVatCodes {
  getInvoiceVatCodes {
    ...InvoiceVatCodeData
  }
}
    ${InvoiceVatCodeDataFragmentDoc}`;
export type InvoiceVatCodesQueryResult = ApolloReactCommon.QueryResult<InvoiceVatCodesQuery, InvoiceVatCodesQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($patientId: Int!, $year: Int, $first: Int, $page: Int) {
  getInvoicesByPatient(
    patient_id: $patientId
    year: $year
    first: $first
    page: $page
    sortBy: [{field: YEAR, order: DESC}]
  ) {
    data {
      ...InvoiceData
    }
    paginatorInfo {
      hasMorePages
      total
    }
  }
}
    ${InvoiceDataFragmentDoc}`;
export type InvoicesQueryResult = ApolloReactCommon.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const InvoicePaymentTypesDocument = gql`
    query InvoicePaymentTypes {
  getInvoicePaymentTypes {
    ...InvoicePaymentTypeData
  }
}
    ${InvoicePaymentTypeDataFragmentDoc}`;
export type InvoicePaymentTypesQueryResult = ApolloReactCommon.QueryResult<InvoicePaymentTypesQuery, InvoicePaymentTypesQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($filters: NotificationFilterInput, $skip: Int = 0, $limit: Int = 25, $read: Boolean = null, $profileType: String) {
  getNotifications(
    skip: $skip
    limit: $limit
    filter: $filters
    read: $read
    profileType: $profileType
    order: {createdAt: DESC}
  ) {
    items {
      ...NotificationData
    }
    paginationInfo {
      ...PaginationInfoData
    }
    totalUnReadNotification
  }
}
    ${NotificationDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type GetNotificationsQueryResult = ApolloReactCommon.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($filters: NotificationFilterInput, $skip: Int = 0, $limit: Int = 25) {
  notifications(
    skip: $skip
    limit: $limit
    filter: $filters
    order: {createdAt: DESC}
  ) {
    items {
      ...NotificationData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${NotificationDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const NotificationDocument = gql`
    query Notification($id: ID!) {
  notification(id: $id) {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type NotificationQueryResult = ApolloReactCommon.QueryResult<NotificationQuery, NotificationQueryVariables>;
export const PatientsDocument = gql`
    query Patients {
  patientProfiles {
    ...PatientUserData
  }
}
    ${PatientUserDataFragmentDoc}`;
export type PatientsQueryResult = ApolloReactCommon.QueryResult<PatientQuery, PatientQueryVariables>;
export const PatientDocument = gql`
    query Patient($id: ID!) {
  patient(id: $id) {
    ...PatientData
  }
}
    ${PatientDataFragmentDoc}`;
export type PatientQueryResult = ApolloReactCommon.QueryResult<PatientQuery, PatientQueryVariables>;
export const SearchPatientsDocument = gql`
    query SearchPatients($filter: PatientFilterInput) {
  patients(filter: $filter) {
    items {
      ...PatientData
    }
  }
}
    ${PatientDataFragmentDoc}`;
export type SearchPatientsQueryResult = ApolloReactCommon.QueryResult<SearchPatientsQuery, SearchPatientsQueryVariables>;
export const PatientToAssociateDocument = gql`
    query PatientToAssociate {
  patientToAssociate {
    ...PatientData
  }
}
    ${PatientDataFragmentDoc}`;
export type PatientToAssociateQueryResult = ApolloReactCommon.QueryResult<PatientToAssociateQuery, PatientToAssociateQueryVariables>;
export const PatientProfessionalsDocument = gql`
    query PatientProfessionals($patientId: String!) {
  patientProfessionalsAsPatient(patientId: $patientId) {
    ... on PatientProfessional {
      ...PatientProfessionalData
    }
    ... on SharedPatientProfessional {
      ...SharedPatientProfessionalData
    }
  }
}
    ${PatientProfessionalDataFragmentDoc}
${SharedPatientProfessionalDataFragmentDoc}`;
export type PatientProfessionalsQueryResult = ApolloReactCommon.QueryResult<PatientProfessionalsQuery, PatientProfessionalsQueryVariables>;
export const PlatformAlertsDocument = gql`
    query PlatformAlerts($day: DateTime!) {
  platformAlerts(
    filter: {target: {eq: PATIENT}, from: {lte: $day}, to: {gte: $day}}
    order: {from: ASC}
  ) {
    ...PlatformAlertData
  }
}
    ${PlatformAlertDataFragmentDoc}`;
export type PlatformAlertsQueryResult = ApolloReactCommon.QueryResult<PlatformAlertsQuery, PlatformAlertsQueryVariables>;
export const ProfessionalDocument = gql`
    query Professional($id: ID!) {
  professional(id: $id) {
    ...ProfessionalData
  }
}
    ${ProfessionalDataFragmentDoc}`;
export type ProfessionalQueryResult = ApolloReactCommon.QueryResult<ProfessionalQuery, ProfessionalQueryVariables>;
export const ProfessionalsDocument = gql`
    query Professionals($filter: ProfessionalFilterInput, $limit: Int = 25, $skip: Int = 0) {
  professionals(limit: $limit, skip: $skip, filter: $filter) {
    items {
      ...ProfessionalBasicData
      professionalsSharedWithMe {
        id
        type
        professionalId
        professional {
          id
          fullName
        }
      }
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${ProfessionalBasicDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type ProfessionalsQueryResult = ApolloReactCommon.QueryResult<ProfessionalsQuery, ProfessionalsQueryVariables>;
export const PatientProfessionalDocument = gql`
    query PatientProfessional($id: ID!) {
  patientProfessional(id: $id) {
    ...PatientProfessionalData
    sharedReports {
      ...ReportSharedWithData
      report {
        ...ReportBasicData
      }
    }
  }
}
    ${PatientProfessionalDataFragmentDoc}
${ReportSharedWithDataFragmentDoc}
${ReportBasicDataFragmentDoc}`;
export type PatientProfessionalQueryResult = ApolloReactCommon.QueryResult<PatientProfessionalQuery, PatientProfessionalQueryVariables>;
export const DefaultProvisionsDocument = gql`
    query DefaultProvisions {
  defaultProvisions {
    ...ProvisionData
  }
}
    ${ProvisionDataFragmentDoc}`;
export type DefaultProvisionsQueryResult = ApolloReactCommon.QueryResult<DefaultProvisionsQuery, DefaultProvisionsQueryVariables>;
export const ProvisionsDocument = gql`
    query Provisions($filter: ProvisionFilterInput) {
  provisions(filter: $filter) {
    ...ProvisionPaginatedData
  }
}
    ${ProvisionPaginatedDataFragmentDoc}`;
export type ProvisionsQueryResult = ApolloReactCommon.QueryResult<ProvisionsQuery, ProvisionsQueryVariables>;
export const ClinicRoomByProvisionDocument = gql`
    query ClinicRoomByProvision($distance: Int, $coordinates: LatLngInput, $medicId: String, $from: Date!, $patientId: String!, $provisionId: String!) {
  clinicRoomByProvision(
    distance: $distance
    coordinates: $coordinates
    medicId: $medicId
    from: $from
    patientId: $patientId
    provisionId: $provisionId
  ) {
    ...ClinicRoomFromDayData
  }
}
    ${ClinicRoomFromDayDataFragmentDoc}`;
export type ClinicRoomByProvisionQueryResult = ApolloReactCommon.QueryResult<ClinicRoomByProvisionQuery, ClinicRoomByProvisionQueryVariables>;
export const ClinicRoomByProvisionAvailsDocument = gql`
    query ClinicRoomByProvisionAvails($distance: Int, $coordinates: LatLngInput, $medicId: String, $from: Date!, $patientId: String!, $provisionId: String!) {
  clinicRoomByProvisionAvails(
    distance: $distance
    coordinates: $coordinates
    medicId: $medicId
    from: $from
    patientId: $patientId
    provisionId: $provisionId
  ) {
    ...ClinicRoomFromDayAvailsData
  }
}
    ${ClinicRoomFromDayAvailsDataFragmentDoc}`;
export type ClinicRoomByProvisionAvailsQueryResult = ApolloReactCommon.QueryResult<ClinicRoomByProvisionAvailsQuery, ClinicRoomByProvisionAvailsQueryVariables>;
export const CupProvidersDocument = gql`
    query CupProviders($coordinates: LatLngInput!, $provisionId: String!, $isCup: Boolean) {
  cupProviders(
    coordinates: $coordinates
    provisionId: $provisionId
    isCup: $isCup
  ) {
    ...CupProviderData
  }
}
    ${CupProviderDataFragmentDoc}`;
export type CupProvidersQueryResult = ApolloReactCommon.QueryResult<CupProvidersQuery, CupProvidersQueryVariables>;
export const TimeSlotsDocument = gql`
    query TimeSlots($patientId: String!, $clinicIds: [String!], $to: Date!, $from: Date!, $provisionId: String!) {
  timeslotsAsPatient(
    patientId: $patientId
    clinicIds: $clinicIds
    to: $to
    from: $from
    provisionId: $provisionId
  ) {
    ...TimeSlotData
  }
}
    ${TimeSlotDataFragmentDoc}`;
export type TimeSlotsQueryResult = ApolloReactCommon.QueryResult<TimeSlotsQuery, TimeSlotsQueryVariables>;

export const SlotTypeDataFragmentDoc = gql`
    fragment SlotTypeData on SlotType{
  slots
  render{
    ...AvailsRenderInDayData
  }
}
${AvailsRenderInDayDataFragmentDoc}
`;

export const TimeSlotsAvailsAsPatientDocument = gql`
    query TimeSlotsAvailsAsPatient($clinicIds: [String!], $to: Date!, $from: Date!, $patientId: String!, $provisionId: String!) {
  timeslotsAvailsAsPatient(
    clinicIds: $clinicIds
    to: $to
    from: $from
    patientId: $patientId
    provisionId: $provisionId
  ) {
    day
    slots{
      ...SlotTypeData
    }
  }
}
${SlotTypeDataFragmentDoc}`;
export type TimeSlotsAvailsAsPatientQueryResult = ApolloReactCommon.QueryResult<TimeSlotsAvailsAsPatientQuery, TimeSlotsAvailsAsPatientQueryVariables>;

export const FrequentProvisionsDocument = gql`
    query FrequentProvisions {
  frequentProvisions {
    ...FrequentProvisionData
  }
}
    ${FrequentProvisionDataFragmentDoc}`;
export type FrequentProvisionsQueryResult = ApolloReactCommon.QueryResult<FrequentProvisionsQuery, FrequentProvisionsQueryVariables>;
export const ReportsDocument = gql`
    query Reports($patientId: String!, $skip: Int = 0, $limit: Int = 10, $order: ReportOrderInput) {
  patientReports(patientId: $patientId, skip: $skip, limit: $limit, order: $order) {
    items {
      ...ReportBasicData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${ReportBasicDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type ReportsQueryResult = ApolloReactCommon.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ReportDocument = gql`
    query Report($id: ID!) {
  report(id: $id) {
    ...ReportData
  }
}
    ${ReportDataFragmentDoc}`;
export type ReportQueryResult = ApolloReactCommon.QueryResult<ReportQuery, ReportQueryVariables>;
export const RequestsDocument = gql`
    query Requests($patientId: String!, $skip: Int = 0, $limit: Int = 10) {
  sentRequestsAsPatient(
    patientId: $patientId
    skip: $skip
    limit: $limit
    order: {createdAt: DESC}
  ) {
    items {
      ...RequestData
    }
    paginationInfo {
      ...PaginationInfoData
    }
  }
}
    ${RequestDataFragmentDoc}
${PaginationInfoDataFragmentDoc}`;
export type RequestsQueryResult = ApolloReactCommon.QueryResult<RequestsQuery, RequestsQueryVariables>;
export const RequestDocument = gql`
    query Request($id: ID!) {
  request(id: $id) {
    ...RequestData
  }
}
    ${RequestDataFragmentDoc}`;
export type RequestQueryResult = ApolloReactCommon.QueryResult<RequestQuery, RequestQueryVariables>;
export const OnNewNotificationDocument = gql`
    subscription OnNewNotification {
  onNewNotification {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;
export type OnNewNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnNewNotificationSubscription>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "AgendaEvent",
        "possibleTypes": [
          {
            "name": "Appointment"
          },
          {
            "name": "Break"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BaseEntity",
        "possibleTypes": [
          {
            "name": "Administrator"
          },
          {
            "name": "AgendaEntry"
          },
          {
            "name": "AgendaList"
          },
          {
            "name": "AgendaWeek"
          },
          {
            "name": "Appointment"
          },
          {
            "name": "Assistant"
          },
          {
            "name": "Attachment"
          },
          {
            "name": "Break"
          },
          {
            "name": "City"
          },
          {
            "name": "Clinic"
          },
          {
            "name": "ClinicRoom"
          },
          {
            "name": "Delegation"
          },
          {
            "name": "Invite"
          },
          {
            "name": "License"
          },
          {
            "name": "Medic"
          },
          {
            "name": "Network"
          },
          {
            "name": "Notification"
          },
          {
            "name": "Operator"
          },
          {
            "name": "Organization"
          },
          {
            "name": "OrganizationProfessional"
          },
          {
            "name": "OrganizationSa"
          },
          {
            "name": "Patient"
          },
          {
            "name": "PatientImportLog"
          },
          {
            "name": "PatientImportLogError"
          },
          {
            "name": "PatientProfessional"
          },
          {
            "name": "PatientProfessionalTransfer"
          },
          {
            "name": "PatientUser"
          },
          {
            "name": "PaymentMethod"
          },
          {
            "name": "PersonalMedicTransfer"
          },
          {
            "name": "PlatformAlert"
          },
          {
            "name": "ProfessionalProvision"
          },
          {
            "name": "ProfessionalShares"
          },
          {
            "name": "Provision"
          },
          {
            "name": "Report"
          },
          {
            "name": "ReportSharedWith"
          },
          {
            "name": "Request"
          },
          {
            "name": "RequestMessage"
          },
          {
            "name": "Specialization"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Professional",
        "possibleTypes": [
          {
            "name": "Administrator"
          },
          {
            "name": "Assistant"
          },
          {
            "name": "Medic"
          },
          {
            "name": "Operator"
          },
          {
            "name": "OrganizationSa"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "RequestAuthor",
        "possibleTypes": [
          {
            "name": "Medic"
          },
          {
            "name": "Operator"
          },
          {
            "name": "Patient"
          },
          {
            "name": "OrganizationSa"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchPatientUnion",
        "possibleTypes": [
          {
            "name": "PublicPatient"
          },
          {
            "name": "Patient"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SharedPatientProfessionalUnion",
        "possibleTypes": [
          {
            "name": "SharedPatientProfessional"
          },
          {
            "name": "PatientProfessional"
          }
        ]
      }
    ]
  }
};
      export default result;
    