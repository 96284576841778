import { RoutesKeys, RouteType } from './utils';
import intersection from 'lodash/intersection';
import { Route } from 'react-router-dom';
import { getRouteFactory } from '@hu-care/react-utils';

import { commonRoutes } from './common.routes';
import { profileRoutes } from './profile.routes';
import { appointmentsRoutes } from './appointments.routes';
import { requestsRoutes } from './requests.routes';
import { provisionsRoutes } from './provisions.routes';
import { reportsRoutes } from './reports.routes';
import { invoicesRoutes } from './invoices.routes';

export * from './utils';

export const routes: RouteType[] = [
  ...commonRoutes,
  ...profileRoutes,
  ...appointmentsRoutes,
  ...requestsRoutes,
  ...provisionsRoutes,
  ...reportsRoutes,
  ...invoicesRoutes,
];

export const renderRoutes = (scopes: string[] = []) => (
  routes
  .filter(({ config }) => config.scopes.length ? intersection(config.scopes, scopes).length : true)
  .map(({ component, exact, path }) => (
    <Route key={path} exact={exact} path={path} component={component}/>
  ))
)
const routeByKey = routes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<RoutesKeys, string>());

export const getRoute = getRouteFactory<RoutesKeys>(routeByKey);
