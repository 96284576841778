import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { Loading } from '@hu-care/react-layout';

import {
  PatientDocument,
  PatientQuery,
  PatientQueryVariables,
  PatientsDocument,
  PatientsQuery,
  PatientsQueryVariables,
  PatientType,
  PatientUserDataFragment,
} from '../generated/graphql';
import { getRoute, RoutesKeys } from '../routes';
import { useRedirect } from '../hooks/useRedirect';
import { useWhoAmI } from '../hooks/useWhoAmI';
import { useLocation } from 'react-router';

export const LAST_PROFILE_USED_KEY = '@patient:lastProfileUsed';

export interface PatientContextType {
  selectedPatient: PatientQuery['patient'] | null;
  loading: boolean;
  isImpersonating: boolean;
  setSelectedPatientId: Dispatch<SetStateAction<string | null>>,
  patientProfiles?: PatientUserDataFragment[],
}

const PatientContext = createContext<PatientContextType>({
  selectedPatient: null,
  loading: false,
  isImpersonating: false,
  setSelectedPatientId: () => null,
  patientProfiles: undefined,
});

export const usePatient = () => useContext(PatientContext);

export const PatientProvider: FC = ({ children }) => {
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
  const redirect = useRedirect();
  const { pathname } = useLocation();

  const { user } = useWhoAmI();

  const { data: patientsData, loading: allPLoading } = useQuery<PatientsQuery, PatientsQueryVariables>(PatientsDocument);
  const { data: patientData, error: pError, loading: pLoading } = useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, {
    variables: {
      id: selectedPatientId!,
    },
    errorPolicy: 'all',
    skip: !selectedPatientId,
  });

  const patientProfiles = useMemo(() => {
    return patientsData?.patientProfiles;
  }, [patientsData]);

  useEffect(() => {
    if (pError) {
      console.error('error while downloading patient', pError);
    }
  }, [pError]);

  useEffect(() => {
    if (patientProfiles) {
      // if last profile id was saved in localStorage, try to retrieve it
      const lastProfileId = localStorage.getItem(LAST_PROFILE_USED_KEY);
      const profileIndex = patientProfiles.findIndex(p => p.patientId === lastProfileId);

      let profileToSelect;
      if (profileIndex > -1) { // a profile was used previously and saved to localStorage
        profileToSelect = patientProfiles[profileIndex];
      } else {
        // try to get the owner profile
        profileToSelect = patientProfiles.find(p => p.type === PatientType.Owner);
        if (!profileToSelect) { // if no owner profile was retrieved, use the first one
          // Note: even if patientProfiles is empty, this won't fail (evaluates to undefined)
          profileToSelect = patientProfiles[0];
        }
      }

      if (profileToSelect) {
        setSelectedPatientId(profileToSelect.patientId);
      }
    }
  }, [patientProfiles, pathname, setSelectedPatientId]);

  useEffect(() => {
    if (selectedPatientId) {
      localStorage.setItem(LAST_PROFILE_USED_KEY, selectedPatientId);
    }
  }, [selectedPatientId]);

  const loading = allPLoading || pLoading;
  const selectedPatient = useMemo(() => patientData?.patient || null, [patientData]);

  const isImpersonating = useMemo(() => {
    if (!selectedPatient || !user) return false;

    const pu = selectedPatient.users.find(u => u.userId === user?.id);
    if (!pu) return false;

    return pu.type !== PatientType.Owner;
  }, [selectedPatient, user]);

  const value = useMemo<PatientContextType>(() => ({
    setSelectedPatientId,
    selectedPatient,
    loading,
    isImpersonating,
    patientProfiles: patientProfiles || undefined,
  }), [
    selectedPatient,
    setSelectedPatientId,
    loading,
    isImpersonating,
    patientProfiles,
  ]);

  useEffect(() => {
    if (patientProfiles && patientProfiles.length === 0) {
      redirect(getRoute(RoutesKeys.firstProfile))
    }
  }, [redirect, patientProfiles])

  if (loading) {
    return <Loading />
  }

  return (
    <PatientContext.Provider value={value}>
      {children}
    </PatientContext.Provider>
  )
}
