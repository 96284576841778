import { PlatformAlertDataFragment } from '../generated/graphql';
import { AlertMessage } from '../contexts/alerts.context';
import { Color } from '@material-ui/lab';

export function platformAlertToAlertMessage(platformAlert: PlatformAlertDataFragment): AlertMessage {
  return {
    id: platformAlert.id,
    severity: platformAlert.severity.toLowerCase() as Color,
    title: platformAlert.title || undefined,
    text: platformAlert.message,
    canClose: true,
  }
}