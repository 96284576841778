import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { PersonAdd } from '@material-ui/icons';

export const requestsRoutes: RouteType[] = [
  {
    key: RoutesKeys.requests,
    component: asyncLoad(() => import('../containers/requests/requests.container')),
    path: '/richieste',
    exact: true,
    config: {
      label: 'Richieste',
      icon: QuestionAnswerIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.requestDetail,
    component: asyncLoad(() => import('../containers/request-detail/request-detail.container')),
    path: '/richieste/:requestId',
    exact: true,
    config: {
      label: 'Richiesta',
      icon: QuestionAnswerIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.createRequest,
    component: asyncLoad(() => import('../containers/create-request/create-request.container')),
    path: '/crea-richiesta/:destinationId',
    exact: true,
    config: {
      label: 'Crea richiesta',
      icon: QuestionAnswerIcon,
      navBar: true,
      showBack: true,
      scopes: [],
    },
  },
  {
    key: RoutesKeys.createFirstConnection,
    component: asyncLoad(() => import('../containers/create-first-connection/create-first-connection.container')),
    path: '/imposta-medico-curante',
    exact: true,
    config: {
      label: 'Imposta medico curante',
      icon: PersonAdd,
      navBar: true,
      showBack: false,
      scopes: [],
    },
  },
];
